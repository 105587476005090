import { AppActionTypes } from "./actions";
import { AppActions, IPost } from "./types";
import { getAppLanguageByBrowserLanguage, Language } from "../../i18n";

export const appInitialState = {
  language: (localStorage.getItem("language") ||
    getAppLanguageByBrowserLanguage() ||
    "ja") as Language,
  loading: false as boolean,
  recentPosts: null as IPost | null,
  hasWebpSupport: false as boolean,
  hasWebpSupportChecked: false as boolean,
  allowLiveBroadcastTestModalVisibility: true as boolean,
};

export type AppState = typeof appInitialState;

export function appReducer(
  state: AppState = appInitialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case AppActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case AppActions.SET_LANGUAGE:
      localStorage.setItem("language", action.payload);

      return {
        ...state,
        language: action.payload,
      };
    case AppActions.SET_SITE_INFO:
      return {
        ...state,
        recentPosts: action.payload.newPosts,
        loading: false,
      };
    case AppActions.SET_HAS_WEBP_SUPPORT_REQUEST:
      return {
        ...state,
        hasWebpSupportChecked: false,
      };
    case AppActions.SET_HAS_WEBP_SUPPORT_SUCCESS:
      return {
        ...state,
        hasWebpSupport: action.payload,
        hasWebpSupportChecked: true,
      };
    case AppActions.SET_HAS_WEBP_SUPPORT_FAILURE:
      return {
        ...state,
        hasWebpSupport: false,
        hasWebpSupportChecked: true,
      };
    case AppActions.SET_ENABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY:
      return {
        ...state,
        allowLiveBroadcastTestModalVisibility: true,
      };
    case AppActions.SET_DISABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY:
      return {
        ...state,
        allowLiveBroadcastTestModalVisibility: false,
      };
    default:
      return state;
  }
}
