export enum HistoryActions {
  GET_HISTORY_SUBSCRIPTION_PAYMENTS_REQUEST = "history/GET_HISTORY_SUBSCRIPTION_PAYMENTS_REQUEST",
  GET_HISTORY_SUBSCRIPTION_PAYMENTS_SUCCESS = "history/GET_HISTORY_SUBSCRIPTION_PAYMENTS_SUCCESS",
  GET_HISTORY_SUBSCRIPTION_PAYMENTS_FAILURE = "history/GET_HISTORY_SUBSCRIPTION_PAYMENTS_FAILURE",
  GET_HISTORY_REGULAR_PAYMENTS_REQUEST = "history/GET_HISTORY_REGULAR_PAYMENTS_REQUEST",
  GET_HISTORY_REGULAR_PAYMENTS_SUCCESS = "history/GET_HISTORY_REGULAR_PAYMENTS_SUCCESS",
  GET_HISTORY_REGULAR_PAYMENTS_FAILURE = "history/GET_HISTORY_REGULAR_PAYMENTS_FAILURE",
}

export interface PaymentHistoryInterface {
  id: number;
  serviceName: string;
  price: string | number;
  currency: string;
  transactionDate: Date | string;
  planCode?: string;
}

export type PaymentHistoryType = "SubscriptionPayments" | "RegularPayments";

export type SubscriptionServiceType = "WorldRambler" | "Therapy" | "Party";

export interface PaymentHistoriesPayload {
  page: number;
  size: number;
  type: PaymentHistoryType;
}
