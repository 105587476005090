import * as Sentry from "@sentry/react";

const commitHash =
  process.env.REACT_APP_GIT_COMMIT_HASH || process.env.COMMIT_REF || "not-set";
const currentBranch =
  process.env.REACT_APP_GIT_CURRENT_BRANCH || process.env.BRANCH || "not-set";

const currentEnv = process.env.REACT_APP_ENV || "local";

const allowedEnvs = ["production", "staging"];

export const initSentry = () => {
  if (allowedEnvs.includes(currentEnv)) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: currentEnv,
    });
    Sentry.setTags({
      commit: commitHash,
      branch: currentBranch,
    });
  }
};

export const setSentryTag = (user: any) => {
  Sentry.setTag("email", user?.email);
};
