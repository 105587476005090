import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { defineMessage } from "react-intl";
import { Language } from "../i18n";

import { ReactComponent as IconTravel } from "../assets/categories/icon-travel.svg";
import { ReactComponent as IconLanguage } from "../assets/categories/icon-language-learning.svg";
import { ReactComponent as IconMusic } from "../assets/categories/icon-music.svg";
// import { ReactComponent as IconLive } from "../assets/categories/icon-live.svg";
// import { ReactComponent as IconKaraoke } from "../assets/categories/icon-karaoke.svg";
import { ReactComponent as IconTherapy } from "../assets/categories/icon-therapy.svg";
import { ReactComponent as IconExploreOthers } from "../assets/categories/icon-explore-others.svg";
import { ReactComponent as IconLab } from "../assets/categories/icon-lab.svg";
import { ReactComponent as IconNews } from "../assets/categories/icon-news.svg";
import { ReactComponent as IconTimeCapsule } from "../assets/categories/icon-time-capsule.svg";
import { ReactComponent as IconSeminar } from "../assets/categories/icon-seminar.svg";
import { ReactComponent as IconTalk } from "../assets/categories/icon-talk.svg";
import { ReactComponent as IconLearningEducation } from "../assets/categories/icon-learning-education.svg";
import { ReactComponent as IconAnimalsPet } from "../assets/categories/icon-animals-pet.svg";
import { ReactComponent as IconFestival } from "../assets/categories/icon-festival.svg";
import { ReactComponent as IconGourmet } from "../assets/categories/icon-gourmet.svg";
import { ReactComponent as IconSports } from "../assets/categories/icon-sports.svg";

// O means unlimited
export const LanguageFreePLANS = {
  free: {
    title: defineMessage({
      id: "label/free-room",
      defaultMessage: "Free Room",
    }),
    code: "free",
    price: 0,
    point: 0,
    rooms: 1,
    peoples: defineMessage({
      id: "label/max-2-people",
      defaultMessage: "Max 2 people",
    }),
    minutes: defineMessage({
      id: "label/max-40-minutes",
      defaultMessage: "Max 40 minutes",
    }),
    features: [
      defineMessage({
        id: "text/free-up-to-40-minutes",
        defaultMessage: "Free for up to <b>40 minutes daily</b>",
      }),
      defineMessage({
        id: "text/max-2-participants",
        defaultMessage: "Maximum <b>2 participants</b>",
      }),
    ],
  },
  pro: {
    title: defineMessage({
      id: "label/pro-room",
      defaultMessage: "Pro Room",
    }),
    code: "pro",
    price: 9800,
    point: 9800,
    rooms: 0,
    peoples: defineMessage({
      id: "label/max-10-people",
      defaultMessage: "Max 10 people",
    }),
    minutes: defineMessage({
      id: "label/unlimited",
      defaultMessage: "Unlimited",
    }),
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/up-to-10-participants",
        defaultMessage: "Up to <b>10 participants</b>",
      }),
      defineMessage({
        id: "text/automatic-stt",
        defaultMessage: "Automatic <b>speech-to-text record</b>",
      }),
    ],
  },
  security: {
    title: defineMessage({
      id: "label/secure-room",
      defaultMessage: "Secure Room",
    }),
    code: "security",
    price: 39800,
    point: 39800,
    rooms: 0,
    peoples: defineMessage({
      id: "label/max-10-people",
      defaultMessage: "Max 10 people",
    }),
    minutes: defineMessage({
      id: "label/unlimited",
      defaultMessage: "Unlimited",
    }),
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/up-to-10-participants",
        defaultMessage: "Up to <b>10 participants</b>",
      }),
      defineMessage({
        id: "text/automatic-stt",
        defaultMessage: "Automatic <b>speech-to-text record</b>",
      }),
      defineMessage({
        id: "text/security-setting",
        defaultMessage: "<b>Security</b> settings",
      }),
    ],
  },
} as const;

export const SelfExplorePLAN = {
  free: {
    title: defineMessage({
      id: "worldrambler/subscription/not-subscribed",
      defaultMessage: "Not Subscribed",
    }),
    code: "free",
    point: 0,
    price: 0,
    features: [],
  },
  selfExploreMobile: {
    title: defineMessage({
      id: "text/worldrambler-smartphone-pc-course",
      defaultMessage: "Smartphone/PC Course",
    }),
    code: "selfExploreMobile",
    point: 980,
    price: 980,
    features: [
      defineMessage({
        id: "text/travel-freely-without-a-guide",
        defaultMessage: "Travel freely without a guide",
      }),
      defineMessage({
        id: "text/travel-explore-the-world-reasonable-price",
        defaultMessage:
          "Travel alone or with your friends and family to explore the world at a reasonable price",
      }),
    ],
  },
  selfExplore: {
    title: defineMessage({
      id: "text/worldrambler-vr-high-quality-course",
      defaultMessage: "VR High-Quality Course",
    }),
    code: "selfExplore",
    point: 3000,
    price: 3000,
    features: [
      defineMessage({
        id: "text/travel-freely-without-a-guide",
        defaultMessage: "Travel freely without a guide",
      }),
      defineMessage({
        id: "text/travel-explore-the-world-immersive-high-quality-vr",
        defaultMessage:
          "Travel alone or with your friends and family to explore the world in immersive high-quality VR",
      }),
    ],
  },
} as const;

export const TherapyPLAN = {
  title: defineMessage({
    id: "label/therapy",
    defaultMessage: "Therapy",
  }),
  code: "therapy",
  point: 500,
  price: 500,
  features: [
    defineMessage({
      id: "text/possible-to-experience-therapy",
      defaultMessage: "It is possible to experience therapy",
    }),
  ],
};

export const PartyPLANS = {
  free: {
    title: defineMessage({
      id: "label/free-plan",
      defaultMessage: "Free Plan",
    }),
    code: "free",
    price: 0,
    point: 0,
    features: [
      defineMessage({
        id: "text/free-up-to-40-minutes",
        defaultMessage: "Free for up to <b>40 minutes daily</b>",
      }),
      defineMessage({
        id: "text/max-2-participants",
        defaultMessage: "Maximum <b>2 participants</b>",
      }),
    ],
  },
  pro: {
    title: defineMessage({
      id: "label/vr-karaoke",
      defaultMessage: "Karaoke",
    }),
    code: "pro",
    price: 900,
    point: 900,
    features: [
      defineMessage({
        id: "text/no-time-limit",
        defaultMessage: "<b>No time limit</b>",
      }),
      defineMessage({
        id: "text/max-10-participants",
        defaultMessage:
          "No Participant restriction<b> (Up to 10 people is recommended)</b>",
      }),
    ],
  },
} as const;

interface LanguageOption {
  value: Language;
  label: string;
}

export const LANGUAGE_OPTIONS: Array<LanguageOption> = [
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "zh-CN", label: "简体中文" },
  { value: "zh-TW", label: "繁體中文" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "hi", label: "हिंदी" },
];

export const AVAILABLE_LANGUAGES = [
  { value: "en", label: "English" },
  { value: "ja", label: "日本語" },
  { value: "zh-CN", label: "简体中文" },
  { value: "zh-TW", label: "繁體中文" },
  { value: "vi", label: "Tiếng Việt" },
  { value: "es", label: "Español" },
  { value: "nl", label: "Nederlands" },
  { value: "fr", label: "Français" },
  { value: "ru", label: "Ру́сский язы́к" },
  { value: "pt", label: "Português" },
  { value: "zh-HK", label: "廣東話" },
];

export const SPOT_LIST = [
  {
    code: "AF",
    name: "Africa",
  },
  {
    code: "AS",
    name: "Asia",
  },
  {
    code: "EU",
    name: "Europe",
  },
  {
    code: "NA",
    name: "North America",
  },
  {
    code: "OC",
    name: "Oceania",
  },
  {
    code: "SA",
    name: "South America",
  },
];

export const STRIPE_COUNTRY_LIST = [
  {
    value: "AU",
    label: "Austraila (AU)",
    currencies: ["AUD"],
  },
  {
    value: "AT",
    label: "Austria (AT)",
    currencies: ["EUR"],
  },
  {
    value: "BG",
    label: "Bulgaria (BG)",
    currencies: ["EUR", "BGN"],
  },
  {
    value: "CA",
    label: "Canada (CA)",
    currencies: ["CAD", "USD"],
  },
  {
    value: "FI",
    label: "Finland (FI)",
    currencies: ["EUR"],
  },
  {
    value: "FR",
    label: "France (FR)",
    currencies: ["EUR"],
  },
  {
    value: "DE",
    label: "Germany (DE)",
    currencies: ["EUR"],
  },
  {
    value: "HU",
    label: "Hungary (HU)",
    currencies: ["HUF", "EUR"],
  },
  {
    value: "IT",
    label: "Italy (IT)",
    currencies: ["EUR"],
  },
  {
    value: "JP",
    label: "Japan (JP)",
    currencies: ["JPY"],
  },
  {
    value: "NZ",
    label: "New Zealand (NZ)",
    currencies: ["NZD"],
  },
  {
    value: "SG",
    label: "Singapore (SG)",
    currencies: ["SGD"],
  },
  {
    value: "ES",
    label: "Spain (ES)",
    currencies: ["EUR"],
  },
  {
    value: "NL",
    label: "Netherlands (NL)",
    currencies: ["EUR"],
  },
  {
    value: "PL",
    label: "Poland (PL)",
    currencies: ["EUR", "PLN"],
  },
  {
    value: "PT",
    label: "Portugal (PT)",
    currencies: ["EUR"],
  },
  {
    value: "RO",
    label: "Romania (RO)",
    currencies: ["EUR", "RON"],
  },
  {
    value: "CH",
    label: "Switzerland (CH)",
    currencies: ["EUR", "CHF"],
  },
  {
    value: "AE",
    label: "United Arab Emirates (AE)",
    currencies: ["AED"],
  },
  {
    value: "GB",
    label: "United Kingdom (GB)",
    currencies: ["EUR", "GBP"],
  },
  {
    value: "US",
    label: "United States (US)",
    currencies: ["USD"],
  },
];

export const MONTHS_OPTIONS = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const INITIAL_SPOT_COUNTRY_OPTIONS: {
  [key: string]: CheckboxValueType[];
} = {
  AF: [],
  AS: [],
  EU: [],
  NA: [],
  OC: [],
  SA: [],
};

export const INITIAL_SPOT_COUNTRY_COUNT_OPTIONS: {
  [key: string]: number;
} = {
  AF: 0,
  AS: 0,
  EU: 0,
  NA: 0,
  OC: 0,
  SA: 0,
};

export const SPOT_COUNT = 6;

export const INITIAL_CATEGORY_OPTIONS: {
  [key: string]: CheckboxValueType[];
} = {
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
};

export const INITIAL_CATEGORY_COUNT_OPTIONS: {
  [key: string]: number;
} = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
};

export const MAIN_CATEGORY_COUNT = 8;

export const DATE_FORMAT = "YYYYMMDD";
export const DISPLAY_DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HHmmss";
export const DISPLAY_TIME_FORMAT = "hh:mm a";
export const DISPLAY_TIME_FORMAT2 = "hh:mm:ss a";

export const DATE_TIME_FORMAT = `${DATE_FORMAT}${TIME_FORMAT}`;
export const DISPLAY_DATE_TIME_FORMAT = `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`;

export const YEAR_MONTH_FORMAT = "YYYYMM";
export const DISPLAY_YEAR_MONTH_FORMAT = "YYYY-MM";

export const YEAR_FORMAT = "YYYY";
export const DISPLAY_YEAR_FORMAT = YEAR_FORMAT; // same as YEAR_FORMAT

export const PHONE_INPUT_DEFAULT_COUNTRY = "jp"; // Japan
export const PHONE_INPUT_PREFERRED_COUNTRIES = [
  "jp", // Japan
]; // We should move this data pointer to database if we add more countries in the future

export const SERVICE_CATEGORIES = [
  {
    key: "tour",
    title: defineMessage({
      id: "label/guide-tour-travel",
      defaultMessage: "Tour",
    }),
    icon: IconTravel,
  },
  {
    key: "languageLearning",
    title: defineMessage({
      id: "label/language-learning",
      defaultMessage: "Language Learning",
    }),
    icon: IconLanguage,
  },
  {
    key: "music",
    title: defineMessage({
      id: "label/music",
      defaultMessage: "Music",
    }),
    icon: IconMusic,
  },
  {
    key: "therapy",
    title: defineMessage({
      id: "label/therapy",
      defaultMessage: "Therapy",
    }),
    icon: IconTherapy,
  },
  {
    key: "explore",
    title: defineMessage({
      id: "label/explore-others",
      defaultMessage: "Explore/Others",
    }),
    icon: IconExploreOthers,
  },
  {
    key: "lab",
    title: defineMessage({
      id: "label/lab-beta",
      defaultMessage: "Lab β",
    }),
    icon: IconLab,
  },
  {
    key: "news",
    title: defineMessage({
      id: "label/news",
      defaultMessage: "News",
    }),
    icon: IconNews,
  },
  {
    key: "timeCapsule",
    title: defineMessage({
      id: "label/time-capsule",
      defaultMessage: "Time Capsule",
    }),
    icon: IconTimeCapsule,
  },
  {
    key: "seminar",
    title: defineMessage({
      id: "label/seminar",
      defaultMessage: "Seminar",
    }),
    icon: IconSeminar,
  },
  {
    key: "talk",
    title: defineMessage({
      id: "label/talk",
      defaultMessage: "Talk",
    }),
    icon: IconTalk,
  },
  {
    key: "learningEducation",
    title: defineMessage({
      id: "label/learning-education",
      defaultMessage: "Learning/Education",
    }),
    icon: IconLearningEducation,
  },
  {
    key: "animalsPet",
    title: defineMessage({
      id: "label/animals-pet",
      defaultMessage: "Animals/Pet",
    }),
    icon: IconAnimalsPet,
  },
  {
    key: "festival",
    title: defineMessage({
      id: "label/festival",
      defaultMessage: "Festival",
    }),
    icon: IconFestival,
  },
  {
    key: "gourmet",
    title: defineMessage({
      id: "label/gourmet",
      defaultMessage: "Gourmet",
    }),
    icon: IconGourmet,
  },
  {
    key: "sports",
    title: defineMessage({
      id: "label/sports",
      defaultMessage: "Sports",
    }),
    icon: IconSports,
  },
];

export const FRIENDLY_ROOM_CATEGORIES = {
  TOUR: "Guide Tour",
  EXPLORE_PLACE: "WorldRambler",
  THERAPY: "Therapy",
  KARAOKE: "Karaoke",
  EXPLORE: "Explore",
  LAB_BETA: "Lab β",
  MUSIC: "Music",
  LIVE: "Live",
  LANGUAGE_LEARNING: "Language Learning",
  NEWS: "News",
  TIME_CAPSULE: "Time Capsule",
  SEMINAR: "Seminar",
  TALK: "Talk",
  LEARNING_EDUCATION: "Learning Education",
  ANIMALS_PET: "Animals Pet",
  FESTIVAL: "Festival",
  GOURMET: "Gourmet",
  SPORTS: "Sports",
  GAUSSIAN_VR: "Gaussian VR",
} as const;

export const BOOKING_STATUS = {
  BOOKED: "booked",
  CANCELLED: "cancelled",
  CANCELLED_BY_INSUFFICIENCE: "cancelled_by_insufficience",
  CANCELLED_BY_NOSHOW: "cancelled_by_noshow",
  PARTICIPATED: "participated",
  FINISHED: "finished",
} as const;

export const ITINERARY_STATUS = {
  CREATED: "created",
  CANCELLED: "cancelled",
  ONGOING: "ongoing",
  NOSHOW: "noshow",
  FINISHED: "finished",

  // Our additional status to handle other cases
  FULL: "full",
  NOSHOW_CANCELLED: "noshow cancelled",

  // this itinerary is created by World. this room will be available permanently for the user
  WORLD: "world",
} as const;

export const langList = [
  "Bahasa Indonesia",
  "Bengali",
  "Dansk",
  "Deutsch",
  "English",
  "Español",
  "Français",
  "Hindi",
  "Italiano",
  "Magyar",
  "Melayu",
  "Nederlands",
  "Norsk",
  "Polski",
  "Português",
  "Punjabi",
  "Sign Language",
  "Suomi",
  "Svenska",
  "Tagalog",
  "Türkçe",
  "Čeština",
  "Ελληνικά",
  "Русский",
  "Українська",
  "עברית",
  "العربية",
  "ภาษาไทย",
  "中文",
  "日本語",
  "한국어",
];

export const LangKeyToLabel = {
  en: defineMessage({
    id: "label/english",
    defaultMessage: "English",
  }),
  ja: defineMessage({
    id: "label/japanese",
    defaultMessage: "Japanese",
  }),
  "zh-CN": defineMessage({
    id: "label/chinese-simplified",
    defaultMessage: "Chinese(Simplified)",
  }),
  "zh-TW": defineMessage({
    id: "label/chinese-traditional",
    defaultMessage: "Chinese(Traditional)",
  }),
  vi: defineMessage({
    id: "label/vietnamese",
    defaultMessage: "Vietnamese",
  }),
  hi: defineMessage({
    id: "label/hindi",
    defaultMessage: "Hindi",
  }),
};

export const permissionOptions = {
  public: defineMessage({
    id: "label/public-to-all",
    defaultMessage: "Public (to all)",
  }),
  allFriends: defineMessage({
    id: "label/friends-added-from-my-page",
    defaultMessage:
      "Friend (to all friends on list which user added from My Page)",
  }),
  limitedFriends: defineMessage({
    id: "label/limited-friends",
    defaultMessage: "Limited friends (select from friend list)",
  }),
  onlyMe: defineMessage({
    id: "label/only-me-not-public",
    defaultMessage: "Only me (not public)",
  }),
};
export const vrPostOptions = {
  public: defineMessage({
    id: "label/vrPost-public-to-all",
    defaultMessage: "Publish on DOKODEMO Door website",
  }),
  allFriends: defineMessage({
    id: "label/vrPost-friends-added-from-my-page",
    defaultMessage:
      "Publish only to the customers in the 'Friends' list on DOKODEMO Door website",
  }),
  limitedFriends: defineMessage({
    id: "label/vrPost-limited-friends",
    defaultMessage:
      "Publish only to selected customers in the 'Friends' list on DOKODEMO Door website",
  }),
  onlyMe: defineMessage({
    id: "label/vrPost-only-me-not-public",
    defaultMessage: "Not publish on DOKODEMO Door website",
  }),
};
export const accommodationTypes = {
  detachedHouse: defineMessage({
    id: "label/detached-house",
    defaultMessage: "Detached house",
  }),
  condominium: defineMessage({
    id: "label/condominium",
    defaultMessage: "Condominium",
  }),
  apartment: defineMessage({
    id: "label/apartment",
    defaultMessage: "Apartment",
  }),
  others: defineMessage({
    id: "label/others",
    defaultMessage: "Others",
  }),
};
export const TRIPPER_LABELS = {
  new: defineMessage({
    id: "label/new-tripper",
    defaultMessage: "New Tripper",
  }),
  beginner: defineMessage({
    id: "label/beginner-tripper",
    defaultMessage: "Beginner Tripper",
  }),
  intermediate: defineMessage({
    id: "label/intermediate-tripper",
    defaultMessage: "Intermediate Tripper",
  }),
  advanced: defineMessage({
    id: "label/advanced-tripper",
    defaultMessage: "Advanced Tripper",
  }),
  experienced: defineMessage({
    id: "label/experienced-tripper",
    defaultMessage: "Experienced Tripper",
  }),
  professional: defineMessage({
    id: "label/professional-tripper",
    defaultMessage: "Professional Tripper",
  }),
  super: defineMessage({
    id: "label/super-tripper",
    defaultMessage: "Super Tripper",
  }),
  master: defineMessage({
    id: "label/master-tripper",
    defaultMessage: "Master Tripper",
  }),
  god: defineMessage({
    id: "label/god-tripper",
    defaultMessage: "God Tripper",
  }),
  legend: defineMessage({
    id: "label/legend-tripper",
    defaultMessage: "Legend Tripper",
  }),
  completed: defineMessage({
    id: "label/completed-tripper",
    defaultMessage: "Completed Tripper",
  }),
};

export const CATEGORY_LABELS = {
  tour: defineMessage({
    id: "label/guide-tour",
    defaultMessage: "Tour",
  }),
  explorePlace: defineMessage({
    id: "label/world-rambler",
    defaultMessage: "WorldRambler",
  }),
  explore: defineMessage({
    id: "label/explore-others",
    defaultMessage: "Explore/Others",
  }),
  therapy: defineMessage({
    id: "home/filter-by-category/therapy",
    defaultMessage: "Therapy",
  }),
  karaoke: defineMessage({
    id: "home/filter-by-category/karaoke",
    defaultMessage: "Karaoke",
  }),
  music: defineMessage({
    id: "home/filter-by-category/music",
    defaultMessage: "Music",
  }),
  live: defineMessage({
    id: "label/live",
    defaultMessage: "Live",
  }),
  languageLearning: defineMessage({
    id: "label/language-learning",
    defaultMessage: "Language Learning",
  }),
  lab: defineMessage({
    id: "label/lab-beta",
    defaultMessage: "Lab β",
  }),
  news: defineMessage({
    id: "label/news",
    defaultMessage: "News",
  }),
  timeCapsule: defineMessage({
    id: "label/time-capsule",
    defaultMessage: "Time Capsule",
  }),
  seminar: defineMessage({
    id: "label/seminar",
    defaultMessage: "Seminar",
  }),
  talk: defineMessage({
    id: "label/talk",
    defaultMessage: "Talk",
  }),
  learningEducation: defineMessage({
    id: "label/learning-education",
    defaultMessage: "Learning/Education",
  }),
  animalsPet: defineMessage({
    id: "label/animals-pet",
    defaultMessage: "Animals/Pet",
  }),
  festival: defineMessage({
    id: "label/festival",
    defaultMessage: "Festival",
  }),
  gourmet: defineMessage({
    id: "label/gourmet",
    defaultMessage: "Gourmet",
  }),
  sports: defineMessage({
    id: "label/sports",
    defaultMessage: "Sports",
  }),
};

export const CATEGORIES_WITH_SORTING = [
  "all", // All categories
  "GM",
  "WR",
  "Party",
  "Music",
  "Therapy",
  "LanguageLearning",
  "Explore",
  "News",
  "TimeCapsule",
  "Seminar",
  "Talk",
  "LearningEducation",
  "AnimalsPet",
  "Festival",
  "Gourmet",
  "Sports",
];

export const LIVE_BROADCAST_EVENT_URLS_INFO = [
  {
    type: "publisher",
    label: defineMessage({
      id: "label/publisher-url",
      defaultMessage: "Publisher",
    }),
    tip: defineMessage({
      id: "label/publisher-tip",
      defaultMessage:
        "This is a link for the live broadcaster. Please access the link after pasting the stream server URL and the stream key in your broadcasting app or broadcasting software.",
    }),
  },
  {
    type: "vip",
    label: defineMessage({
      id: "label/vip-type",
      defaultMessage: "VR Talk SPACE",
    }),
    tip: defineMessage({
      id: "label/vip-price-tip",
      defaultMessage:
        "In VR Talk SPACE ticket, you will be able to speak and publish audio to others.",
    }),
  },
  {
    type: "general",
    label: defineMessage({
      id: "label/general-type",
      defaultMessage: "VR Exciting SPACE",
    }),
    tip: defineMessage({
      id: "label/general-price-tip",
      defaultMessage:
        "In VR Exciting SPACE ticket, you will not be able to speak, you'll listen and enjoy.",
    }),
  },
];

export const mainCategory = [
  "自然",
  "建造物",
  "街ぶら",
  "カルチャー",
  "動物",
  "趣味",
  "遊覧",
  "特殊なサブカテゴリー",
];

export const subCategory = [
  "自然 世界自然遺産",
  "自然 海・湖・川",
  "自然 滝",
  "自然 山・岩・森林",
  "自然 奇景",
  "自然 雪",
  "自然 氷河",
  "自然 峡谷",
  "自然 砂漠",
  "自然 花",
  "自然 植物園",
  "自然 ジャングル",
  "自然 自由記載",
  "建造物 世界文化遺産",
  "建造物 ランドマーク",
  "建造物 古代遺跡",
  "建造物 中世",
  "建造物 歴史",
  "建造物 宮殿",
  "建造物 城",
  "建造物 教会・大聖堂",
  "建造物 モスク",
  "建造物 寺院",
  "建造物 土着宗教",
  "建造物 橋",
  "建造物 タワー",
  "建造物 記念碑・像",
  "建造物 自由記載",
  "街ぶら 街並み",
  "街ぶら 市場",
  "街ぶら 公園・庭園",
  "街ぶら 商店街",
  "街ぶら 夜景・ライトアップ",
  "街ぶら 遊園地",
  "街ぶら 噴水",
  "街ぶら 自由記載",
  "カルチャー 祭・イベント",
  "カルチャー 音楽・舞踊",
  "カルチャー 美術館",
  "カルチャー 博物館",
  "カルチャー 野外博物館",
  "カルチャー 宗教儀式",
  "カルチャー 部族",
  "カルチャー 自由記載",
  "動物 動物園",
  "動物 水族館",
  "動物 魚",
  "動物 イルカ",
  "動物 アシカ",
  "動物 カメ",
  "動物 ゾウ",
  "動物 キリン",
  "動物 カンガルー",
  "動物 パンダ",
  "動物 フラミンゴ",
  "動物 鳥",
  "動物 ワニ",
  "動物 ヘビ",
  "動物 サル",
  "動物 ラクダ",
  "動物 自由記載",
  "趣味 グルメ",
  "趣味 スポーツ",
  "趣味 自動車",
  "趣味 鉄道",
  "趣味 陶器",
  "趣味 時計",
  "趣味 映画",
  "趣味 スキー場",
  "趣味 コーヒー",
  "趣味 酒類",
  "趣味 武具",
  "趣味 自由記載",
  "遊覧 ドライブ",
  "遊覧 クルーズ",
  "遊覧 セスナ",
  "遊覧 気球",
  "遊覧 水路巡り",
  "遊覧 電車",
  "遊覧 バス",
  "遊覧 自由記載",
  "特殊なサブカテゴリー パワースポット",
  "特殊なサブカテゴリー エメラルドグリーン",
  "特殊なサブカテゴリー 夕陽・夜明",
  "特殊なサブカテゴリー テーマパーク",
  "特殊なサブカテゴリー 偉人・有名人",
  "特殊なサブカテゴリー スラム街",
  "特殊なサブカテゴリー 恐竜",
  "特殊なサブカテゴリー 自由記載",
];

export const spotName = {
  EU: "ヨーロッパ",
  AS: "アジア",
  NA: "北米",
  AF: "アフリカ",
  AN: "南極大陸",
  SA: "南アメリカ",
  OC: "オセアニア",
};

export const countryName = {
  AD: "アンドラ",
  AE: "アラブ首長国連邦",
  AF: "アフガニスタン",
  AG: "アンティグアバーブーダ",
  AI: "アンギラ",
  AL: "アルバニア",
  AM: "アルメニア",
  AN: "オランダ領アンティル",
  AO: "アンゴラ",
  AQ: "南極大陸",
  AR: "アルゼンチン",
  AS: "アメリカ領サモア",
  AT: "オーストリア",
  AU: "オーストラリア",
  AW: "アルバ",
  AZ: "アゼルバイジャン",
  BA: "ボスニア・ヘルツェゴビナ",
  BB: "バルバドス",
  BD: "バングラデシュ",
  BE: "ベルギー",
  BF: "ブルキナファソ",
  BG: "ブルガリア",
  BH: "バーレーン",
  BI: "ブルンジ",
  BJ: "ベナン",
  BM: "バミューダ",
  BN: "ブルネイ",
  BO: "ボリビア",
  BR: "ブラジル",
  BS: "バハマ",
  BT: "ブータン",
  BV: "ブーベ島",
  BW: "ボツワナ",
  BY: "ベラルーシ",
  BZ: "ベリーズ",
  CA: "カナダ",
  CC: "ココス[キーリング]諸島",
  CD: "コンゴ[DRC]",
  CF: "中央アフリカ共和国",
  CG: "コンゴ[共和国]",
  CH: "スイス",
  CI: "コートジボワール",
  CK: "クック諸島",
  CL: "チリ",
  CM: "カメルーン",
  CN: "中国",
  CO: "コロンビア",
  CR: "コスタリカ",
  CU: "キューバ",
  CV: "カーボベルデ",
  CX: "クリスマス島",
  CY: "キプロス",
  CZ: "チェコ共和国",
  DE: "ドイツ",
  DJ: "ジブチ",
  DK: "デンマーク",
  DM: "ドミニカ",
  DO: "ドミニカ共和国",
  DZ: "アルジェリア",
  EC: "エクアドル",
  EE: "エストニア",
  EG: "エジプト",
  EH: "西サハラ",
  ER: "エリトリア",
  ES: "スペイン",
  ET: "エチオピア",
  FI: "フィンランド",
  FJ: "フィジー",
  FK: "フォークランド諸島[マルビナス諸島]",
  FM: "ミクロネシア",
  FO: "フェロー諸島",
  FR: "フランス",
  GA: "ガボン",
  GB: "イギリス",
  GD: "グレナダ",
  GE: "ジョージア",
  GF: "フランス領ギアナ",
  GG: "ガーンジー",
  GH: "ガーナ",
  GI: "ジブラルタル",
  GL: "グリーンランド",
  GM: "ガンビア",
  GN: "ギニア",
  GP: "グアドループ",
  GQ: "赤道ギニア",
  GR: "ギリシャ",
  GS: "サウスジョージア・サウスサンドウィッチ諸島",
  GT: "グアテマラ",
  GU: "グアム",
  GW: "ギニアビサウ",
  GY: "ガイアナ",
  GZ: "ガザ地区",
  HK: "香港",
  HM: "ハード島とマクドナルド諸島",
  HN: "ホンジュラス",
  HR: "クロアチア",
  HT: "ハイチ",
  HU: "ハンガリー",
  ID: "インドネシア",
  IE: "アイルランド",
  IL: "イスラエル",
  IM: "マン島",
  IN: "インド",
  IO: "イギリス領インド洋地域",
  IQ: "イラク",
  IR: "イラン",
  IS: "アイスランド",
  IT: "イタリア",
  JE: "ジャージー",
  JM: "ジャマイカ",
  JO: "ヨルダン",
  JP: "日本",
  KE: "ケニア",
  KG: "キルギスタン",
  KH: "カンボジア",
  KI: "キリバス",
  KM: "コモロ",
  KN: "セントクリストファーネイビス",
  KP: "北朝鮮",
  KR: "韓国",
  KW: "クウェート",
  KY: "ケイマン諸島",
  KZ: "カザフスタン",
  LA: "ラオス",
  LB: "レバノン",
  LC: "セントルシア",
  LI: "リヒテンシュタイン",
  LK: "スリランカ",
  LR: "リベリア",
  LS: "レソト",
  LT: "リトアニア",
  LU: "ルクセンブルク",
  LV: "ラトビア",
  LY: "リビア",
  MA: "モロッコ",
  MC: "モナコ",
  MD: "モルドバ",
  ME: "モンテネグロ",
  MG: "マダガスカル",
  MH: "マーシャル諸島",
  MK: "マケドニア[FYROM]",
  ML: "マリ",
  MM: "ミャンマー[ビルマ]",
  MN: "モンゴル",
  MO: "マカオ",
  MP: "北マリアナ諸島",
  MQ: "マルティニーク",
  MR: "モーリタニア",
  MS: "モントセラト",
  MT: "マルタ",
  MU: "モーリシャス",
  MV: "モルディブ",
  MW: "マラウイ",
  MX: "メキシコ",
  MY: "マレーシア",
  MZ: "モザンビーク",
  NA: "ナミビア",
  NC: "ニューカレドニア",
  NE: "ニジェール",
  NF: "ノーフォーク島",
  NG: "ナイジェリア",
  NI: "ニカラグア",
  NL: "オランダ",
  NO: "ノルウェー",
  NP: "ネパール",
  NR: "ナウル",
  NU: "ニウエ",
  NZ: "ニュージーランド",
  OM: "オマーン",
  PA: "パナマ",
  PE: "ペルー",
  PF: "フランス領ポリネシア",
  PG: "パプアニューギニア",
  PH: "フィリピン",
  PK: "パキスタン",
  PL: "ポーランド",
  PM: "サンピエール島とミクロン島",
  PN: "ピトケアン諸島",
  PR: "プエルトリコ",
  PS: "パレスチナ領土",
  PT: "ポルトガル",
  PW: "パラオ",
  PY: "パラグアイ",
  QA: "カタール",
  RE: "レユニオン",
  RO: "ルーマニア",
  RS: "セルビア",
  RU: "ロシア",
  RW: "ルワンダ",
  SA: "サウジアラビア",
  SB: "ソロモン諸島",
  SC: "セイシェル",
  SD: "スーダン",
  SE: "スウェーデン",
  SG: "シンガポール",
  SH: "セントヘレナ",
  SI: "スロベニア",
  SJ: "スヴァールバル諸島およびヤンマイエン島",
  SK: "スロバキア",
  SL: "シエラレオネ",
  SM: "サンマリノ",
  SN: "セネガル",
  SO: "ソマリア",
  SR: "スリナム",
  SS: "南スーダン",
  ST: "サントメ・プリンシペ",
  SV: "エルサルバドル",
  SY: "シリア",
  SZ: "スワジランド",
  TC: "タークス・カイコス諸島",
  TD: "チャド",
  TF: "フランス領南方南方領土",
  TG: "トーゴ",
  TH: "タイ",
  TJ: "タジキスタン",
  TK: "トケラウ",
  TL: "東ティモール",
  TM: "トルクメニスタン",
  TN: "チュニジア",
  TO: "トンガ",
  TR: "トルコ",
  TT: "トリニダード・トバゴ",
  TV: "ツバル",
  TW: "台湾",
  TZ: "タンザニア",
  UA: "ウクライナ",
  UG: "ウガンダ",
  US: "アメリカ",
  UY: "ウルグアイ",
  UZ: "ウズベキスタン",
  VA: "バチカン市",
  VC: "セントビンセントおよびグレナディーン諸島",
  VE: "ベネズエラ",
  VG: "イギリス領ヴァージン諸島",
  VI: "アメリカ領バージン諸島",
  VN: "ベトナム",
  VU: "バヌアツ",
  WF: "ウォリス・フツナ",
  WS: "サモア",
  XK: "コソボ",
  YE: "イエメン",
  YT: "マヨット",
  ZA: "南アフリカ",
  ZM: "ザンビア",
  ZW: "ジンバブエ",
};

export enum AiSeries {
  MsAi = "MsAi",
  AiMama = "AiMama",
  AiHost = "AiHost",
  AiBuddha = "AiBuddha",
}
