import React from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getLanguage } from "../../store/app/selectors";

const DefaultSeoMeta = () => {
  const language = useSelector(getLanguage);
  return (
    <>
      {language === "ja" ? (
        <Helmet
          title="【オンライン/VR空間】出会って集まって楽しむメタバース空間「どこでもドア」"
          meta={[
            {
              name: "description",
              content:
                "メタバース空間共有プラットフォーム「どこでもドア」では、誰でも無料で簡単に360°のVR空間をオンラインで作り、皆で集まってイベントやゲーム、旅行、カラオケ、音楽などを楽しめます。メタバース空間を作って「メタバーサー」になりましょう！",
            },
            {
              property: "og:title",
              content:
                "【オンライン/VR空間】出会って集まって楽しむメタバース空間「どこでもドア」",
            },
            {
              property: "og:description",
              content:
                "メタバース空間共有プラットフォーム「どこでもドア」では、誰でも無料で簡単に360°のVR空間をオンラインで作り、皆で集まってイベントやゲーム、旅行、カラオケ、音楽などを楽しめます。メタバース空間を作って「メタバーサー」になりましょう！",
            },
          ]}
        />
      ) : (
        <Helmet
          title="The only Metaverse with real-world images | DOKODEMO Doors"
          meta={[
            {
              name: "description",
              content:
                "Experience the Metaverse in real-life VR. Travel the world, meet new people, attend live concerts, learn new skills, languages, etc. Free avatars.",
            },
            {
              property: "og:title",
              content:
                "The only Metaverse with real-world images | DOKODEMO Doors",
            },
            {
              property: "og:description",
              content:
                "Experience the Metaverse in real-life VR. Travel the world, meet new people, attend live concerts, learn new skills, languages, etc. Free avatars.",
            },
          ]}
        />
      )}
    </>
  );
};

export default DefaultSeoMeta;
