export interface IUser {
  id: number;
  avatarUrl: string | null;
  name: string;
  hostName: string | null;
  hostPhoto: string | null;
}

export interface IComment {
  id: number;
  comment: string;
  commentJp?: string;
  commentZhcn?: string;
  commentZhtw?: string;
  commentVi?: string;
  commentHi?: string;
  createdAt: string;
  user: IUser;
}

export interface IPost {
  id: number;
  title: string;
  description: string;
  titleJp?: string;
  descriptionJp?: string;
  titleZhcn?: string;
  descriptionZhcn?: string;
  titleZhtw?: string;
  descriptionZhtw?: string;
  titleVi?: string;
  descriptionVi?: string;
  titleHi?: string;
  descriptionHi?: string;
  assets: string[];
  isLiked: boolean;
  commentCount: number;
  likeCount: number;
  updatedAt: string;
  User: IUser;
  comments?: IComment[];
  type: number;
}

export enum ActionTypes {
  SET_IS_LOADING = "posts/SET_IS_LOADING",
  SET_IS_SUBMITTING = "posts/SET_IS_SUBMITTING",
  SET_POSTS = "posts/SET_POSTS",
  RESET_POSTS = "posts/RESET_POSTS",
  SET_POST_DETAIL = "posts/SET_POST_DETAIL",
  RESET_POST_DETAIL = "posts/RESET_POST_DETAIL",
  ADD_POST = "posts/ADD_POST",
  EDIT_POST = "posts/EDIT_POST",
  DELETE_POST = "posts/DELETE_POST",
  SET_FAVORITE_STATUS = "posts/SET_FAVORITE_STATUS",
  ADD_COMMENT = "posts/ADD_COMMENT",
}

export type FeedType = "community" | "profile";
export type PostsOptionType = "all" | "me" | "profileId";
export type ProfileTabType = "feed" | "world-event";

export interface FetchPostsPayload {
  option: PostsOptionType;
  page: number;
  size: number;
  type?: number;
  feedType: FeedType;
  profileId?: number;
}
