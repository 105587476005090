import mixpanel from "mixpanel-browser";
import { SelfhostTourInterface } from "../store/selfhost/types";
import {
  RecTourDetailsInterface,
  RecTourDetailsReservationInterface,
} from "../store/tour/types";
import { User } from "../store/account/types";
import { countryName, mainCategory, spotName, subCategory } from "./constant";
import { scrollValueCalc } from "./mixpanelHelper";

import { API_USER_IP } from "../routes/constants";
import { getJson } from "../utils/network";

async function getUserIpJson() {
  const response = await getJson(`${API_USER_IP}`);
  return response.response.userIp;
}

export const mixpanelRegister = async (user: User) => {
  console.log("新規会員登録");

  const userIp = await getUserIpJson();

  //  return;
  mixpanel.identify(String(user.id));

  mixpanel.people.set({
    $distinct_id: user.id,
    $name: user.name,
    $email: user.email,
    $phone: user.phone,
    $avatar: user.avatar,
    ユーザーID: user.id,
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "Therapy" : "未加入",
    VRゴーグルの利用有無: navigator.userAgent.includes("OculusBrowser"),
    ユーザーIP: userIp,
  });

  const now = new Date().toJSON();
  mixpanel.people.set_once({
    新規会員登録日: now,
  });

  mixpanel.register({
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "Therapy" : "未加入",
  });

  mixpanel.track("新規会員登録", {
    "Distinct ID": user.id,
    ユーザーID: user.id,
    Name: user.name,
    Email: user.email,
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "Therapy" : "未加入",
    // アカウントタイプ: "",
    // 年齢: "",
    // 性別: "",
    デバイス情報: window.navigator.userAgent,
    ユーザーIP: userIp,
  });
};

export const mixpanelLogin = (user: User) => {
  console.log("ログイン");
  mixpanel.identify(String(user.id));
  console.log("mixpanel.identify");
  //  return;
  mixpanel.people.set({
    $distinct_id: user.id,
    $name: user.name,
    $email: user.email,
    $phone: user.phone,
    $avatar: user.avatar,
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "Therapy" : "未加入",
    VRゴーグルの利用有無: navigator.userAgent.includes("OculusBrowser"),
  });
  console.log("mixpanel.people.set");
  mixpanel.register({
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "未加入" : "Therapy",
  });
  console.log("mixpanel.register");
  mixpanel.track("ログイン", {
    "Distinct ID": user.id,
    Name: user.name,
    Email: user.email,
    Phone: user.phone,
    セカぶらプラン:
      user.selfExplorePlan.plan === "selfExplore"
        ? "VR高画質"
        : user.selfExplorePlan.plan === "selfExploreMobile"
        ? "スマホ・PC"
        : "未加入",
    Partyプラン: user.partyPlan.hasPlan ? "Party" : "無料",
    Therapyプラン: user.therapyPlan.hasPlan ? "Therapy" : "未加入",
    // アカウントタイプ: "",
    // 年齢: "",
    // 性別: "",
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelVisitTop = () => {
  mixpanel.track("トップページを訪問", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelVisitDetail = (
  detailEventOrWorld: RecTourDetailsInterface | SelfhostTourInterface,
  path: "/travel/tour" | "/selfhost/tour" | "/world/room"
) => {
  if (path === "/travel/tour") {
    const detail: RecTourDetailsInterface =
      detailEventOrWorld as RecTourDetailsInterface;
    console.log("イベント/ワールド詳細を確認", path);
    //   // return;
    mixpanel.track("イベント/ワールド詳細を確認", {
      旅程id: detail.Itineraries
        ? detail.Itineraries.map((e) => e.id)
        : undefined,
      ルームid: detail.id,
      タイトル: detail.titleJp,
      メタバーサーid: detail.guideId,
      メタバーサー名: detail.hostName,
      ビデオid: detail.videoId,
      ビデオ名: detail.Video?.defaultRoomId, // NameがないのでdefaultRoomId
      カテゴリー: detail.category,
      タイプ: detail.type,
      価格: detail.isFree ? "無料" : "有料",
      金額: Number(detail.RoomPrices[0].paymentUSD),
      デバイス情報: window.navigator.userAgent,
    });
  } else if (path === "/selfhost/tour" || path === "/world/room") {
    const detail: SelfhostTourInterface =
      detailEventOrWorld as SelfhostTourInterface;

    console.log("イベント/ワールド詳細を確認", path);

    let price: number | undefined = undefined;
    if (path === "/world/room") {
      price = detail.Itineraries?.map((e) => e.ItineraryPrices[0].amount)[0];
    }

    console.log(detail, "detail");

    //  return;
    mixpanel.track("イベント/ワールド詳細を確認", {
      旅程id: detail.Itineraries
        ? detail.Itineraries?.map((e) => e.id)
        : undefined,
      ルームid: detail.id,
      タイトル: detail.titleJp,
      メタバーサーid: detail.userId,
      メタバーサー名: detail.host?.name,
      ビデオid: detail.videoId || detail.Video?.id,
      ビデオ名: detail.Video?.defaultRoomId, // NameがないのでdefaultRoomId
      カテゴリー: detail.category,
      タイプ: detail.type,
      価格: detail.isFree ? "無料" : "有料",
      金額: Number(price),
      デバイス情報: window.navigator.userAgent,
    });
  } else {
    //  return;
  }
};

export const mixpanelVisitEventPayment = (
  detail: RecTourDetailsInterface,
  reservation?: RecTourDetailsReservationInterface
) => {
  console.log("イベント参加の詳細を確認");
  // return;
  mixpanel.track("イベント参加の詳細を確認", {
    開始時刻: reservation?.timeStart,
    終了時刻: reservation?.timeEnd,
    旅程id: [reservation?.id],
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.guideId,
    メタバーサー名: detail.hostName,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId,
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(reservation?.ItineraryPrices[0].amount),
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelOpenEventForm = (
  detail: RecTourDetailsInterface,
  reservation?: RecTourDetailsReservationInterface
) => {
  console.log("イベント参加支払フォームを開く");
  // return;
  mixpanel.track("イベント参加支払フォームを開く", {
    開始時刻: reservation?.timeStart,
    終了時刻: reservation?.timeEnd,
    旅程id: [reservation?.id],
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.guideId,
    メタバーサー名: detail.hostName,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId,
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(reservation?.ItineraryPrices[0].amount),
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelBookEvent = (
  detail: RecTourDetailsInterface,
  invitationNum: number,
  userId?: number,
  reservation?: RecTourDetailsReservationInterface
) => {
  console.log("イベント/ワールドの参加申込");
  // return;
  mixpanel.track("イベント/ワールドの参加申込", {
    開始時刻: reservation?.timeStart,
    終了時刻: reservation?.timeEnd,
    旅程id: [reservation?.id],
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.guideId,
    メタバーサー名: detail.hostName,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId,
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(reservation?.ItineraryPrices[0].amount),
    誘った友達の数: invitationNum,
    デバイス情報: window.navigator.userAgent,
  });

  if (userId) {
    mixpanel.identify(String(userId));
    mixpanel.people.increment({
      累計申込金額: Number(reservation?.ItineraryPrices[0].amount),
    });
  }
};

export const mixpanelBookWorld = (
  detail: SelfhostTourInterface,
  price?: number
) => {
  console.log("イベント/ワールドの参加申込");
  // return;
  mixpanel.track("イベント/ワールドの参加申込", {
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.userId,
    メタバーサー名: detail.host?.name,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId,
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(price),
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelCancelBooking = (
  userId: number,
  detail: RecTourDetailsInterface,
  reservation: RecTourDetailsReservationInterface
) => {
  console.log("イベントの申込をキャンセル");
  // return;
  mixpanel.track("イベントの申込をキャンセル", {
    開始時刻: reservation?.timeStart,
    終了時刻: reservation?.timeEnd,
    旅程id: [reservation?.id],
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.guideId,
    メタバーサー名: detail.hostName,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId,
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(reservation?.ItineraryPrices[0].amount),
    デバイス情報: window.navigator.userAgent,
  });

  mixpanel.identify(String(userId));
  mixpanel.people.increment({
    累計申込金額: -Number(reservation?.ItineraryPrices[0].amount),
  });
};

export const mixpanelWatchSample = (detail: RecTourDetailsInterface) => {
  console.log("無料サンプルを視聴");

  mixpanel.track("無料サンプルを視聴", {
    旅程id: detail.Itineraries
      ? detail.Itineraries.map((e) => e.id)
      : undefined,
    ルームid: detail.id,
    タイトル: detail.titleJp,
    メタバーサーid: detail.guideId,
    メタバーサー名: detail.hostName,
    ビデオid: detail.videoId,
    ビデオ名: detail.Video?.defaultRoomId, // NameがないのでdefaultRoomId
    カテゴリー: detail.category,
    タイプ: detail.type,
    価格: detail.isFree ? "無料" : "有料",
    金額: Number(detail.RoomPrices[0].paymentUSD),
    デバイス情報: window.navigator.userAgent,
  });
};

type SpotType = typeof spotName;
type CountryType = typeof countryName;

interface SearchProps {
  searchValue: string;
  priceRangeValue: string[];
  startDateValue: string;
  endDateValue: string;
  spotCodesValue: [keyof SpotType];
  countryCodesValue: [keyof CountryType];
  mainCategoryIdsValue: string[];
  subCategoryIdsValue: number[];
}

export const mixpanelSearch = ({
  searchValue,
  priceRangeValue,
  startDateValue,
  endDateValue,
  spotCodesValue,
  countryCodesValue,
  mainCategoryIdsValue,
  subCategoryIdsValue,
}: SearchProps) => {
  console.log("イベント/ワールドを探す");

  const price =
    priceRangeValue[0] === "free"
      ? "無料"
      : priceRangeValue[0] === "paid"
      ? "有料"
      : null;

  let startDate = "";
  if (startDateValue) {
    startDate =
      startDateValue.slice(0, 4) +
      "/" +
      startDateValue.slice(5, 7) +
      "/" +
      startDateValue.slice(-2);
  }

  let endDate = "";
  if (endDateValue) {
    endDate =
      endDateValue.slice(0, 4) +
      "/" +
      endDateValue.slice(5, 7) +
      "/" +
      endDateValue.slice(-2);
  }

  const spotList = spotCodesValue.map((spot) => spotName[spot]);
  const countryList = countryCodesValue.map((country) => countryName[country]);
  const mainCategoryList = mainCategoryIdsValue.map(
    (i) => mainCategory[Number(i) - 1]
  );
  const subCategoryList = subCategoryIdsValue.map((i) => subCategory[i - 1]);

  mixpanel.track("イベント/ワールドを探す", {
    キーワード: searchValue,
    価格: price,
    開始日: startDate,
    終了日: endDate,
    検索地域: spotList,
    検索国: countryList,
    検索カテゴリー: mainCategoryList,
    検索サブカテゴリー: subCategoryList,
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelRegisterFavoriteHost = (id: number, name: string) => {
  console.log("お気に入りホストを登録");
  mixpanel.track("お気に入りホストを登録", {
    ホストid: id,
    ホスト名: name,
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelCheckedHelp = (menu: string) => {
  console.log("ヘルプページを確認");
  mixpanel.track("ヘルプページを確認", {
    内容: menu,
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelStripeAccount = (userId: number, success: boolean) => {
  console.log("stripeアカウント");
  mixpanel.identify(String(userId));
  mixpanel.people.set({
    stripeアカウント: success,
  });

  mixpanel.track("stripeアカウント", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAddBank = (success: boolean) => {
  console.log("口座を登録");
  mixpanel.people.set({
    口座登録: success,
  });

  mixpanel.track("口座を登録", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelPayout = (amount: string) => {
  console.log("収益を受け取る");
  // return;
  mixpanel.track("収益を受け取る", {
    金額: Number(amount),
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelSubscribePlan = (
  user: User,
  planCode: string,
  service: string
) => {
  console.log("プランを契約");
  console.log(user, planCode, service);
  //    // return;
  let peopleProperty = {};
  let peoplePropertyOnce = {};
  const now = new Date().toJSON();
  if (service === "SelfExplore") {
    peopleProperty = {
      セカぶらプラン: planCode === "selfExplore" ? "VR高画質" : "スマホ・PC",
    };
    peoplePropertyOnce = {
      セカぶらプラン初回契約日: now,
    };
  } else if (service === "Party") {
    peopleProperty = {
      Partyプラン: service,
    };
    peoplePropertyOnce = {
      Partyプラン初回契約日: now,
    };
  } else if (service === "Therapy") {
    peopleProperty = {
      Therapyプラン: service,
    };
    peoplePropertyOnce = {
      Therapyプラン初回契約日: now,
    };
  }

  mixpanel.identify(String(user.id));
  mixpanel.people.set(peopleProperty);
  mixpanel.people.set_once(peoplePropertyOnce);
  mixpanel.track("プランを契約", {
    変更したプランの種類: service === "SelfExplore" ? "セカぶら" : service,
    変更後プラン:
      service === "SelfExplore" && planCode === "selfExplore"
        ? "VR高画質"
        : service === "SelfExplore" && planCode === "selfExploreMobile"
        ? "スマホ・PC"
        : service,
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelCancelPlan = (
  user: User,
  expireDate: string,
  service: string
) => {
  console.log("プランを解約");
  //    // return;

  let peopleProperty = {};
  if (service === "SelfExplore") {
    peopleProperty = {
      // セカぶらプラン: "未加入",
      セカぶらプラン契約解除日: expireDate,
    };
  } else if (service === "Party") {
    peopleProperty = {
      // Partyプラン: "無料",
      Partyプラン契約解除日: expireDate,
    };
  } else if (service === "Therapy") {
    peopleProperty = {
      // Therapyプラン: "未加入",
      Therapyプラン契約解除日: expireDate,
    };
  }

  mixpanel.identify(String(user.id));
  mixpanel.people.set(peopleProperty);
  mixpanel.track("プランを解約", {
    変更したプランの種類: service === "SelfExplore" ? "セカぶら" : service,
    変更後プラン: service === "Party" ? "無料" : "未加入",
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelMsAiLP = () => {
  mixpanel.track("AI先生LP訪問", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiMamaLP = () => {
  mixpanel.track("AIママLP訪問", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiHostLP = () => {
  mixpanel.track("AIホストLP訪問", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiBuddhaLP = () => {
  mixpanel.track("AI仏陀LP訪問", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelColumnLists = () => {
  mixpanel.track("どこでもドアコラム", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelColumnAll = () => {
  mixpanel.track("どこでもドアコラム閲覧", {
    デバイス情報: window.navigator.userAgent,
    閲覧ページ: window.location.href,
  });
};

export const mixpanelAiSeriesBtn = (
  eventName: string,
  btnPosition: string,
  deviceType: string
) => {
  mixpanel.track(eventName, {
    デバイス情報: window.navigator.userAgent,
    ボタン位置: btnPosition,
    ボタン種別: deviceType,
  });
};

export const mixpanelMsAiDetectPopUpVideo = () => {
  mixpanel.track("AI先生_ポップアップ動画閉じる", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiMamaDetectPopUpVideo = () => {
  mixpanel.track("AIママ_ポップアップ動画閉じる", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiHostDetectPopUpVideo = () => {
  mixpanel.track("AIホスト_ポップアップ動画閉じる", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiBuddhaDetectPopUpVideo = () => {
  mixpanel.track("AI仏陀_ポップアップ動画閉じる", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelMsAiScroll = (scrollValue: number) => {
  scrollValue = scrollValueCalc(scrollValue);
  mixpanel.track(`AI先生_LP ${scrollValue}%スクロール`, {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAimamaScroll = (scrollValue: number) => {
  scrollValue = scrollValueCalc(scrollValue);
  mixpanel.track(`AIママ_LP ${scrollValue}%スクロール`, {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAihostScroll = (scrollValue: number) => {
  scrollValue = scrollValueCalc(scrollValue);
  mixpanel.track(`AIホスト_LP ${scrollValue}%スクロール`, {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiBuddhaScroll = (scrollValue: number) => {
  scrollValue = scrollValueCalc(scrollValue);
  mixpanel.track(`AI仏陀_LP ${scrollValue}%スクロール`, {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelMsAiYoutubePlay = () => {
  mixpanel.track("AI先生_サンプル動画再生", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiMamaYoutubePlay = () => {
  mixpanel.track("AIママ_サンプル動画再生", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiHostYoutubePlay = () => {
  mixpanel.track("AIホスト_サンプル動画再生", {
    デバイス情報: window.navigator.userAgent,
  });
};

export const mixpanelAiBuddhaYoutubePlay = () => {
  mixpanel.track("AI仏陀_サンプル動画再生", {
    デバイス情報: window.navigator.userAgent,
  });
};
