import { VideoUploadResult } from "../types";

export enum EventActions {
  SET_IS_LOADING = "event/IS_LOADING",
  SET_IS_SUBMITTING = "event/IS_SUBMITTING",
  SET_MY_UPLOADS = "event/MY_UPLOADS",
}

export interface IUpload {
  id: number;
  name: string;
  createdAt: string;
  difficulty: string | null;
  status: VideoUploadResult;
}

export interface IMyUploads {
  inProgressVideos: IUpload[];
  pastVideos: {
    items: IUpload[];
    total: number;
  };
}
