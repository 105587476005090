import { InviteActionTypes } from "./actions";
import {
  InviteActions,
  InviteToFriendsInfoType,
  InviteToGuestsInfoType,
} from "./types";

export const inviteInitialState = {
  invitesToGuests: {} as InviteToGuestsInfoType,
  invitesToFriends: {} as InviteToFriendsInfoType,
  errors: [] as any[],
};

export type InviteState = typeof inviteInitialState;

export function inviteReducer(
  state: InviteState = inviteInitialState,
  action: InviteActionTypes
): InviteState {
  switch (action.type) {
    case InviteActions.SEND_EMAIL_INVITES_TO_GUESTS_REQUEST:
      return {
        ...state,
      };
    case InviteActions.SEND_EMAIL_INVITES_TO_GUESTS_SUCCESS:
      return {
        ...state,
        invitesToGuests: action.payload,
      };
    case InviteActions.SEND_EMAIL_INVITES_TO_FRIENDS_REQUEST:
      return {
        ...state,
      };
    case InviteActions.SEND_EMAIL_INVITES_TO_FRIENDS_SUCCESS:
      return {
        ...state,
        invitesToFriends: action.payload,
      };
    case InviteActions.SEND_EMAIL_INVITES_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
