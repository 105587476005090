export const calculateMaxScollDown = (maxScroll: any) => {
  window.addEventListener("scroll", function () {
    let scrollPx = document.documentElement.scrollTop;
    let hiddenHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    let scrollValue = Math.round((scrollPx / hiddenHeight) * 100);
    if (scrollValue >= maxScroll.current) {
      maxScroll.current = scrollValue;
    }
  });
};

export const handleLeaveAiSeries = (isAdd: boolean, handleTabClose: any) => {
  if (isSafariMobile()) {
    if (isAdd) {
      document.addEventListener("visibilitychange", handleTabClose);
    } else {
      document.removeEventListener("visibilitychange", handleTabClose);
    }
  } else {
    if (isAdd) {
      window.addEventListener("beforeunload", handleTabClose);
    } else {
      window.removeEventListener("beforeunload", handleTabClose);
    }
  }
};

export function isSafariMobile() {
  return (
    navigator &&
    /Safari/.test(navigator.userAgent) &&
    /iPhone|iPad/.test(navigator.userAgent)
  );
}

export const scrollValueCalc = (scrollValue: number) => {
  if (scrollValue < 25) {
    scrollValue = 0;
  } else if (scrollValue >= 25 && scrollValue < 50) {
    scrollValue = 25;
  } else if (scrollValue >= 50 && scrollValue < 75) {
    scrollValue = 50;
  } else if (scrollValue >= 75 && scrollValue < 100) {
    scrollValue = 75;
  } else if (scrollValue >= 100) {
    scrollValue = 100;
  }
  return scrollValue;
};
