export interface IDictionary {
  id: string;
  from: string;
  to: string;
  toTransliteration: string;
  score: string;
}

export enum DictionaryTypes {
  SET_ITEMS = "dictionary/SET_ITEMS",
  NEW_ITEM = "dictionary/NEW_ITEM",
  EDIT_ITEM = "dictionary/EDIT_ITEM",
  DELETE_ITEM = "dictionary/DELETE_ITEM",
  SET_IS_SUBMITTING = "dictionary/SET_IS_SUBMITTING",
  SET_ERRORS = "dictionary/SET_ERRORS",
}
