export enum SearchActions {
  SEARCH_USERS_REQUEST = "SEARCH_USERS_REQUEST",
  SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS",
  SEARCH_ERROR = "SEARCH_ERROR",
}

export interface SearchedUser {
  id: number;
  name: string;
  email: string;
  avatarUrl: string;
}
