export enum SpotActions {
  GET_ALL_SPOT_NAMES_REQUEST = "GET_ALL_SPOT_NAMES_REQUEST",
  GET_ALL_SPOT_NAMES_SUCCESS = "GET_ALL_SPOT_NAMES_SUCCESS",
  SPOT_ERROR = "SPOT_ERROR",
}

export interface SpotType {
  label: string;
  value: string;
  searchedCountriesCount?: number;
  countries?: {
    label: string;
    value: string;
    isSearchedCountry: boolean;
  }[];
}
