import { createSelector } from "reselect";
import { State } from "../state";
import { AccountState } from "./reducer";

export function getAccountState(state: State): AccountState {
  return state.account;
}

export const getUser = createSelector(
  getAccountState,
  (account: AccountState) => account.user
);
export const isUserLoaded = createSelector(
  getAccountState,
  (account: AccountState) => account.loaded
);
export const isAuthenticated = createSelector(
  getAccountState,
  (account: AccountState) => account.authenticated
);
export const getRedirectPath = createSelector(
  getAccountState,
  (account: AccountState) => account.redirectPath
);
export const accountErrors = createSelector(
  getAccountState,
  (account: AccountState) => account.errors
);
export const getIsSubmitting = createSelector(
  getAccountState,
  (account: AccountState) => account.isSubmitting
);
export const getBusinessMode = createSelector(
  getAccountState,
  (account: AccountState) => account.isBusinessMode
);
export const getBusinessUpdated = createSelector(
  getAccountState,
  (account: AccountState) => account.isBusinessUpdated
);
export const getPaymentMethod = createSelector(
  getAccountState,
  (account: AccountState) => account.paymentMethod
);
export const userAuthLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.userAuthLoading
);
export const resendOtpLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resendOtpLoading
);
export const confirmOtpLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.confirmOtpLoading
);
export const resetPasswordOtpLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resetPasswordOtpLoading
);
export const resetPasswordOtpConfirmationLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resetPasswordOtpConfirmationLoading
);
export const createPreSignupPhoneUserLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.createPreSignupPhoneUserLoading
);
export const resetPhoneSaveNewPhoneLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resetPhoneSaveNewPhoneLoading
);
export const resetPhoneVerifyCurrentPhoneLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resetPhoneVerifyCurrentPhoneLoading
);
export const resetPhoneVerifyAndResetNewPhoneLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.resetPhoneVerifyAndResetNewPhoneLoading
);
export const updateCanCheckPaymentHistoryLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.updateCanCheckPaymentHistoryLoading
);
export const userInfoByGoogleCallbackTokenLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.userInfoByGoogleCallbackTokenLoading
);
export const userInfoByTwitterCallbackTokenLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.userInfoByTwitterCallbackTokenLoading
);
export const userInfoByLineCallbackTokenLoadingSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.userInfoByLineCallbackTokenLoading
);
export const mayContainStaleSubscriptionDataSelector = createSelector(
  getAccountState,
  (account: AccountState) => account.mayContainStaleSubscriptionData
);
