export interface IInput {
  id: number;
  value: string;
  valueJp?: string;
  deletable: boolean;
  required: boolean;
}

export interface ISubmission {
  id: number;
  data: string;
  createdAt: string;
  User: {
    id: number;
    name?: string;
    email: string;
    enableLabß: boolean;
  };
}

export interface ITerm {
  title: string;
  titleJp?: string;
  content: string;
  contentJp?: string;
}

export enum ActionTypes {
  SET_IS_LOADING = "lab/SET_IS_LOADING",
  SET_IS_SUBMITTING = "lab/SET_IS_SUBMITTING",
  SET_INPUTS = "lab/SET_INPUTS",
  ADD_INPUT = "lab/ADD_INPUT",
  UPDATE_INPUT = "lab/UPDATE_INPUT",
  DELETE_INPUT = "lab/DELETE_INPUT",
  SET_TERMS = "lab/SET_TERMS",
  SET_SUBMISSIONS = "lab/SET_SUBMISSIONS",
  UPDATE_SUBMISSIONS = "lab/UPDATE_SUBMISSIONS",
}
