import { DictionaryActions } from "./actions";
import { DictionaryTypes, IDictionary } from "./types";

export type DictionaryState = {
  items: IDictionary[];
  isSubmitting: boolean;
  errors: string[];
};

export const dictionaryInitialState: DictionaryState = {
  items: [],
  isSubmitting: false,
  errors: [],
};

export function dictionaryReducer(
  state: DictionaryState = dictionaryInitialState,
  action: DictionaryActions
): DictionaryState {
  switch (action.type) {
    case DictionaryTypes.SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case DictionaryTypes.NEW_ITEM:
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    case DictionaryTypes.EDIT_ITEM:
      return {
        ...state,
        items: state.items.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...el,
              ...action.payload.data,
            };
          }

          return el;
        }),
      };
    case DictionaryTypes.DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((el) => el.id !== action.payload),
      };
    case DictionaryTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case DictionaryTypes.SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
