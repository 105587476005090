// page routes
export const HOME_ROUTE_PATH = "/";
export const USER_LOGIN_ROUTE_PATH = "/user/login";
export const USER_CONFIRM_EMAIL = "/user/email-confirmation";
export const USER_FORGOT_PASS_ROUTE_PATH = "/user/forgot-password";
export const USER_FORGOT_PASS_SUCCESS_ROUTE_PATH =
  "/user/forgot-password-success";
export const USER_RESET_PASS_ROUTE_PATH = "/user/reset-password";
export const USER_RESET_PASS_CONFIRM_OTP_ROUTE_PATH =
  "/user/reset-password-otp";
export const USER_RESET_EMAIL_ROUTE_PATH = "/user/reset-email";
// Business Landing page
export const BUSINESS_LANDING_PAGE = "/biz";
export const USER_REGISTER_ROUTE_PATH = "/user/register";
export const USER_SETTING_ROUTE_PATH = "/user/setting";
export const USER_MYPAGETRAVEL_ROUTE_PATH = "/user/mypage/travel";
export const USER_MYPAGETRAVEL_PAYOUT_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/payout-earnings`;
export const USER_MYPAGETRAVEL_COMPLETE_BOOK_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/complete-book`;
export const USER_MYPAGETRAVEL_COMPLETE_BOOK_RANKING_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/complete-book-ranking`;
export const USER_MYPAGETRAVEL_BOOKING_HISTORY_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/booking-history`;
export const USER_MYPAGETRAVEL_BOOKING_HISTORY_ACTIVE_BOOKINGS_ROUTE_PATH = `${USER_MYPAGETRAVEL_BOOKING_HISTORY_ROUTE_PATH}/active-bookings`;
export const USER_MYPAGETRAVEL_REVIEWS_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/reviews`;
export const USER_MYPAGETRAVEL_FRIENDS_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/friends`;
export const USER_MYPAGETRAVEL_FAVOURITES_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/favourites`;
export const USER_MYPAGETRAVEL_PAYMENT_HISTORY_ROUTE_PATH = `${USER_MYPAGETRAVEL_ROUTE_PATH}/payment-history`;
export const LP_VR_PARTY_PATH = "/party/lp1";
export const COMMUNITY_ROUTE_PATH = "/community";
export const GAUSSIAN_VR_ROUTE_PATH =
  "/new-world?category=gaussianVr&mode=original";
export const COMMUNITY_MY_POSTS_ROUTE_PATH = "/community/my-posts";
export const COMMUNITY_POST_DETAIL_ROUTE_PATH = "/community/post/:id";
export const COMMUNITY_POST_DETAIL_ROUTE_PATH_NO_ID = "/community/post";
export const PROFILE_ROUTE_PATH = "/profile";
export const PROFILE_POST_DETAIL_ROUTE_PATH = "/profile/post/:id";
export const PROFILE_POST_DETAIL_ROUTE_PATH_NO_ID = "/profile/post";
export const NEW_EVENT_PATH = "/new-event";
export const NEW_WORLD_PATH = "/new-world";
export const INFO_USER_PATH = "/info/user";
export const TRAVEL_AGENCY_PATH = "/b2b/for-travel-agency";
export const EN_TRAVEL_AGENCY_PATH = "/en/b2b/for-travel-agency";
export const TIME_CAPSULE_PATH = "/time-capsule";
export const EN_TIME_CAPSULE_PATH = "/en/time-capsule";
export const EN_INFO_USER_PATH = "/en/info/user";
export const INFO_MEET_PATH = "/info/meet";
export const INFO_MEET_PATH_EN = "/en/info/meet";
export const RANKINFO_PATH = "/info/metaverser-ranking";
export const RANKINFO_EN_PATH = "/en/info/metaverser-ranking";
export const ENGLISH_GPT_TEACHER_PATH = "/ms-ai";
export const AI_MAMA_PATH = "/ai-mama";
export const AI_HOST_PATH = "/ai-host";
export const AI_BUDDHA_PATH = "/ai-buddha";
export const COLUMNS_BLOG = "/columns";
export const COLUMNS_BLOG_EN = "/en/columns";
export const COLUMN_BLOG = "/column";
export const COLUMN_GLACIER = "/column/glacier";
export const COLUMN_FORT = "/column/fort";
export const COLUMN_ZOO = "/column/zoo";
export const COLUMN_CITY = "/column/city";
export const COLUMN_LAKE = "/column/lake";
export const COLUMN_CASTLE = "/column/castle";
export const COLUMN_NATIONALPARK = "/column/national-park";
export const COLUMN_CATHEDRAL = "/column/cathedral";
export const COLUMN_WATERFALL = "/column/waterfall";
export const COLUMN_FESTIVAL = "/column/festival";
export const COLUMN_SUNSET = "/column/sunset";
export const COLUMN_RUINS = "/column/ruins";
export const COLUMN_NIGHTVIEW = "/column/nightview";
export const COLUMN_MOUNTIAN = "/column/mountain";
export const COLUMN_SEA = "/column/sea";
export const COLUMN_ROCK = "/column/rock";
export const COLUMN_LONDON = "/column/london";
export const COLUMN_GREECE = "/column/greece";
export const COLUMN_POLAND = "/column/poland";
export const COLUMN_TUNISIA = "/column/tunisia";
export const COLUMN_MEXICO = "/column/mexico";
export const COLUMN_SOUTH_AMERICA = "/column/south-america";
export const COLUMN_INDONESIA = "/column/indonesia";
export const COLUMN_WORLDHERITAGE_EUROPE = "/column/world-heritage-site-europe";
export const COLUMN_WORLDHERITAGE_ASIA = "/column/world-heritage-site-asia";
export const TV_MIRROR = "/column/tv-mirror";
export const COLUMN_OVERCOME_VR_SICKNESS_PATH = "/column/vr-motion-sickness";
export const COLUMN_OVERCOME_VR_SICKNESS_EN_PATH =
  "/en/column/vr-motion-sickness";
export const COLUMN_LANGUAGE_LEARNING_PATH = "/column/vr-language-learning";
export const INTRO_ONLINE_TOUR = "/column/introduction-online-tour";
// Social Connect Callbacks
export const USER_CONTINUE_WITH_GOOGLE_CALLBACK_ROUTE_PATH =
  "/user/continue/google/callback";

export const USER_DISCONTINUE_WITH_FACEBOOK_DATA_DELETED_ROUTE_PATH =
  "/user/discontinue/facebook/data-deleted";

export const USER_CONTINUE_WITH_TWITTER_CALLBACK_ROUTE_PATH =
  "/user/continue/twitter/callback";

export const USER_CONTINUE_WITH_LINE_CALLBACK_ROUTE_PATH =
  "/user/continue/line/callback";

export const ADMIN_ROUTE_PATH = "/admin";
export const PRO_ADMIN_DASHBOARD_PATH = "/admin/pro";
export const PRO_ADMIN_PAYMENT_HISTORY_PATH = "/admin/pro/payment-history";

export const LANGUAGE_FREE_ROUTE_PATH = "/rooms";
export const VR_THERAPY_ROOMS_ROUTE_PATH = "/vr-therapy";
export const VR_PARTY_ROOMS_ROUTE_PATH = "/vr-party";
export const PRICES_ROUTE_PATH = "/prices";
export const DICTIONARY_ROUTE_PATH = "/dictionary";

export const PRIVACY_POLICY_ROUTE_PATH = "/privacy-policy";
export const PRIVACY_POLICY_ROUTE_PATH_EN = "/privacy-policy-en";
export const TERMS_USE_ROUTE_PATH = "/terms-use";
export const TERMS_USE_ROUTE_PATH_EN = "/terms-use-en";
export const TERMS_USE_GUEST_ROUTE_PATH = "/terms-use-guest";
export const TERMS_USE_GUIDE_ROUTE_PATH = "/terms-use-guide";
export const SCT_LAW_ROUTE_PATH = "/specified-commercial";
export const SCT_LAW_ROUTE_PATH_EN = "/specified-commercial-en";
export const FAQ_PATH = "/faq";

export const NOTIFICATION_ROUTE_PATH = "/notification";

export const GUIDE_POST_ROUTE_PATH = "/guide/:guideId/post/:postId";

// text only version of https://www.traveldx.jp/selfhost-notes
export const TERMS_SELFHOST_ROUTE_PATH = "/terms-selfhost";
export const TERMS_SELFHOST_ROUTE_PATH_EN = "/terms-selfhost-en";
export const TERMS_PARTY_ROUTE_PATH = "/terms-party";

// User Info
export const USER_INFO_ROUTE_PATH = "/info/user";
export const USER_INFO_ROUTE_PATH_EN = "/en/info/user";

// Vr sickness
export const VR_SICKNESS_TEST_PATH = "/about-vr-sickness-transfer";
export const VR_SICKNESS_TEST_ENG_PATH = "/about-vr-sickness-transfer-eng";

// guest travel
export const TRAVEL_SEARCH_ROUTE_PATH_NO_CODE = "/travel/country-search";
export const TRAVEL_TOUR_ROUTE_PATH = "/travel/tour/:slug";
export const TRAVEL_TOUR_ROUTE_PATH_NO_SLUG = "/travel/tour";
export const TRAVEL_SPOT_SEARCH_ROUTE_PATH = "/travel/spot-search/:spot";
export const TRAVEL_SPOT_SEARCH_ROUTE_PATH_NO_SPOT = "/travel/spot-search";
export const TRAVEL_GUIDEDETAIL_PATH = "/travel/host/:slug";
export const TRAVEL_SELFHOST_PRICES_PATH = "/travel/selfhost/prices";
export const TRAVEL_SELFHOST_INTRO_PATH = "/travel/selfhost/introduction";
export const TRAVEL_GUIDEMEETUP_INTRO_PATH = "/travel/guideMeetup/introduction";

// selfhost
export const SELFHOST_TOUR_ROUTE_PATH = "/admin/pro/selfhost/tour/:id";
export const SELFHOST_TOUR_EXPLORE_PATH = "/selfhost/tour/:id";
export const SELFHOST_TOUR_EXPLORE_PATH_NO_ID = "/selfhost/tour";

// host travel
export const TRAVEL_HOSTMANAGEMENT_ROUTE_PATH = "/hosting/travel";
export const TRAVEL_REGISTRATION_ROUTE_PATH_WITHOUT_SLUG =
  "/hosting/travel/register";
export const TRAVEL_REGISTRATION_ROUTE_PATH = "/hosting/travel/register/:slug";
export const TRAVEL_EDIT_ROUTE_PATH = "/hosting/travel/edit/:slug";
export const TRAVEL_EDIT_ROUTE_PATH_WITHOUT_SLUG = "/hosting/travel/edit";

// World Travel
export const WORLD_EDIT_ROUTE_PATH = "/hosting/world/edit/:slug";
export const WORLD_EDIT_ROUTE_PATH_WITHOUT_SLUG = "/hosting/world/edit";

// VR Party
export const PARTY_PAYMENT_ROUTE_PATH = "/party/payment";
export const THERAPY_PAYMENT_PATH = "/therapy/payment";

// Therapy
export const THERAPY_TOUR_ROUTE_PATH = "/therapy/tour/:id";
export const THERAPY_TOUR_DETAIL_PATH = "/therapy/tour";

// World
export const WORLD_ROUTE_PATH = "/world/room/:id";
export const WORLD_DETAIL_PATH = "/world/room";

// host shopping
export const LIVE_COMMERCE_ROUTE_PATH = "/hosting/shopping";

// Invites
export const INVITES_BY_SHORT_URL_ID_ROUTE_PATH = "/invites/:shortUrlId";

// Invites
export const CONCERT_DETAIL_ROUTE_PATH = "/concert/:id";

// Transition (transfer)
export const TRANSITION_TO_MSAI = "/transition-to-msai";
export const TRANSITION_TO_AI = "/transition-to-ai";

export const HOME_EN_ROUTE_PATH = "/en/";
export const HOME_EN_ROUTE_PATH_WITHOUT_SLASH = "/en";
export const EN_TRAVEL_TOUR_ROUTE_PATH = "/en/travel/tour/:slug";
export const EN_SELFHOST_TOUR_EXPLORE_PATH = "/en/selfhost/tour/:id";
export const EN_WORLD_ROUTE_PATH = "/en/world/room/:id";
export const EN_PREFIX_PAGES = [
  HOME_EN_ROUTE_PATH,
  EN_TRAVEL_TOUR_ROUTE_PATH,
  EN_SELFHOST_TOUR_EXPLORE_PATH,
  EN_WORLD_ROUTE_PATH,
  EN_INFO_USER_PATH,
  EN_TRAVEL_AGENCY_PATH,
  EN_TIME_CAPSULE_PATH,
];
export const EN_PREFIX_SUPPORTED_PAGES = [
  HOME_ROUTE_PATH,
  TRAVEL_TOUR_ROUTE_PATH,
  SELFHOST_TOUR_EXPLORE_PATH,
  WORLD_ROUTE_PATH,
  INFO_USER_PATH,
  TRAVEL_AGENCY_PATH,
  TIME_CAPSULE_PATH,
];
export const CN_TO_EN_FALLBACK_PAGES = [
  INFO_USER_PATH,
  TRAVEL_AGENCY_PATH,
  TIME_CAPSULE_PATH,
];

// api paths
const API_BASE_URL = `${process.env.REACT_APP_BACKEND_HOST}/api`;
const API_BASE_URL_PUBLIC = process.env.REACT_APP_BACKEND_HOST;
const API_BASE_URL_NOT_AUTH = `${process.env.REACT_APP_BACKEND_HOST}/notAuth`;
const API_VIDEO_MANAGEMENT = process.env.REACT_APP_VIDEO_MANAGE_BACKEND;

export const API_CURRENCY_URL = `${process.env.REACT_APP_BACKEND_HOST}/currency`;

export const API_USER_REGISTER = `${API_BASE_URL}/user/register`;
export const API_USER_LOGIN = `${API_BASE_URL}/user/login`;
export const API_USER_CONTINUE_WITH_GOOGLE_IDENTITY = `${API_BASE_URL}/user/continueWithGoogleIdentity`;
export const API_USER_CONTINUE_WITH_FACEBOOK = `${API_BASE_URL}/user/continueWithFacebook`;
export const API_USER_CONTINUE_WITH_TWITTER = `${API_BASE_URL}/user/continueWithTwitter`;
export const API_USER_CONTINUE_WITH_LINE = `${API_BASE_URL}/user/continueWithLine`;
export const API_USER_FORGOT_PASSWORD = `${API_BASE_URL}/user/forgot-password`;
export const API_USER_RESET_PASSWORD = `${API_BASE_URL}/user/reset-password`;
export const API_USER_INFO = `${API_BASE_URL}/user`;
export const API_USER_PERMISSION = `${API_BASE_URL}/user/permission`;
export const API_USER_PRO_ADMIN_PERMISSION = `${API_BASE_URL}/user/permission/pro`;
export const API_USER_TUTORIAL_STATUS = `${API_BASE_URL}/user/tutorial-status`;
export const API_USER_POINT = `${API_BASE_URL}/point/getPointBySelf`;
export const API_USER_PAYMENT_METHOD = `${API_BASE_URL}/user/payment-method`;
export const API_EMAIL_CHANGE = `${API_BASE_URL}/user/email`;
export const API_CONFIRM_EMAIL_CHANGE = `${API_BASE_URL}/user/confirm-email-change`;
export const API_EMAIL_VERIFY = `${API_BASE_URL}/user/email-verify`;
export const API_SEND_CONTACT = `${API_BASE_URL_PUBLIC}/send-contact`;
export const API_CMS_PAGE = `${API_BASE_URL_PUBLIC}/page`;

export const API_RESEND_OTP = `${API_BASE_URL_PUBLIC}/user/resendOtp`;
export const API_CONFIRM_OTP = `${API_BASE_URL_PUBLIC}/user/confirmOtp`;
export const API_RESET_PASSWORD_OTP_CONFIRMATION = `${API_BASE_URL_PUBLIC}/user/resetPasswordOtpConfirmation`;
export const API_RESET_PASSWORD_OTP = `${API_BASE_URL_PUBLIC}/user/resetPasswordOtp`;
export const API_USER_FORGOT_PASSWORD_BY_TOKEN = `${API_BASE_URL_PUBLIC}/user/forgot-password-by-token`;
export const API_USER_CREATE_PRE_SIGNUP_PHONE_USER = `${API_BASE_URL_PUBLIC}/user/createPreSignupPhoneUser`;

export const API_USER_RESET_PHONE_SAVE_NEW_PHONE = `${API_BASE_URL}/user/resetPhone/saveNewPhone`;
export const API_USER_RESET_PHONE_VERIFY_CURRENT_PHONE = `${API_BASE_URL}/user/resetPhone/verifyCurrentPhone`;
export const API_USER_RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE = `${API_BASE_URL}/user/resetPhone/verifyAndResetNewPhone`;

export const API_USERS_SEARCH_DETAILS = `${API_BASE_URL}/users/searchDetails`;
export const API_USERS_WORLDWIDE_MISSIONS_RANKINGS = `${API_BASE_URL}/users/worldwide-missions/rankings`;

export const API_LAB_INPUTS = `${API_BASE_URL}/lab/inputs`;
export const API_LAB_TERMS = `${API_BASE_URL}/lab/terms`;
export const API_LAB_SUBMISSIONS = `${API_BASE_URL}/lab/submissions`;

export const API_USER_UPDATE_CAN_CHECK_PAYMENT_HISTORY = `${API_BASE_URL}/user/canCheckPaymentHistory`;

export const API_USER_DISABLE_MANAGEMENT_NOTIFICATIONS_POPUP = `${API_BASE_URL}/user/disableManagementNotificationsPopup`;

export const API_SOUVENIRS = `${API_BASE_URL}/items`;
export const API_ROOMS = `${API_BASE_URL}/tokbox/rooms`;
export const API_PARTY_LOG = `${API_BASE_URL}/partyRooms/history`;

export const API_VIDEO = `${API_BASE_URL}/hosting/video`;
export const API_VIDEOS_BY_ROOM_ID = `${API_BASE_URL}/hosting/video/roomId`;
export const API_GET_FAVOURITE_VIDEOS = `${API_BASE_URL}/hosting/video/list`;
export const API_UPDATE_VIDEO_HOST = `${API_BASE_URL}/hosting/video/addOwner`;

export const API_VIDEOS = `${API_BASE_URL}/videos`;
export const API_ROOM_HISTORY = `${API_BASE_URL}/room/history`;
export const API_ROOM_HISTORIES = `${API_BASE_URL}/room/histories`;
export const API_PAYMENT_HISTORIES = `${API_BASE_URL}/payment/histories`;
export const API_ROOM_STATISTICS = `${API_BASE_URL}/room/statistics`;
export const API_ROOM_BOOKINGS = `${API_BASE_URL}/room/bookings`;
export const API_ROOM_PURCHASE_HISTORY = `${API_BASE_URL}/room/purchaseHistory`;
export const API_UPLOADED_VIDEOS = `${API_BASE_URL}/video/uploaded`;
export const API_SUCCEEDED_VIDEOS = `${API_BASE_URL}/video/succeeded`;
export const API_EXPLORE_PLACES = `${API_BASE_URL}/explorePlaces`;
export const API_EXPLORE_PLACES_PUBLIC_TOUR = `${API_BASE_URL}/explorePlaces/public`;
export const API_EXPLORE_PLACES_PUBLIC = `${API_BASE_URL_PUBLIC}/explorePlaces`;
export const API_EXPLORE_PLACE_HISTORY = `${API_BASE_URL}/explorePlaceHistory`;
export const API_REVIEW_LIKE = `${API_BASE_URL}/review/like`;
export const API_REVIEW_PHOTO_LIKE = `${API_BASE_URL}/review/photo/like`;

export const API_PARTY_ROOMS = `${API_BASE_URL}/partyRooms`;
export const API_THERAPY_ROOMS = `${API_BASE_URL}/therapyRooms`;
export const API_THERAPY_ROOMS_PUBLIC = `${API_BASE_URL_PUBLIC}/therapyRooms`;
export const API_STREAMING_TEST_VIDEO = `${API_BASE_URL}/videos/streamingTest`;
export const API_STREAMING_TEST_VIDEO_PUBLIC = `${API_BASE_URL_PUBLIC}/videos/streamingTest/public`;
export const API_TRIAL_DOOR_VIDEO = `${API_BASE_URL}/setting/trialTrippingUrl`;

export const API_DICTIONARY = `${API_BASE_URL}/dictionary`;
export const API_HOSTING = `${API_BASE_URL}/hosting`;
export const API_PAYMENT = `${API_BASE_URL}/payment`;
export const API_NOT_AUTH_PAYMENT = `${API_BASE_URL_NOT_AUTH}/payment`;
export const API_NOT_AUTH_USER = `${API_BASE_URL_NOT_AUTH}/user`;

export const API_CARD = `${API_BASE_URL}/card`;
export const API_PLAN = `${API_BASE_URL}/plan`;
export const API_SAVE_TRIAL_CODE = `${API_BASE_URL}/plan/save/trialcode`;
// Travel API
export const API_TOUR = `${API_BASE_URL}/tour`;
export const API_TOUR_PUBLIC = `${API_BASE_URL_PUBLIC}/tour`;
export const API_RECTOURS = `${API_BASE_URL}/tour/list`;
export const API_TEMPORARY = `${API_BASE_URL}/tour/temporary`;

export const API_HOSTGUIDE = `${API_BASE_URL}/tour/guide/host/`;
export const API_HOSTGUIDE_PUBLIC = `${API_BASE_URL_PUBLIC}/tour/guide/host/`;
export const API_HOSTGUIDE_EDIT = `${API_BASE_URL}/tour/guide/host/edit`;

export const API_TOURINRESERVATION = `${API_BASE_URL}/tour/guest/list/booking`;
export const API_HISTORYTOUR = `${API_BASE_URL}/tour/guest/list/history`;
export const API_HISTORYTOUR_WITH_NOSHOW = `${API_BASE_URL}/tour/guest/list/history-with-no-show`;
export const API_TOURREVIEW = `${API_BASE_URL}/tour/guest/booking`;

/* export const API_CATEGORIES = `${API_BASE_URL}/tour/getCategories`; */
export const API_SCHEDULE_LIMITATION = `${API_BASE_URL}/tour/checkItineraryTime`;

// YC Point
export const API_POINT_SEARCH_EMAIL = `${API_BASE_URL}/point/getPointByEmail`;
export const API_POINT_CHANGE_PROADMIN = `${API_BASE_URL}/point/changePointByProAdmin`;
export const API_POINT_HISTORY = `${API_BASE_URL}/point/history`;
export const API_SUBSCRIBE_PLAN_WITH_FREE_POINT = `${API_BASE_URL}/point/subscribePlanWithFreePoint`;
export const API_CANCEL_PLAN_WITH_FREE_POINT = `${API_BASE_URL}/point/cancelPlanWithFreePoint`;
export const API_USER_POINT_HISTORY = `${API_BASE_URL}/hosting/payment/account/pointBalance`;

export const API_BUY_ITINERARY_WITH_FREE_POINT = `${API_BASE_URL}/payment/itinerary/buyWithFreePoint`;
export const API_BUY_FREE_ITINERARY = `${API_BASE_URL}/payment/itinerary/buyFreeItinerary`;
export const API_BUY_NOT_AUTH_FREE_ITINERARY = `${API_BASE_URL_NOT_AUTH}/payment/itinerary/buyFreeItinerary`;

// My page API
export const API_MYPAGE = `${API_BASE_URL}/mypage`;
// Google Analytics Tracking ID
export const GOOGLE_TRACKING_ID = `${process.env.REACT_APP_GOOGLE_TRACKING_ID}`;

// Invites API
export const API_SEND_EMAIL_INVITES = `${API_BASE_URL}/invite/send-email-invites`;
export const API_INVITE_LONG_URL = `${API_BASE_URL_PUBLIC}/invite/long-url`;

// Friends API
export const API_FRIEND = `${API_BASE_URL}/friend`;
export const API_FRIEND_LIST = `${API_BASE_URL}/friend/list`;
export const API_FRIEND_USER_DETAILS = `${API_BASE_URL}/friend/userDetails`;

// Notifications API
export const API_NOTIFICATIONS = `${API_BASE_URL}/notifications`;

// Music API
export const API_MUSIC = `${API_BASE_URL_PUBLIC}/music/concerts`;
export const API_MUSIC_SINGLE = `${API_BASE_URL_PUBLIC}/music/concert`;
export const API_MUSIC_PURCHASED = `${API_BASE_URL}/music/ticketPurchases`;
export const API_MUSIC_BUY = `${API_BASE_URL}/payment/concert/buy`;

// Guides API
export const API_GUIDES = `${API_BASE_URL}/guides`;
export const API_FAVORITE_GUIDES = `${API_BASE_URL}/favorite-guides`;

// ScheduleRequestHistory API
export const API_SCHEDULE_REQUEST_HISTORY = `${API_BASE_URL}/scheduleRequestHistory`;

// Locations Selection API
export const API_LOCATIONS_MULTI_SELECT = `${API_BASE_URL_PUBLIC}/locations/multi-select`;

// Locations PreSelection API
export const API_LOCATIONS_PRE_SELECTED = `${API_BASE_URL_PUBLIC}/locations/pre-selected`;

// Location - Spot & Countries Specific APIs
export const API_SPOTS_SPOT_NAMES = `${API_BASE_URL_PUBLIC}/spots/spot-names`;
export const API_COUNTRIES_COUNTRY_NAMES = `${API_BASE_URL_PUBLIC}/countries/country-names`;
export const API_COUNTRIES_HAS_PHONE_FEATURE_DATA = `${API_BASE_URL_PUBLIC}/countries/hasPhoneFeature`;

// Categories Selection API
export const API_CATEGORIES_MULTI_SELECT = `${API_BASE_URL_PUBLIC}/categories/multi-select`;

// Categories PreSelection API
export const API_CATEGORIES_PRE_SELECTED = `${API_BASE_URL_PUBLIC}/categories/pre-selected`;

// Categories PreSelection API by Video
export const API_CATEGORIES_PRE_SELECTED_BY_VIDEO = `${API_BASE_URL_PUBLIC}/categories/pre-selected/video`;

// Categories - Main Category & Sub Category Specific APIs
export const API_MAIN_CATEGORIES_MAIN_CATEGORY_NAMES = `${API_BASE_URL_PUBLIC}/main-categories/main-category-names`;
export const API_SUB_CATEGORIES_MAIN_CATEGORY_NAMES = `${API_BASE_URL_PUBLIC}/sub-categories/sub-category-names`;

// Unique Suggestion API
export const API_UNIQUE_SUGGESTION = `${API_BASE_URL_PUBLIC}/unique-suggestions`;

// Reviews API's
export const API_REVIEW = `${API_BASE_URL}/review`;
export const API_REVIEWS = `${API_BASE_URL}/reviews`;
export const API_REVIEWS_BY_ROOM = `${API_BASE_URL_PUBLIC}/reviews/room`;

// GET_SITE_INFO
export const API_SITE_INFO = `${API_BASE_URL_PUBLIC}/site-info`;

// Update `ref` Affiliated Status
export const API_UPDATE_REF_AFFILIATED_STATUS = `${API_BASE_URL}/affiliate/ref-status`;

// CompleteBook API
export const API_GET_BOOK_RANKING = `${process.env.REACT_APP_BACKEND_HOST}/complete-book/ranking`;
export const API_GET_BOOK_PROGRESS = `${API_BASE_URL}/complete-book/total-progress`;
export const API_GET_CONTINENTS = `${API_BASE_URL}/complete-book/continents`;
export const API_GET_COUNTRIES = `${API_BASE_URL}/complete-book/countries`;

export const API_PUT_TOUR_STATUS = `${API_BASE_URL}/explorePlace-status`;

export const EVENT_EXPLAINATION_ROUTE_PATH = "/event-explaination";

export const API_GET_SERVICES = `${API_BASE_URL_PUBLIC}/rooms`;

export const SEARCH_RESULTS_PAGE_ROUTE_PATH = `/search`;

// Tour Register/Edit Steps
export const API_REGISTER_TOUR_STEP_2 = `${API_BASE_URL}/register/tour/step/2`;
export const API_REGISTER_TOUR_STEP_3 = `${API_BASE_URL}/register/tour/step/3`;
export const API_REGISTER_TOUR_STEP_4 = `${API_BASE_URL}/register/tour/step/4`;
export const API_REGISTER_TOUR_STEP_5 = `${API_BASE_URL}/register/tour/step/5`;
export const API_REGISTER_TOUR_STEP_6 = `${API_BASE_URL}/register/tour/step/6`;

export const API_WR_EVENTS_FOR_USER = `${API_BASE_URL}/explorePlaces/events`;

// Withdraw Screens Routes
export const WITHDRAW_PASSWORD_CONFIRM = "/withdraw/confirm-password";
export const WITHDRAW_CONFIRMATION = "/withdraw/confirmation";
export const WITHDRAW_COMPLETE = "/withdraw/complete";

// withdraw feature API
export const API_WITHDRAW_PASSWORD_VERIFY = `${API_BASE_URL}/auth/password-verify`;
export const API_WITHDRAW_FROM_SERVICE = `${API_BASE_URL}/withdraw-user`;
export const API_WITHDRAW_FROM_WR = `${API_BASE_URL}/withdraw-wr`;

// video/image upload API
export const API_UPLOAD_VIDEO = `${API_VIDEO_MANAGEMENT}/video/world/upload`;
export const API_UPLOAD_IMAGE = `${API_VIDEO_MANAGEMENT}/video/image/upload`;

// WR Best Scene API
export const API_WR_BEST_SCENE = `${API_BASE_URL_PUBLIC}/rooms/wr-best-scene`;

// User Info by Social Callback Token's API
export const API_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN = `${API_BASE_URL_PUBLIC}/user/social-callback-token/google`;
export const API_USER_INFO_BY_TWITTER_CALLBACK_TOKEN = `${API_BASE_URL_PUBLIC}/user/social-callback-token/twitter`;
export const API_USER_INFO_BY_LINE_CALLBACK_TOKEN = `${API_BASE_URL_PUBLIC}/user/social-callback-token/line`;

// community posts API
export const API_FEED_POST = `${API_BASE_URL}/feed-post`;
export const API_FEED_POST_PUBLIC = `${API_BASE_URL_PUBLIC}/feed-post`;
export const API_FEED_POSTS = `${API_BASE_URL}/feed-posts`;
export const API_FEED_POSTS_PUBLIC = `${API_BASE_URL_PUBLIC}/feed-posts`;

// user ip API
export const API_USER_IP = `${API_BASE_URL}/user/ip`;

// test Gaussian Splatting API and Page
export const API_GAUSSIAN_SPLATTING = `${API_BASE_URL}/gaussian-splatting/test-create`;
export const PAGE_GAUSSIAN_SPLATTING = "/test-gaussian-splatting";
