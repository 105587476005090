import { TourActionTypes } from "./action";
import {
  TourActions,
  RecToursInterface,
  RecTourDetailsInterface,
  CreateTourInterface,
  TourReservationInterface,
  SearchTourInterface,
  SpotListInterface,
  TravelCountryInterface,
  TemporarySaveTourInterface,
  CategoryInterface,
  PointEmail,
  ChangePointAdminPro,
  BuyItineraryWithFreePoint,
  ChouseisanLinkInfo,
  ITourDetail,
  GmBookingInterface,
  TourReviewInterface,
} from "./types";

export const tourInitialState = {
  loading: true,
  loadingItineraries: true,
  tour: null as ITourDetail | null,
  recTours: [] as RecToursInterface[],
  recTourDetails: {} as RecTourDetailsInterface,
  createTours: [] as CreateTourInterface[], // Delete create tour also here
  tourReservations: [] as TourReservationInterface[], // Delete reservation also here
  searchTours: [] as SearchTourInterface[],
  errors: [] as any[],
  spotList: [] as SpotListInterface[],
  countries: [] as TravelCountryInterface[],
  tourInReser: [] as GmBookingInterface[],
  historyTour: [] as GmBookingInterface[],
  temporarySaveTour: {} as TemporarySaveTourInterface,
  categories: [] as CategoryInterface[],
  scheduleChecked: false as boolean,
  tourManagementIndex: "", //For locate to specific page while route transfer to /hosting/travel
  pointEmail: {} as PointEmail,
  changePointAdminPro: {} as ChangePointAdminPro,
  buyItineraryWithFreePoint: {} as BuyItineraryWithFreePoint,
  chouseisanLinkInfo: {} as ChouseisanLinkInfo,
  presentBookingId: 0 as number,
  reviews: [] as TourReviewInterface[],
  isReviewsLoading: false as boolean,
};

export type tourState = typeof tourInitialState;

export const tourReducers = (
  state: tourState = tourInitialState,
  action: TourActionTypes
): tourState => {
  const { createTours, tourReservations, historyTour, reviews } = state;
  let index = 0;

  switch (action.type) {
    case TourActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TourActions.SET_SINGLE_TOUR:
      return {
        ...state,
        tour: action.payload,
      };
    case TourActions.SET_TOUR_MANAGEMENT_INDEX:
      return {
        ...state,
        tourManagementIndex: action.payload,
      };
    case TourActions.GET_RECTOURS_REQUEST:
      return {
        ...state,
        recTours: [],
        loading: true,
      };
    case TourActions.GET_RECTOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        recTours: action.payload,
      };
    case TourActions.GET_RECTOURS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case TourActions.GET_CREATETOURS_REQUEST:
      return {
        ...state,
        loading: true,
        createTours: [],
      };
    case TourActions.GET_CREATETOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        createTours: action.payload,
      };
    case TourActions.GET_CREATETOURS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case TourActions.DELETE_TOUR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.DELETE_TOUR_SUCCESS:
      index = createTours.findIndex(
        (e: CreateTourInterface) => e.tourId === action.payload
      );
      return {
        ...state,
        loading: false,
        createTours: [
          ...createTours.slice(0, index),
          ...createTours.slice(index + 1),
        ],
      };
    case TourActions.DELETE_TOUR_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case TourActions.GET_RECTOURDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.GET_RECTOURDETAILS_REQUEST_ITINERARIES_ONLY:
      return {
        ...state,
        loadingItineraries: true,
      };
    case TourActions.GET_RECTOURDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingItineraries: false,
        recTourDetails: action.payload,
      };
    case TourActions.GET_RECTOURDETAILS_ERROR:
      return {
        ...state,
        loading: false,
        loadingItineraries: false,
        errors: action.payload,
      };
    case TourActions.GET_RESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.GET_RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        tourReservations: action.payload,
      };
    case TourActions.GET_RESERVATION_ERROR:
      return {
        ...state,
        loading: false,
        tourReservations: [],
        errors: action.payload,
      };
    case TourActions.CREATE_RESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.CREATE_RESERVATION_SUCCESS:
      const { tourId, itinerary } = action.payload;

      return {
        ...state,
        loading: false,
        tour: state.tour
          ? {
              ...state.tour,
              Itineraries:
                state.tour.id === tourId
                  ? [
                      ...state.tour.Itineraries,
                      {
                        id: itinerary.id,
                        timeStart: itinerary.timeStart,
                        timeEnd: itinerary.timeEnd,
                        minimum: itinerary.minimum,
                        maximum: itinerary.maximum,
                      },
                    ]
                  : state.tour.Itineraries,
            }
          : null,
        tourReservations: [
          ...tourReservations,
          {
            itineraryId: itinerary.id,
            timeStart: itinerary.timeStart,
            timeEnd: itinerary.timeEnd,
            available: itinerary.available,
          },
        ],
      };
    case TourActions.CREATE_RESERVATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case TourActions.DELETE_RESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.DELETE_RESERVATION_SUCCESS:
      index = tourReservations.findIndex(
        (e: TourReservationInterface) => e.itineraryId === action.payload
      );
      return {
        ...state,
        loading: false,
        tour: state.tour
          ? {
              ...state.tour,
              Itineraries: state.tour.Itineraries.filter(
                (el) => el.id !== action.payload
              ),
            }
          : null,
        tourReservations: [
          ...tourReservations.slice(0, index),
          ...tourReservations.slice(index + 1),
        ],
      };
    case TourActions.DELETE_RESERVATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case TourActions.GET_SEARCHTOURS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.GET_SEARCHTOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchTours: action.payload,
      };
    case TourActions.GET_SEARCHTOURS_ERROR:
      return {
        ...state,
        loading: false,
        searchTours: [],
        errors: action.payload,
      };
    case TourActions.SET_TOUR_ERROR:
      return {
        ...state,
        errors: action.payload as any[],
      };
    case TourActions.GET_SPOT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        spotList: [],
      };
    case TourActions.GET_SPOT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        spotList: action.payload,
      };
    case TourActions.SET_COUNTRIES_REQUEST:
      return {
        ...state,
        loading: true,
        countries: [],
      };
    case TourActions.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };
    case TourActions.GET_TEMPORARY_TOUR:
      return {
        ...state,
        loading: true,
      };
    case TourActions.GET_TEMPORARY_SUCCESS:
      return {
        ...state,
        loading: false,
        temporarySaveTour: action.payload,
      };
    case TourActions.GET_TOURINRESERVATION_REQUEST:
      return {
        ...state,
        loading: true,
        spotList: [],
      };
    case TourActions.GET_TOURINRESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        tourInReser: action.payload,
      };
    case TourActions.SET_HISTORYTOUR:
      return {
        ...state,
        historyTour: [],
      };
    case TourActions.GET_HISTORYTOUR_REQUEST:
      return {
        ...state,
        loading: true,
        historyTour: [],
      };
    case TourActions.GET_HISTORYTOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        historyTour: action.payload,
      };
    case TourActions.GET_BOOKING_FOR_REPAY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.SEND_TOURREVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.SEND_TOURREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        historyTour: historyTour.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...el,
              review: action.payload.review,
              evaluation: action.payload.evaluation,
              ReviewPhotos:
                action.payload.ReviewPhotos && action.payload.ReviewPhotos,
            };
          }

          return el;
        }),
      };
    case TourActions.GET_TOUR_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case TourActions.GET_TOUR_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case TourActions.VALIDATE_ADD_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.VALIDATE_ADD_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleChecked: true,
      };
    case TourActions.VALIDATE_ADD_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        scheduleChecked: false,
        errors: action.payload,
      };
    case TourActions.RESET_VALIDATE_ADD_SCHEDULE_FLAG:
      return {
        ...state,
        scheduleChecked: action.payload,
      };
    case TourActions.GET_POINT_EMAIL:
      return {
        ...state,
        loading: false,
      };
    case TourActions.GET_POINT_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        pointEmail: action.payload,
      };
    case TourActions.UPDATE_TOUR_CHOUSEISAN_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.UPDATE_TOUR_CHOUSEISAN_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        chouseisanLinkInfo: action.payload,
      };
    case TourActions.SUGGEST_TOUR_PREFERRED_SCHEDULE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TourActions.SUGGEST_TOUR_PREFERRED_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TourActions.CANCEL_BOOKING:
      return {
        ...state,
        recTourDetails: {
          ...state.recTourDetails,
          Itineraries: state.recTourDetails.Itineraries.map((el) => {
            if (el.id === action.payload) {
              return {
                ...el,
                reservationGuests: el.reservationGuests.filter(
                  (e) => e !== Number(localStorage.getItem("userId"))
                ),
              };
            }

            return el;
          }),
        },
      };
    case TourActions.CANCEL_PRESENT_BOOKING:
      return {
        ...state,
        presentBookingId: action.payload,
      };
    // GET_ROOMS_REVIEWS_FOR_USER_REQUEST
    case TourActions.GET_ROOMS_REVIEWS_FOR_USER_REQUEST:
      return {
        ...state,
        isReviewsLoading: true,
      };
    case TourActions.GET_ROOMS_REVIEWS_FOR_USER_SUCCESS:
      return {
        ...state,
        isReviewsLoading: false,
        reviews: action.payload,
      };
    case TourActions.GET_ROOMS_REVIEWS_FOR_USER_FAILURE:
      return {
        ...state,
        isReviewsLoading: false,
        errors: action.payload,
      };
    case TourActions.MUTATE_ROOMS_REVIEW_FOR_USER_REQUEST:
      return {
        ...state,
        isReviewsLoading: true,
      };
    case TourActions.MUTATE_ROOMS_REVIEW_FOR_USER_SUCCESS:
      return {
        ...state,
        isReviewsLoading: false,
        reviews: reviews.map((el) => {
          if (action.payload && el.id === action.payload.id) {
            return {
              ...el,
              comment: action.payload.comment,
              evaluation: action.payload.evaluation,
              ReviewPhotos: action.payload.ReviewPhotos
                ? action.payload.ReviewPhotos
                : el.ReviewPhotos,
            };
          }
          return el;
        }),
      };
    case TourActions.MUTATE_ROOMS_REVIEW_FOR_USER_FAILURE:
      return {
        ...state,
        isReviewsLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
