import React from "react";
import { useSelector } from "react-redux";

import { isLoading } from "../../store/app/selectors";
import { Loader } from "../Loader/Loader";

export function AppLoader() {
  const loading = useSelector(isLoading);

  return <React.Fragment>{loading ? <Loader /> : null}</React.Fragment>;
}
