export enum PaymentActionTypes {
  SET_IS_SUBMITTING = "payment/IS_SUBMITTIMG",
  SET_ITINERARY = "payment/SET_ITINERARY",
  SET_PAYMENT_INTENT = "payment/SET_PAYMENT_INTENT",
  CLEAR_PAYMENT_INTENT = "payment/CLEAR_PAYMENT_INTENT",
  SET_HISTORY = "payment/SET_HISTORY",
  SET_BANK_ACCOUNT = "payment/SET_BANK_ACCOUNT",
  ADD_BANK_ACCOUNT = "payment/ADD_BANK_ACCOUNT",
  SET_POINT_BALANCE = "payment/SET_POINT_BALANCE",
  SET_NOT_AUTH_DATA = "payment/SET_NOT_AUTH_DATA",
  SET_USERS_PAYMENT_HISTORIES = "payment/SET_USERS_PAYMENT_HISTORIES",
}

export interface IBalance {
  amount: number | string;
  currency: string;
  amountCurrencyHint: {
    jpy: number;
    usd: string;
  };
}

export interface IPoint {
  month: string;
  pointInUsd: number;
}

export interface IPaymentHistory {
  id: number;
  amount: string;
  platformFee: string;
  Guest: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface IBankAccount {
  id: string;
  last4: string;
  accountHolderType: string;
  accountHolderName: string;
  routingNumber: string;
  currency: string;
  country: string;
}

export type CurrencyInfoBalanceType = "available" | "pending";
