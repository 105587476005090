import * as React from "react";
import { Spin, Space } from "antd";
import "./Loader.scss";
import { SpinIndicator } from "antd/lib/spin";

interface LoaderProps {
  indicator?: SpinIndicator;
  delay?: number;
}

export function Loader({
  indicator = undefined,
  delay = undefined,
}: LoaderProps) {
  return (
    <div className="spinner-container">
      <Space size="middle">
        <Spin
          size="large"
          {...(indicator && { indicator })}
          {...(delay && { delay })}
        />
      </Space>
    </div>
  );
}
