export enum GuideActions {
  GET_HOSTGUIDE_REQUEST = "GET_HOSTGUIDE_REQUEST",
  GET_HOSTGUIDE_SUCCESS = "GET_HOSTGUIDE_SUCCESS",
  GET_HOSTGUIDE_ERROR = "GET_HOSTGUIDE_ERROR",
  UPDATE_HOSTGUIDE_REQUEST = "UPDATE_HOSTGUIDE_REQUEST",
  UPDATE_HOSTGUIDE_SUCCESS = "UPDATE_HOSTGUIDE_SUCCESS",
  UPDATE_HOSTGUIDE_ERROR = "UPDATE_HOSTGUIDE_ERROR",
  SET_GUIDE_ERROR = "SET_GUIDE_ERROR",
  SET_GUIDES = "SET_GUIDES",
  SET_FAVORITE_GUIDES = "SET_FAVORITE_GUIDES",
  SET_LOADING = "SET_LOADING",
  SET_IS_SUBMITTING = "GUIDE_IS_SUBMITTING",
  REMOVE_FAVORITE_GUIDE = "REMOVE_FAVORITE_GUIDE",
  SET_GUIDE_TOURS = "SET_GUIDE_TOURS",
  ADD_GUIDE_TOURS = "ADD_GUIDE_TOURS",
  SET_GUIDE_FAVORITE_STATUS = "SET_GUIDE_FAVORITE_STATUS",
}

export interface EvaluationType {
  guestId: number;
  tourId: number;
  evaluation: number;
  comment: string;
  date: string;
}

export interface HostGuideInfoType {
  evaluation: EvaluationType[];
  averagedEvaluations: string | null;
  hostHeaderImage: string;
  hostName: string;
  hostStory: string;
  hostStoryJp?: string;
  hostStoryZhcn?: string | null;
  hostStoryZhtw?: string | null;
  hostStoryVi?: string;
  hostStoryHi?: string;
  id: number;
  isDeleted: boolean;
  language: string;
  location: string;
  otherLanguages: string[];
  photo: string;
  userId: number;
  roomEventCount: number;
  favoriteCount: number;
  isFavorited: boolean;
  hostUrls?: {
    id: number;
    url: string;
    text?: string;
  }[];
}

export interface HostEditInfo {
  photo?: any;
  headerImage?: any;
  language?: string;
  otherLanguages?: string[];
  hostName?: string;
  hostStory?: string;
  hostStoryJp?: string;
  hostStoryZhcn?: string;
  hostStoryZhtw?: string;
  hostStoryVi?: string;
  hostStoryHi?: string;
  location?: string;
  hasHostUrlsInput?: boolean;
  hostUrls?: {
    url: string;
    text?: string;
  }[];
}

export interface IGuide {
  id: number;
  name?: string;
  email: string;
  avatarUrl?: string;
}

export interface IFavoriteGuide {
  id: number;
  name?: string;
  email: string;
  avatarUrl?: string;
  tourCount: number;
}

export interface IGuideTourInfo {
  guide: {
    name?: string;
    email: string;
  };
  tours: {
    id: number;
    title: string;
    titleJp?: string;
    titleZhcn?: string;
    titleZhtw?: string;
    titleVi?: string;
    titleHi?: string;
    Categories: {
      id: number;
      name: string;
      nameJp?: string;
      nameZhcn?: string;
      nameZhtw?: string;
      nameVi?: string;
      nameHi?: string;
    }[];
    Video: {
      difficulty: string;
    };
    language: string;
    location: string;
    payment: number;
    paymentUSD: number;
    isFree: boolean;
    photos: string[];
    time: number;
  }[];
  total: number;
}

export interface GuidePostComment {
  postId: number;
  comment: string;
  User: {
    id: number;
    name: string;
    avatarUrl: string;
  };
}

export interface GuidePost {
  id: number;
  postContent: string;
  photos: string[];
  createdAt: string;
  likeCount: number;
  liked?: boolean;
  Comments: GuidePostComment[];
}

export type ApiError = { path?: string; message: string };

export interface FavouriteGuidesPayload {
  page?: number;
}
