import { AffiliateActionTypes } from "./actions";
import { AffiliateActions } from "./types";

export const affiliateInitialState = {
  refAffiliatedStatus: {} as {},
  errors: [] as any[],
};

export type AffiliateState = typeof affiliateInitialState;

export function affiliateReducer(
  state: AffiliateState = affiliateInitialState,
  action: AffiliateActionTypes
): AffiliateState {
  switch (action.type) {
    case AffiliateActions.UPDATE_REF_AFFILIATED_STATUS_REQUEST:
      return {
        ...state,
      };
    case AffiliateActions.UPDATE_REF_AFFILIATED_STATUS_SUCCESS:
      return {
        ...state,
        refAffiliatedStatus: action.payload,
      };
    case AffiliateActions.UPDATE_REF_AFFILIATED_STATUS_FAILURE:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
