export interface IRankUnit {
  userId: number;
  spots: number;
  rank: number;
  User: {
    avatarUrl: string;
    name: string;
  };
}
export interface IRanking {
  ranking: IRankUnit[];
}
export interface ICountry {
  code: string;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
  completed: number;
  total: number;
  stamps: {
    id: number;
    photo: string;
    completedAt?: string;
  }[];
}

export interface IContinent {
  spotCode: string;
  spotName: string;
  spotNameJp: string;
  spotNameZhcn: string;
  spotNameZhtw: string;
  spotNameVi: string;
  spotNameHi: string;
  completed: number;
  total: number;
}

export type Grade =
  | "new"
  | "beginner"
  | "intermediate"
  | "advanced"
  | "experienced"
  | "professional"
  | "super"
  | "master"
  | "god"
  | "legend"
  | "completed";

export enum BookTypes {
  SET_IS_LOADING = "book/SET_IS_LOADING",
  SET_TOTAL_PROGRESS = "book/SET_TOTAL_PROGRESS",
  SET_CONTINENTS = "book/SET_CONTINENTS",
  SET_COUNTRIES = "book/SET_COUNTRIES",
  SET_RANKING_ALL = "book/SET_RANKING_ALL",
  SET_RANKING_MONTHLY = "book/SET_RANKING_MONTHLY",
}
