import { CsvActionTypes } from "./actions";
import {
  CsvActions,
  CsvPaymentsDataItem,
  CsvPaymentsHeaderItem,
} from "./types";

export const csvInitialState = {
  exportedPaymentHistories: {
    csvPaymentsHeader: [] as CsvPaymentsHeaderItem[],
    csvPaymentsData: [] as CsvPaymentsDataItem[],
  },
  isExportingPaymentHistories: false as boolean,
  hasExportedPaymentHistories: false as boolean,
  errors: [] as any[],
};

export type CsvState = typeof csvInitialState;

export function csvReducer(
  state: CsvState = csvInitialState,
  action: CsvActionTypes
): CsvState {
  switch (action.type) {
    case CsvActions.EXPORT_PAYMENT_HISTORIES_REQUEST:
      return {
        ...state,
        isExportingPaymentHistories: true,
        hasExportedPaymentHistories: false,
      };
    case CsvActions.EXPORT_PAYMENT_HISTORIES_SUCCESS:
      return {
        ...state,
        exportedPaymentHistories: {
          csvPaymentsHeader: action.payload.csvPaymentsHeader,
          csvPaymentsData: action.payload.csvPaymentsData,
        },
        isExportingPaymentHistories: false,
        hasExportedPaymentHistories: true,
      };
    case CsvActions.EXPORT_PAYMENT_HISTORIES_FAILURE:
      return {
        ...state,
        errors: action.payload,
        exportedPaymentHistories: {
          csvPaymentsHeader: [],
          csvPaymentsData: [],
        },
        isExportingPaymentHistories: false,
        hasExportedPaymentHistories: false,
      };
    case CsvActions.EXPORT_PAYMENT_HISTORIES_REFRESH:
      return {
        ...state,
        exportedPaymentHistories: {
          csvPaymentsHeader: [],
          csvPaymentsData: [],
        },
        isExportingPaymentHistories: false,
        hasExportedPaymentHistories: false,
      };
    default:
      return state;
  }
}
