export enum TherapyActions {
  CREATE_TOUR_REQUEST = "therapy/CREATE_TOUR_REQUEST",
  CREATE_TOUR_SUCCESS = "therapy/CREATE_TOUR_SUCCESS",
  GET_TOUR_LIST_REQUEST = "therapy/GET_TOUR_LIST_REQUEST",
  GET_TOUR_LIST_SUCCESS = "therapy/GET_TOUR_LIST_SUCCESS",
  GET_TOUR_DETAIL_REQUEST = "therapy/GET_TOUR_DETAIL_REQUEST",
  GET_TOUR_DETAIL_SUCCESS = "therapy/GET_TOUR_DETAIL_SUCCESS",
  UPDATE_TOUR_DETAIL_REQUEST = "therapy/UPDATE_TOUR_DETAIL_REQUEST",
  UPDATE_TOUR_DETAIL_SUCCESS = "therapy/UPDATE_TOUR_DETAIL_SUCCESS",
  DELETE_TOUR_DETAIL_SUCCESS = "therapy/DELETE_TOUR_DETAIL_SUCCESS",
  SET_ERRORS = "therapy/SET_ERRORS",
}

interface TherapyTourVideo {
  id: number;
  defaultRoomId: string;
  pcUrl: string;
  s3Url: string;
  size: number;
  streamUrl?: string;
  name?: string;
  resolution?: string;
  thumbnailUrl?: string;
}

export interface TherapyTourInterface {
  id?: number;
  userId?: number;
  videoId?: number;
  description?: string;
  title: string;
  photos?: string[];
  Video?: TherapyTourVideo;
}
