import { MusicActions } from "./actions";
import { ActionTypes, IConcert, IConcertDetail } from "./types";

export type MusicState = {
  concerts: IConcert[];
  concert: IConcertDetail | null;
  total: number;
  isLoading: boolean;
  isSubmitting: boolean;
};

export const musicInitialState: MusicState = {
  concerts: [],
  concert: null,
  total: 0,
  isLoading: false,
  isSubmitting: false,
};

export function musicReducer(
  state: MusicState = musicInitialState,
  action: MusicActions
): MusicState {
  switch (action.type) {
    case ActionTypes.SET_CONCERTS:
      return {
        ...state,
        concerts: action.payload.data,
        total: action.payload.total,
      };
    case ActionTypes.SET_CONCERT:
      return {
        ...state,
        concert: action.payload,
      };
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}
