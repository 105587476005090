import { CountryActionTypes } from "./actions";
import { CountryActions } from "./types";

export const countryInitialState = {
  allCountryNames: {} as {
    [key: string]: string;
  },
  hasPhoneFeatureCountriesData: {} as {
    onlyCountries: string[];
    localization: {
      [key: string]: string;
    };
  },
  errors: [] as any[],
};

export type CountryState = typeof countryInitialState;

export function countryReducer(
  state: CountryState = countryInitialState,
  action: CountryActionTypes
): CountryState {
  switch (action.type) {
    case CountryActions.GET_ALL_COUNTRY_NAMES_REQUEST:
      return {
        ...state,
      };
    case CountryActions.GET_ALL_COUNTRY_NAMES_SUCCESS:
      return {
        ...state,
        allCountryNames: action.payload,
      };
    case CountryActions.GET_HAS_PHONE_FEATURE_COUNTRIES_DATA_REQUEST:
      return {
        ...state,
      };
    case CountryActions.GET_HAS_PHONE_FEATURE_COUNTRIES_DATA_SUCCESS:
      return {
        ...state,
        hasPhoneFeatureCountriesData: action.payload,
      };
    case CountryActions.COUNTRY_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
