import { PageActions, CMSPageActionTypes } from "./actions";
import { PagesDataType } from "./types";

export const pageInitialState: PagesDataType = {
  data: {
    id: -1,
    pageId: 0,
    langJaPageId: 0,
    langEnPageId: 0,
    publicDatetime: "",
    pageTypeId: -1,
    status: "",
    path: "",
    title: "",
    description: "",
    html: "",
  },
  success: false,
  errors: {},
  redirectPath: "",
};
export function pageReducer(
  state: PagesDataType = pageInitialState,
  action: CMSPageActionTypes
): PagesDataType {
  switch (action.type) {
    case PageActions.PAGE_IS_LOADING:
      return {
        ...state,
        data: action.payload.data,
        success: action.payload.success,
        errors: action.payload.errors,
      };
    case PageActions.SET_REDIRECT_PATH:
      return {
        ...state,
        redirectPath: action.payload,
      };
    default:
      return state;
  }
}
