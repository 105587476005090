export enum CsvActions {
  EXPORT_PAYMENT_HISTORIES_REQUEST = "csv/EXPORT_PAYMENT_HISTORIES_REQUEST",
  EXPORT_PAYMENT_HISTORIES_SUCCESS = "csv/EXPORT_PAYMENT_HISTORIES_SUCCESS",
  EXPORT_PAYMENT_HISTORIES_FAILURE = "csv/EXPORT_PAYMENT_HISTORIES_FAILURE",
  EXPORT_PAYMENT_HISTORIES_REFRESH = "csv/EXPORT_PAYMENT_HISTORIES_REFRESH",
}

export interface CsvPaymentsHeaderItem {
  label: string;
  key: string;
}

export interface CsvPaymentsDataItem {
  year: string;
  month: string;
  date: string;
  refCode: string;
  userName: string;
  userEmail: string;
  serviceName: string;
  grossValue?: string;
  sales?: string;
  points?: string;
  grossValueYen?: string;
  salesYen?: string;
}
