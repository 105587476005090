import { notification } from "antd";
import { Action } from "redux";

import {
  ActionTypes,
  FetchRecentNotificationsPayload,
  INotification,
} from "./types";
import { AppThunk } from "../types";
import { setLoading } from "../app/actions";
import { EVENTS } from "../../utils/socket";
import { createRequest } from "../../utils/network";
import { API_NOTIFICATIONS } from "../../routes/constants";
import { actionTypes as SocketActions } from "../../middleware/types";

interface SetNotification extends Action {
  readonly type: ActionTypes.SET_NOTIFICATIONS;
  readonly payload: {
    data: INotification[];
    total: number;
    unread: {
      general: number;
      management: number;
      metaverser: number;
    };
  };
}

interface SetRecentNotification extends Action {
  readonly type: ActionTypes.SET_RECENT_NOTIFICATIONS;
  readonly payload: {
    general: {
      data: INotification[];
      unread: number;
    };
    management: {
      data: INotification[];
      unread: number;
    };
    metaverser: {
      data: INotification[];
      unread: number;
    };
    isNotificationDropdownLoaded: boolean;
  };
}

interface DeleteNotification extends Action {
  readonly type: ActionTypes.DELETE_NOTIFICATION;
  readonly payload: number;
}

interface NewNotification extends Action {
  readonly type: ActionTypes.NEW_NOTIFICATION;
  readonly payload: INotification;
}

interface ReadNotifications extends Action {
  readonly type: ActionTypes.READ_NOTIFICATIONS;
  readonly payload: {
    ids: number[];
    unreadGeneral: number;
    unreadManagement: number;
    unreadMetaverser: number;
  };
}

interface SetIsSubmitting extends Action {
  readonly type: ActionTypes.SET_IS_SUBMITTING;
  readonly payload: boolean;
}

interface SetNotificationDropdownVisibility extends Action {
  readonly type: ActionTypes.SET_NOTIFICATION_DROPDOWN_VISIBILITY;
  readonly payload: boolean;
}

interface SetNotificationsPopupOpenAction extends Action {
  readonly type: ActionTypes.SET_NOTIFICATIONS_POPUP_OPEN;
}

interface SetNotificationsPopupCloseAction extends Action {
  readonly type: ActionTypes.SET_NOTIFICATIONS_POPUP_CLOSE;
}

export function fetchNotifications(
  page: number,
  size: number,
  type?: string,
  isAdmin: boolean = false
): AppThunk {
  return async (dispatch: Function) => {
    dispatch(setLoading(true));
    const { response, error } = await createRequest(
      `${API_NOTIFICATIONS}${
        isAdmin ? "/admin" : ""
      }?page=${page}&size=${size}${type !== "" ? "&type=" + type : ""}`,
      { method: "GET" }
    );

    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
    } else if (response.success) {
      dispatch({
        type: ActionTypes.SET_NOTIFICATIONS,
        payload: {
          data: response.data.notifications.map((el: any) => ({
            id: el.id,
            title: el.title,
            titleJP: el.titleJP,
            text: el.text,
            textJP: el.textJP,
            createdAt: el.createdAt,
            updatedAt: el.updatedAt,
            deletedAt: el.deletedAt,
            isRead: el.NotificationStatuses
              ? el.NotificationStatuses[0].isRead
              : false,
          })),
          total: response.data.total,
          unread: response.data.unread,
        },
      });
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
    }

    dispatch(setLoading(false));
  };
}
export function fetchSingleNotification(id: number) {
  return async () => {
    const { response, error } = await createRequest(
      `${API_NOTIFICATIONS}/${id}`,
      { method: "GET" }
    );
    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
      return false;
    } else if (response.success) {
      return response.data;
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
      return false;
    }
  };
}

export function fetchRecentNotifications({
  sendAllData,
}: FetchRecentNotificationsPayload): AppThunk {
  return async (dispatch: Function) => {
    const sendOptionalData = new URLSearchParams({
      ...(sendAllData ? { sendAllData: "true" } : {}),
    }).toString();

    const { response, error } = await createRequest(
      `${API_NOTIFICATIONS}/recent${
        sendOptionalData ? `?${sendOptionalData}` : ""
      }`,
      { method: "GET" }
    );

    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
    } else if (response.success) {
      const { data } = response;

      if (!data.general.data) {
        data.general.data = [];
      } else {
        data.general.data = data.general.data.map((el: any) => ({
          id: el.id,
          title: el.title,
          titleJP: el.titleJP,
          text: el.text,
          textJP: el.textJP,
          createdAt: el.createdAt,
          isRead: el.NotificationStatuses[0].isRead,
        }));
      }

      if (!data.metaverser.data) {
        data.metaverser.data = [];
      } else {
        data.metaverser.data = data.metaverser.data.map((el: any) => ({
          id: el.id,
          title: el.title,
          titleJP: el.titleJP,
          text: el.text,
          textJP: el.textJP,
          createdAt: el.createdAt,
          isRead: el.NotificationStatuses[0].isRead,
        }));
      }

      if (!data.management.data) {
        data.management.data = [];
      } else {
        data.management.data = data.management.data.map((el: any) => ({
          id: el.id,
          title: el.title,
          titleJP: el.titleJP,
          text: el.text,
          textJP: el.textJP,
          createdAt: el.createdAt,
          isRead: el.NotificationStatuses[0].isRead,
        }));
      }

      setTimeout(() => {
        dispatch({
          type: ActionTypes.SET_RECENT_NOTIFICATIONS,
          payload: {
            ...data,
            isNotificationDropdownLoaded: sendAllData,
          },
        });
      }, 50); // Marginal delay to avoid flickering
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
    }
  };
}

export function createNotifications(data: {
  to: string;
  title: string;
  titleJP: string;
  text: string;
  textJP: string;
}): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: ActionTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(`${API_NOTIFICATIONS}`, {
      method: "POST",
      body: JSON.stringify(data),
    });

    dispatch({
      type: ActionTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
      return false;
    } else if (response.success) {
      return true;
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
      return false;
    }
  };
}

export function editNotification(
  id: number,
  data: {
    to: string;
    title: string;
    titleJP: string;
    text: string;
    textJP: string;
  }
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: ActionTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(
      `${API_NOTIFICATIONS}/${id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          title: data.title,
          titleJP: data.titleJP,
          text: data.text,
          textJP: data.textJP,
        }),
      }
    );

    dispatch({
      type: ActionTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
      return false;
    } else if (response.success) {
      return true;
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
      return false;
    }
  };
}
export function deleteNotification(id: number): AppThunk {
  return async (dispatch: Function) => {
    const { response, error } = await createRequest(
      `${API_NOTIFICATIONS}/${id}`,
      { method: "DELETE" }
    );
    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
      return false;
    } else if (response.success) {
      dispatch({
        type: ActionTypes.DELETE_NOTIFICATION,
        payload: id,
      });
    } else {
      response.errors?.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
      return false;
    }
  };
}

export function newNotification(data: INotification): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.NEW_NOTIFICATION,
      payload: data,
    });

    notification.info({
      message: "A new notification has arrived.",
      duration: 15.0,
    });
  };
}

export function readNotifications(ids: number[]): AppThunk {
  return (dispatch: Function) => {
    setTimeout(() => {
      dispatch({
        type: SocketActions.SEND_MESSAGE,
        payload: {
          event: EVENTS.READ_NOTIFATION,
          data: {
            ids,
            userId: localStorage.getItem("userId"),
          },
        },
      });
    }, 2000);
  };
}

export function setReadNotification(data: {
  ids: number[];
  unreadGeneral: number;
  unreadManagement: number;
}): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.READ_NOTIFICATIONS,
      payload: data,
    });
  };
}

export function setNotificationDropdownVisibility(visible: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATION_DROPDOWN_VISIBILITY,
      payload: visible,
    });
  };
}

export function setNotificationsPopupOpen(open: boolean): AppThunk {
  return async (dispatch: Function) => {
    if (open) {
      return dispatch({
        type: ActionTypes.SET_NOTIFICATIONS_POPUP_OPEN,
      });
    }

    return dispatch({
      type: ActionTypes.SET_NOTIFICATIONS_POPUP_CLOSE,
    });
  };
}

export type NotificationActions =
  | SetNotification
  | SetRecentNotification
  | DeleteNotification
  | NewNotification
  | ReadNotifications
  | SetIsSubmitting
  | SetNotificationDropdownVisibility
  | SetNotificationsPopupOpenAction
  | SetNotificationsPopupCloseAction;
