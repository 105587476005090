import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser } from "../store/account/actions";
import { isUserLoaded } from "../store/account/selectors";
import { getAuthToken } from ".";

export default function useUserAuth() {
  const dispatch = useDispatch();
  const userLoaded = useSelector(isUserLoaded);
  const token = getAuthToken();

  useEffect(() => {
    if (token && !userLoaded) {
      dispatch(fetchUser());
    }
  }, [dispatch, userLoaded, token]);

  return userLoaded;
}
