import { postJson, getJson } from "../utils/network";

import { CreateOrReadResponseBody } from ".";
import {
  API_PAYMENT,
  API_HOSTING,
  API_NOT_AUTH_PAYMENT,
} from "../routes/constants";
import { mixpanelStripeAccount } from "../utils/mixpanel";

export type StripeVerifyStatus = "unverified" | "pending" | "verified";

export type StripeConnectionType = "individual" | "company";

interface BaseStripeConnectData {
  type: StripeConnectionType;
  address: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
}

interface IndividualStripeConnectData extends BaseStripeConnectData {
  type: "individual";
  firstName: string;
  lastName: string;
}

interface CompanyStripeConnectData extends BaseStripeConnectData {
  type: "company";
  businessName: string;
}

type StripeConnectData = IndividualStripeConnectData | CompanyStripeConnectData;

type ConnectStripeResponse = CreateOrReadResponseBody<ConnectStripeData>;
type BuyTourResponse = CreateOrReadResponseBody<{
  status: string;
  clientSecret: string;
  preSignupUserId?: number;
  postSignupUserId?: number;
}>;
type CancelBookingResponse = CreateOrReadResponseBody<{
  refundId: string;
  status: string;
  paymentIntentId: string;
}>;
type VerifyAccountResponse = CreateOrReadResponseBody<{
  stripe_onbroading_url: string;
}>;
type AddBankResponse = CreateOrReadResponseBody<{
  bankAccountId: string;
  status: string;
}>;

type ConnectStripeData = {
  userId: number;
  stripe_onbroading_url: string;
  stripe_verify_status: "unverified";
};

export async function connectStripeAccount(data: StripeConnectData) {
  const apiPath = `${API_HOSTING}/payment/account/create`;
  const { response } = await postJson<ConnectStripeResponse>(apiPath, data);

  if (response.success) {
    // mixpanel Stripeアカウントを作成
    mixpanelStripeAccount(response.data.userId, response.success);
  }
  return response;
}

// Tour = Room which has itinerary
export async function buyTour(data: {
  description?: string;
  itineraryId: number;
  isWorld: boolean;
  presentEmails?: string[];
  refCode?: string | null;
  type?: "vip" | "normal";
}) {
  const apiPath = `${API_PAYMENT}/itinerary/buy`;
  const { response } = await postJson<BuyTourResponse>(apiPath, data);
  return response;
}

export async function buyTourWithNotAuth(data: {
  name: string;
  email: string;
  description?: string;
  itineraryId: number;
  isWorld: boolean;
  refCode?: string | null;
}) {
  const apiPath = `${API_NOT_AUTH_PAYMENT}/itinerary/buy`;
  const { response } = await postJson<BuyTourResponse>(apiPath, data);
  return response;
}

type CancelBookingData = { itineraryId: number } | { bookingId: number };

export async function cancelBooking(data: CancelBookingData) {
  const apiPath = `${API_PAYMENT}/booking/cancel`;
  return await postJson<CancelBookingResponse>(apiPath, data);
}

export async function getStripeVerificationLink() {
  const apiPath = `${API_HOSTING}/payment/account/verify`;
  return await postJson<VerifyAccountResponse>(apiPath);
}

export async function getConnectBalance(params: {
  page: number;
  size: number;
}) {
  const apiPath = `${API_HOSTING}/payment/account/balance`;
  return await getJson(apiPath, params);
}

export async function addBankAccount(data: {
  accountHolderType: string;
  country: string;
  currency: string;
  accountHolderName: string;
  accountNumber: string;
  routingNumber: string;
}) {
  const apiPath = `${API_HOSTING}/payment/account/addBank`;
  return await postJson<AddBankResponse>(apiPath, data);
}

export async function getBankAccounts(data: {
  limit: number;
  startingAfter?: string;
}) {
  const apiPath = `${API_HOSTING}/payment/account/listBankAccount`;
  return await getJson(apiPath, data);
}

export async function requestPayout(data: {
  amount: number;
  bankAccountId: string;
  currency: string;
}) {
  const apiPath = `${API_HOSTING}/payment/account/requestPayout`;
  return await postJson(apiPath, data);
}

export async function requestUsersPaymentHistories(params: {
  option: string;
  type: string;
  search?: string;
  filterDateType: string;
  date?: string;
  page?: number;
  size?: number;
}) {
  const { option, page, size, ...rest } = params;
  const apiPath = `${API_PAYMENT}/users/histories/${option}`;

  if (option === "csv") {
    return await getJson(apiPath, rest);
  }

  return await getJson(apiPath, { ...rest, page, size });
}
