import { TherapyActionTypes } from "./actions";
import { TherapyActions, TherapyTourInterface } from "./types";

export const therapyInitialState = {
  isSubmitting: false,
  tours: [] as TherapyTourInterface[],
  tourDetail: {} as TherapyTourInterface,
  isLoading: false,
  isHistoriesLoading: false,
};

export type TherapyState = typeof therapyInitialState;

export function therapyReducer(
  state: TherapyState = therapyInitialState,
  action: TherapyActionTypes
): TherapyState {
  const { tours } = state;
  let index = -1;

  switch (action.type) {
    case TherapyActions.CREATE_TOUR_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case TherapyActions.CREATE_TOUR_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case TherapyActions.GET_TOUR_LIST_REQUEST:
      return {
        ...state,
        tours: [],
      };
    case TherapyActions.GET_TOUR_LIST_SUCCESS:
      return {
        ...state,
        tours: action.payload,
      };
    case TherapyActions.DELETE_TOUR_DETAIL_SUCCESS:
      index = tours.findIndex((e) => e.id === action.payload);

      return {
        ...state,
        tours: [...tours.slice(0, index), ...tours.slice(index + 1)],
      };
    case TherapyActions.GET_TOUR_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        tourDetail: {} as TherapyTourInterface,
      };
    case TherapyActions.GET_TOUR_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tourDetail: action.payload,
      };
    case TherapyActions.UPDATE_TOUR_DETAIL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case TherapyActions.UPDATE_TOUR_DETAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    default:
      return state;
  }
}
