import dayjs from "dayjs";
import { NotificationActions } from "./actions";
import { ActionTypes, INotification } from "./types";

export type NotificationState = {
  notifications: INotification[];
  recentNotifications: {
    general: {
      data: INotification[];
      unread: number;
    };
    metaverser: {
      data: INotification[];
      unread: number;
    };
    management: {
      data: INotification[];
      unread: number;
    };
    hasLoaded: boolean;
  };
  unread: {
    general: number;
    metaverser: number;
    management: number;
  };
  total: number;
  isSubmitting: boolean;
  isNotificationDropdownVisible: boolean;
  isNotificationDropdownLoaded: boolean;
  notificationsPopupOpen: boolean;
};

export const notificationInitialState: NotificationState = {
  notifications: [],
  recentNotifications: {
    general: {
      data: [],
      unread: 0,
    },
    metaverser: {
      data: [],
      unread: 0,
    },
    management: {
      data: [],
      unread: 0,
    },
    hasLoaded: false,
  },
  unread: {
    general: 0,
    metaverser: 0,
    management: 0,
  },
  total: 0,
  isSubmitting: false,
  isNotificationDropdownVisible: false,
  isNotificationDropdownLoaded: false,
  notificationsPopupOpen: false,
};

export function notificationReducer(
  state: NotificationState = notificationInitialState,
  action: NotificationActions
): NotificationState {
  switch (action.type) {
    case ActionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.data,
        total: action.payload.total,
        unread: action.payload.unread,
      };
    case ActionTypes.SET_RECENT_NOTIFICATIONS:
      const recentNotifications = {
        general: action.payload.general,
        metaverser: action.payload.metaverser,
        management: action.payload.management,
        hasLoaded: true,
      };
      const isNotificationDropdownLoaded =
        action.payload.isNotificationDropdownLoaded;

      return {
        ...state,
        recentNotifications,
        isNotificationDropdownLoaded,
      };
    case ActionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((el) => ({
          ...el,
          deletedAt:
            el.id === action.payload
              ? dayjs().format("YYYY/M/D(ddd) HH:mm")
              : el.deletedAt,
        })),
      };
    case ActionTypes.NEW_NOTIFICATION:
      if (action.payload.title) {
        return {
          ...state,
          recentNotifications: {
            general: state.recentNotifications.general,
            metaverser: state.recentNotifications.metaverser,
            management: {
              data: [
                action.payload,
                ...state.recentNotifications.management.data,
              ].slice(0, 5),
              unread: state.recentNotifications.management.unread + 1,
            },
            hasLoaded: state.recentNotifications.hasLoaded,
          },
        };
      }

      return {
        ...state,
        recentNotifications: {
          general: {
            data: [
              action.payload,
              ...state.recentNotifications.general.data,
            ].slice(0, 5),
            unread: state.recentNotifications.general.unread + 1,
          },
          metaverser: {
            data: [
              action.payload,
              ...state.recentNotifications.metaverser.data,
            ].slice(0, 5),
            unread: state.recentNotifications.metaverser.unread + 1,
          },
          management: state.recentNotifications.management,
          hasLoaded: state.recentNotifications.hasLoaded,
        },
      };
    case ActionTypes.READ_NOTIFICATIONS:
      const { ids, unreadGeneral, unreadManagement, unreadMetaverser } =
        action.payload;
      return {
        ...state,
        notifications: state.notifications.map((el) => {
          if (ids.includes(el.id)) {
            return {
              ...el,
              isRead: true,
            };
          }

          return el;
        }),
        recentNotifications: {
          general: {
            data: state.recentNotifications.general.data.map((el) => {
              if (ids.includes(el.id)) {
                return {
                  ...el,
                  isRead: true,
                };
              }

              return el;
            }),
            unread: unreadGeneral,
          },
          metaverser: {
            data: state.recentNotifications.metaverser.data.map((el) => {
              if (ids.includes(el.id)) {
                return {
                  ...el,
                  isRead: true,
                };
              }

              return el;
            }),
            unread: unreadMetaverser,
          },
          management: {
            data: state.recentNotifications.management.data.map((el) => {
              if (ids.includes(el.id)) {
                return {
                  ...el,
                  isRead: true,
                };
              }

              return el;
            }),
            unread: unreadManagement,
          },
          hasLoaded: state.recentNotifications.hasLoaded,
        },
      };
    case ActionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case ActionTypes.SET_NOTIFICATION_DROPDOWN_VISIBILITY:
      return {
        ...state,
        isNotificationDropdownVisible: action.payload,
      };
    case ActionTypes.SET_NOTIFICATIONS_POPUP_OPEN:
      return {
        ...state,
        notificationsPopupOpen: true,
      };
    case ActionTypes.SET_NOTIFICATIONS_POPUP_CLOSE:
      return {
        ...state,
        notificationsPopupOpen: false,
      };
    default:
      return state;
  }
}
