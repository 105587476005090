import { accountInitialState } from "./account/reducer";
import { appInitialState } from "./app/reducer";
import { roomInitialState } from "./room/reducer";
import { souvenirInitialState } from "./souvenir/reducer";
import { paymentInitialState } from "./payment/reducer";
import { tourInitialState } from "./tour/reducer";
import { dictionaryInitialState } from "./dictionary/reducer";
import { guideInitialState } from "./guide/reducer";
import { selfhostInitialState } from "./selfhost/reducer";
import { therapyInitialState } from "./therapy/reducer";
import { inviteInitialState } from "./invite/reducer";
import { friendInitialState } from "./friend/reducer";
import { notificationInitialState } from "./notification/reducer";
import { searchInitialState } from "./search/reducer";
import { countryInitialState } from "./country/reducer";
import { spotInitialState } from "./spot/reducer";
import { suggestionInitialState } from "./suggestion/reducer";
import { musicInitialState } from "./music/reducer";
import { reviewInitialState } from "./review/reducer";
import { categoryInitialState } from "./category/reducer";
import { locationInitialState } from "./location/reducer";
import { affiliateInitialState } from "./affiliate/reducer";
import { bookInitialState } from "./book/reducer";
import { csvInitialState } from "./csv/reducer";
import { tripInitialState } from "./trip/reducer";
import { eventInitialState } from "./events/reducer";
import { postsInitialState } from "./posts/reducer";
import { historyInitialState } from "./history/reducer";
import { labInitialState } from "./lab/reducer";
import { pageInitialState } from "./pages/reducer";
import { columnInitialState } from "./columns/reducer";

export const initialState = {
  account: accountInitialState,
  app: appInitialState,
  souvenir: souvenirInitialState,
  tour: tourInitialState,
  payment: paymentInitialState,
  room: roomInitialState,
  dictionary: dictionaryInitialState,
  guide: guideInitialState,
  selfhost: selfhostInitialState,
  therapy: therapyInitialState,
  invite: inviteInitialState,
  friend: friendInitialState,
  notification: notificationInitialState,
  search: searchInitialState,
  country: countryInitialState,
  spot: spotInitialState,
  suggestion: suggestionInitialState,
  music: musicInitialState,
  review: reviewInitialState,
  category: categoryInitialState,
  location: locationInitialState,
  affiliate: affiliateInitialState,
  book: bookInitialState,
  csv: csvInitialState,
  trip: tripInitialState,
  event: eventInitialState,
  posts: postsInitialState,
  history: historyInitialState,
  lab: labInitialState,
  pages: pageInitialState,
  columns: columnInitialState,
};

export type State = typeof initialState;
