import { createSelector } from "reselect";
import { State } from "../state";
import { NotificationState } from "./reducer";

export function getNotificationState(state: State): NotificationState {
  return state.notification;
}

export const getNotifications = createSelector(
  getNotificationState,
  (notification: NotificationState) => ({
    data: notification.notifications,
    unread: notification.unread,
    total: notification.total,
  })
);

export const getRecentNotifications = createSelector(
  getNotificationState,
  (notification: NotificationState) => notification.recentNotifications
);

export const getHasLoadedRecentNotifications = createSelector(
  getNotificationState,
  (notification: NotificationState) =>
    notification.recentNotifications.hasLoaded
);

export const getIsSubmitting = createSelector(
  getNotificationState,
  (notification: NotificationState) => notification.isSubmitting
);

export const getIsNotificationDropdownVisible = createSelector(
  getNotificationState,
  (notification: NotificationState) =>
    Boolean(notification.isNotificationDropdownVisible)
);

export const getIsNotificationDropdownLoaded = createSelector(
  getNotificationState,
  (notification: NotificationState) =>
    Boolean(notification.isNotificationDropdownLoaded)
);

export const hasRecentUnreadNotificationsManagementSelector = createSelector(
  getNotificationState,
  (notification: NotificationState) =>
    Boolean(notification.recentNotifications.management.unread)
);

export const notificationsPopupOpenSelector = createSelector(
  getNotificationState,
  (notification: NotificationState) => notification.notificationsPopupOpen
);
