import { SpotActions } from "./types";
import { SpotActionTypes } from "./actions";

export const spotInitialState = {
  allSpotNames: {} as { [key: string]: string },
  errors: [] as any[],
};

export type SpotState = typeof spotInitialState;

export function spotReducer(
  state: SpotState = spotInitialState,
  action: SpotActionTypes
): SpotState {
  switch (action.type) {
    case SpotActions.GET_ALL_SPOT_NAMES_REQUEST:
      return {
        ...state,
      };
    case SpotActions.GET_ALL_SPOT_NAMES_SUCCESS:
      return {
        ...state,
        allSpotNames: action.payload,
      };
    case SpotActions.SPOT_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
