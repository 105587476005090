import io, { Socket as ISocket } from "socket.io-client";

export const EVENTS = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  NOTIFICATION: "notification",
  READ_NOTIFATION: "read-notification",
  MARK_AS_READ: "mark-as-read",
};

export default class Socket {
  private onMessage: (event: string, args: any) => void;
  private socket: ISocket | null;

  constructor(onMessage: (event: string, args: any) => void) {
    this.onMessage = onMessage;
    this.socket = null;
  }

  public connect = () => {
    this.socket = io(`${process.env.REACT_APP_BACKEND_HOST}/dashboard`);

    this.socket.onAny(this.onMessage);
  };

  public sendMessage = (type: string, payload?: any) => {
    this.socket?.emit(type, payload);
  };

  public disconnect = () => {
    this.socket?.close();
  };
}
