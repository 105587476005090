export enum AppActions {
  SET_LANGUAGE = "app/SET_LANGUAGE",
  SET_LOADING = "app/SET_LOADING",
  SET_SITE_INFO = "app/SET_SITE_INFO",
  SET_HAS_WEBP_SUPPORT_REQUEST = "app/SET_HAS_WEBP_SUPPORT_REQUEST",
  SET_HAS_WEBP_SUPPORT_SUCCESS = "app/SET_HAS_WEBP_SUPPORT_SUCCESS",
  SET_HAS_WEBP_SUPPORT_FAILURE = "app/SET_HAS_WEBP_SUPPORT_FAILURE",
  SET_ENABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY = "app/SET_ENABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY",
  SET_DISABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY = "app/SET_DISABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY",
}

export interface IPost {
  trip?: {
    id: string;
    title: string;
    titleJp?: string;
    titleZhcn?: string;
    titleZhtw?: string;
    titleVi?: string;
    titleHi?: string;
    photos: string[];
  };
  selfhost?: {
    id: string;
    title: string;
    titleJp?: string;
    photos: string[];
  };
  therapy?: {
    id: string;
    title?: string;
    photos: string[];
  };
  music?: {
    id: string;
    title?: string;
    imageUrl?: string;
  };
}
