import Socket, { EVENTS } from "../utils/socket";
import { actionTypes } from "./types";
import {
  newNotification,
  setNotificationsPopupOpen,
  setReadNotification,
} from "../store/notification/actions";
import { getAuthToken } from "../utils";
import { fetchUser } from "../store/account/actions";

export const socketMiddleware = (store: any) => {
  const onIncomingMessage = (event: string, args: any) => {
    const state = store.getState();
    const token = getAuthToken();

    switch (event) {
      case EVENTS.NOTIFICATION:
        const { to, data } = args;

        if (!to || state.account.user.id === to) {
          store.dispatch(newNotification(data));

          if (token) {
            const isUnreadManagement = Boolean(
              state.notification.recentNotifications.management.unread
            );
            const hasLoaded = state.notification.recentNotifications.hasLoaded;

            store.dispatch(
              fetchUser(() => {
                const managementNotificationsPopupEnabled =
                  state.account.user.managementNotificationsPopupEnabled;

                store.dispatch(
                  setNotificationsPopupOpen(
                    isUnreadManagement &&
                      hasLoaded &&
                      managementNotificationsPopupEnabled
                  )
                );
              })
            );
          }
        }
        break;
      case EVENTS.MARK_AS_READ:
        store.dispatch(setReadNotification(args));
        break;
      default:
        break;
    }
  };

  const socket = new Socket(onIncomingMessage);

  return (next: any) => (action: any) => {
    const { type } = action;

    switch (type) {
      case actionTypes.CONNECT_SOCKET:
        socket.connect();
        break;
      case actionTypes.DISCONNECT_SOCKET:
        socket.disconnect();
        break;
      case actionTypes.SEND_MESSAGE:
        socket.sendMessage(action.payload.event, action.payload.data);
        break;
    }

    return next(action);
  };
};
