import { LabActions } from "./actions";
import { ActionTypes, IInput, ITerm, ISubmission } from "./types";

export type LabState = {
  inputs: IInput[];
  terms: ITerm;
  submissions: ISubmission[];
  total: number;
  isLoading: boolean;
  isSubmitting: boolean;
};

export const labInitialState: LabState = {
  inputs: [],
  terms: {
    title: "",
    titleJp: "",
    content: "",
    contentJp: "",
  },
  submissions: [],
  total: 0,
  isLoading: false,
  isSubmitting: false,
};

export function labReducer(
  state: LabState = labInitialState,
  action: LabActions
): LabState {
  switch (action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case ActionTypes.SET_INPUTS:
      return {
        ...state,
        inputs: action.payload,
      };
    case ActionTypes.ADD_INPUT:
      return {
        ...state,
        inputs: [...state.inputs, action.payload],
      };
    case ActionTypes.UPDATE_INPUT:
      return {
        ...state,
        inputs: state.inputs.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload.data;
          }

          return el;
        }),
      };
    case ActionTypes.DELETE_INPUT:
      return {
        ...state,
        inputs: state.inputs.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.SET_TERMS:
      return {
        ...state,
        terms: action.payload,
      };
    case ActionTypes.SET_SUBMISSIONS:
      return {
        ...state,
        submissions: action.payload.data,
        total: action.payload.total,
      };
    case ActionTypes.UPDATE_SUBMISSIONS:
      return {
        ...state,
        submissions: state.submissions.map((el) => {
          const { userId, status } = action.payload;

          if (el.User.id === userId) {
            return {
              ...el,
              User: {
                ...el.User,
                enableLabß: status,
              },
            };
          }

          return el;
        }),
      };
    default:
      return state;
  }
}
