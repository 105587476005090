import { HistoryActionTypes } from "./actions";
import { HistoryActions, PaymentHistoryInterface } from "./types";

export const historyInitialState = {
  subscriptionPayments: {
    loading: false as boolean,
    data: [] as PaymentHistoryInterface[],
    total: 0 as number,
  },
  regularPayments: {
    loading: false as boolean,
    data: [] as PaymentHistoryInterface[],
    total: 0 as number,
  },
  errors: [] as any[],
};

export type HistoryState = typeof historyInitialState;

export function historyReducer(
  state: HistoryState = historyInitialState,
  action: HistoryActionTypes
): HistoryState {
  switch (action.type) {
    case HistoryActions.GET_HISTORY_SUBSCRIPTION_PAYMENTS_REQUEST:
      return {
        ...state,
        subscriptionPayments: {
          ...state.subscriptionPayments,
          loading: true,
        },
      };

    case HistoryActions.GET_HISTORY_SUBSCRIPTION_PAYMENTS_SUCCESS:
      return {
        ...state,
        subscriptionPayments: {
          ...state.subscriptionPayments,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
        },
      };

    case HistoryActions.GET_HISTORY_SUBSCRIPTION_PAYMENTS_FAILURE:
      return {
        ...state,
        subscriptionPayments: {
          ...state.subscriptionPayments,
          loading: false,
        },
        errors: [...state.errors, action.payload],
      };

    case HistoryActions.GET_HISTORY_REGULAR_PAYMENTS_REQUEST:
      return {
        ...state,
        regularPayments: {
          ...state.regularPayments,
          loading: true,
        },
      };

    case HistoryActions.GET_HISTORY_REGULAR_PAYMENTS_SUCCESS:
      return {
        ...state,
        regularPayments: {
          ...state.regularPayments,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
        },
      };

    case HistoryActions.GET_HISTORY_REGULAR_PAYMENTS_FAILURE:
      return {
        ...state,
        regularPayments: {
          ...state.regularPayments,
          loading: false,
        },
        errors: [...state.errors, action.payload],
      };

    default:
      return state;
  }
}
