export enum FriendActions {
  ADD_FRIEND_REQUEST = "ADD_FRIEND_REQUEST",
  ADD_FRIEND_SUCCESS = "ADD_FRIEND_SUCCESS",
  DELETE_FRIEND_REQUEST = "DELETE_FRIEND_REQUEST",
  DELETE_FRIEND_SUCCESS = "DELETE_FRIEND_SUCCESS",
  GET_FRIEND_LIST_REQUEST = "GET_FRIEND_LIST_REQUEST",
  GET_FRIEND_LIST_SUCCESS = "GET_FRIEND_LIST_SUCCESS",
  GET_FRIEND_USER_DETAILS_REQUEST = "GET_FRIEND_USER_DETAILS_REQUEST",
  GET_FRIEND_USER_DETAILS_SUCCESS = "GET_FRIEND_USER_DETAILS_SUCCESS",
  FRIEND_ERROR = "FRIEND_ERROR",
}

export interface AddFriendInfo {
  ownerId: number;
  friendEmail: string;
  friendName: string;
}

export interface DeleteFriendInfo {
  id: number;
  ownerId: number;
}

export interface FriendUser {
  id: number;
  name: string;
  avatarUrl: string;
}

export interface GetFriendInfo {
  id: number;
  friendEmail: string;
  friendName: string;
  friendUser: FriendUser;
}

export interface FriendListPayload {
  ownerId: number;
  page?: number;
}

export interface AddNewFriendValues {
  email: string;
  name: string;
}
