import {
  INITIAL_CATEGORY_COUNT_OPTIONS,
  INITIAL_CATEGORY_OPTIONS,
} from "../../utils/constant";
import { MainListWithSubListType } from "../../utils/type";
import { CategoryActionTypes } from "./actions";
import { CategoryActions } from "./types";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

export const categoryInitialState = {
  allCategoriesLoading: false,
  allCategoriesHasFailedToLoad: false,
  allCategories: [] as MainListWithSubListType[],
  totalSubCategories: 0 as number,
  totalSubCategoriesByMainCategory: {} as {
    [key: string]: number;
  },
  preSelectedCategoriesLoading: false as boolean,
  preSelectedSubCategoriesByMainCategory: {
    ...INITIAL_CATEGORY_OPTIONS,
  } as {
    [key: string]: CheckboxValueType[];
  },
  preSelectedSubCategoriesCountByMainCategory: {
    ...INITIAL_CATEGORY_COUNT_OPTIONS,
  } as {
    [key: string]: number;
  },
  preSelectedCategoriesByVideoLoading: false as boolean,
  preSelectedSubCategoriesByMainCategoryByVideo: {
    ...INITIAL_CATEGORY_OPTIONS,
  } as {
    [key: string]: CheckboxValueType[];
  },
  preSelectedSubCategoriesCountByMainCategoryByVideo: {
    ...INITIAL_CATEGORY_COUNT_OPTIONS,
  } as {
    [key: string]: number;
  },
  prePopulatedFreeDescriptionsByVideo: null as {
    [key: string]: string;
  } | null,
  allMainCategoryNames: {} as { [key: string]: string },
  allSubCategoryNames: {} as { [key: string]: string },
  errors: [] as any[],
};

export type CategoryState = typeof categoryInitialState;

export function categoryReducer(
  state: CategoryState = categoryInitialState,
  action: CategoryActionTypes
): CategoryState {
  switch (action.type) {
    case CategoryActions.GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        allCategoriesLoading: true,
        allCategoriesHasFailedToLoad: false,
      };
    case CategoryActions.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        allCategoriesLoading: false,
        allCategoriesHasFailedToLoad: false,
        allCategories: action.payload.allData,
        totalSubCategories: action.payload.total,
        totalSubCategoriesByMainCategory: action.payload.totalByMain,
      };
    case CategoryActions.GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        allCategoriesLoading: false,
        allCategoriesHasFailedToLoad: true,
        errors: action.payload,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_REQUEST:
      return {
        ...state,
        preSelectedCategoriesLoading: true,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_SUCCESS:
      return {
        ...state,
        preSelectedSubCategoriesByMainCategory:
          action.payload.preSelectedSubCategoriesByMainCategory,
        preSelectedSubCategoriesCountByMainCategory:
          action.payload.preSelectedSubCategoriesCountByMainCategory,
        preSelectedCategoriesLoading: false,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_FAILURE:
      return {
        ...state,
        preSelectedCategoriesLoading: false,
        errors: action.payload,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_REQUEST:
      return {
        ...state,
        preSelectedCategoriesByVideoLoading: true,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_SUCCESS:
      return {
        ...state,
        preSelectedSubCategoriesByMainCategoryByVideo:
          action.payload.preSelectedSubCategoriesByMainCategory,
        preSelectedSubCategoriesCountByMainCategoryByVideo:
          action.payload.preSelectedSubCategoriesCountByMainCategory,
        prePopulatedFreeDescriptionsByVideo:
          action.payload.prePopulatedFreeDescriptions,
        preSelectedCategoriesByVideoLoading: false,
      };
    case CategoryActions.GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_FAILURE:
      return {
        ...state,
        errors: action.payload,
        preSelectedCategoriesByVideoLoading: false,
      };
    case CategoryActions.GET_ALL_MAIN_CATEGORY_NAMES_REQUEST:
      return {
        ...state,
      };
    case CategoryActions.GET_ALL_MAIN_CATEGORY_NAMES_SUCCESS:
      return {
        ...state,
        allMainCategoryNames: action.payload,
      };
    case CategoryActions.GET_ALL_SUB_CATEGORY_NAMES_REQUEST:
      return {
        ...state,
      };
    case CategoryActions.GET_ALL_SUB_CATEGORY_NAMES_SUCCESS:
      return {
        ...state,
        allSubCategoryNames: action.payload,
      };
    case CategoryActions.CATEGORY_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
