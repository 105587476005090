export enum SouvenirActions {
  GET_SOUVENIRS = "souvenir/GET_SOUVENIRS",
  CREATE_SOUVENIR = "souvenir/CREATE_SOUVENIR",
  UPDATE_SOUVENIR = "souvenir/UPDATE_SOUVENIR",
  DELETE_SOUVENIR = "souvenir/DELETE_SOUVENIR",
  SET_ERRORS = "souvenir/SET_ERRORS",
}

export interface SouvenirInterface {
  id?: string;
  name: string;
  key?: string;
  description: string;
  stock: number;
  basePrice: number;
  url?: string;
  published?: boolean;
  roomId: string;
}
