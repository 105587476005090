import { Action } from "redux";
import { notification } from "antd";
import { AppThunk } from "../types";
import { PagesDataType } from "./types";
import { setLoading } from "../app/actions";
import { createRequest } from "../../utils/network";
import { API_CMS_PAGE } from "../../routes/constants";
import { getLanguage } from "../app/selectors";
export enum PageActions {
  PAGE_IS_LOADING = "page/PAGE_IS_LOADING",
  SET_REDIRECT_PATH = "page/SET_REDIRECT_PATH",
}
export interface SetRedirectPathAction extends Action {
  type: PageActions.SET_REDIRECT_PATH;
  payload: string;
}
export function setRedirectPath(path: string): SetRedirectPathAction {
  return {
    type: PageActions.SET_REDIRECT_PATH,
    payload: path,
  };
}
export interface GetPageAction extends Action {
  type: PageActions.PAGE_IS_LOADING;
  payload: PagesDataType;
}
export type CMSPageActionTypes = GetPageAction | SetRedirectPathAction;
export function fetchCmsPageByPath(path: string): AppThunk {
  return async (dispatch: Function) => {
    dispatch(setLoading(true));
    const { response, error } = await createRequest(
      `${API_CMS_PAGE}?path=${path}`,
      {
        method: "GET",
      }
    );
    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
    } else {
      if (!response.success) {
        dispatch(dispatchPageData(response));
      } else {
        dispatch(fetchCmsPageByPageID(response));
      }
    }
    dispatch(setLoading(false));
  };
}

export function fetchCmsPageByPageID(payload: PagesDataType): AppThunk {
  return async (dispatch: Function, getState) => {
    try {
      const language = getLanguage(getState());
      var pageId = payload.data.langJaPageId;
      if (language === "en") pageId = payload.data.langEnPageId;
      if (pageId != null && pageId !== payload.data.pageId) {
        const { response, error } = await createRequest(
          `${API_CMS_PAGE}/page-id/${pageId}`,
          {
            method: "GET",
          }
        );
        if (error && typeof error === "string") {
          notification.error({
            message: error as string,
            duration: 15.0,
          });
        } else {
          if (!response.success) dispatch(dispatchPageData(payload));
          else {
            if (response.data.path) {
              dispatch(setRedirectPath(response.data.path));
            } else {
              dispatch(fetchCmsPageByLocalePath(response));
            }
          }
        }
      } else dispatch(dispatchPageData(payload));
    } catch (error) {
      console.log("Exception: fetchCmsPageByPageID", error);
    }
  };
}

export function fetchCmsPageByLocalePath(payload: PagesDataType): AppThunk {
  return async (dispatch: Function) => {
    try {
      const LocalePath = payload.data.path;
      const { response, error } = await createRequest(
        `${API_CMS_PAGE}?path=${LocalePath}`,
        {
          method: "GET",
        }
      );
      if (error && typeof error === "string") {
        notification.error({
          message: error as string,
          duration: 15.0,
        });
      } else {
        dispatch(dispatchPageData(response));
      }
    } catch (error) {
      console.log("Exception: fetchCmsPageByLocalePath", error);
    }
  };
}

function dispatchPageData(response: PagesDataType) {
  return async (dispatch: Function) => {
    dispatch({
      type: PageActions.PAGE_IS_LOADING,
      payload: {
        data: response.data,
        success: response.success,
        errors: response.errors,
      },
    });
  };
}
