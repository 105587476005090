import { PostsActions } from "./actions";
import { ActionTypes, IPost } from "./types";

export type PostsState = {
  posts: IPost[];
  postDetail: IPost | null;
  total: number;
  isLoading: boolean;
  isSubmitting: boolean;
};

export const postsInitialState: PostsState = {
  posts: [],
  postDetail: null,
  total: 0,
  isLoading: false,
  isSubmitting: false,
};

export function postsReducer(
  state: PostsState = postsInitialState,
  action: PostsActions
): PostsState {
  switch (action.type) {
    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ActionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case ActionTypes.RESET_POSTS:
      return {
        ...state,
        posts: [],
        total: 0,
      };
    case ActionTypes.SET_POSTS:
      return {
        ...state,
        posts: [...state.posts, ...action.payload.data],
        total: action.payload.total,
      };
    case ActionTypes.SET_POST_DETAIL:
      return {
        ...state,
        postDetail: action.payload,
      };
    case ActionTypes.RESET_POST_DETAIL:
      return {
        ...state,
        postDetail: null,
      };
    case ActionTypes.ADD_POST:
      return {
        ...state,
        posts: [action.payload, ...state.posts],
      };
    case ActionTypes.EDIT_POST:
      const { id, data } = action.payload;

      return {
        ...state,
        posts: state.posts.map((el) => {
          if (el.id === id) {
            return {
              ...el,
              ...data,
            };
          }

          return el;
        }),
        postDetail:
          state.postDetail?.id === id
            ? {
                ...state.postDetail,
                ...data,
              }
            : state.postDetail,
      };
    case ActionTypes.DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((el) => el.id !== action.payload),
      };
    case ActionTypes.SET_FAVORITE_STATUS:
      return {
        ...state,
        posts: state.posts.map((el) => {
          if (el.id === action.payload) {
            return {
              ...el,
              isLiked: !el.isLiked,
              likeCount: el.isLiked ? el.likeCount - 1 : el.likeCount + 1,
            };
          }

          return el;
        }),
        postDetail:
          state.postDetail?.id === action.payload
            ? {
                ...state.postDetail,
                isLiked: !state.postDetail.isLiked,
                likeCount: state.postDetail.isLiked
                  ? state.postDetail.likeCount - 1
                  : state.postDetail.likeCount + 1,
              }
            : state.postDetail,
      };
    case ActionTypes.ADD_COMMENT:
      return {
        ...state,
        postDetail: state.postDetail
          ? {
              ...state.postDetail,
              commentCount: state.postDetail.commentCount + 1,
              comments: state.postDetail.comments
                ? [action.payload, ...state.postDetail.comments]
                : [action.payload],
            }
          : state.postDetail,
      };
    default:
      return state;
  }
}
