export enum CategoryActions {
  GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE = "GET_ALL_CATEGORIES_FAILURE",
  GET_PRE_SELECTED_CATEGORIES_REQUEST = "GET_PRE_SELECTED_CATEGORIES_REQUEST",
  GET_PRE_SELECTED_CATEGORIES_SUCCESS = "GET_PRE_SELECTED_CATEGORIES_SUCCESS",
  GET_PRE_SELECTED_CATEGORIES_FAILURE = "GET_PRE_SELECTED_CATEGORIES_FAILURE",
  GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_REQUEST = "GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_REQUEST",
  GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_SUCCESS = "GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_SUCCESS",
  GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_FAILURE = "GET_PRE_SELECTED_CATEGORIES_BY_VIDEO_FAILURE",
  GET_ALL_MAIN_CATEGORY_NAMES_REQUEST = "GET_ALL_MAIN_CATEGORY_NAMES_REQUEST",
  GET_ALL_MAIN_CATEGORY_NAMES_SUCCESS = "GET_ALL_MAIN_CATEGORY_NAMES_SUCCESS",
  GET_ALL_SUB_CATEGORY_NAMES_REQUEST = "GET_ALL_SUB_CATEGORY_NAMES_REQUEST",
  GET_ALL_SUB_CATEGORY_NAMES_SUCCESS = "GET_ALL_SUB_CATEGORY_NAMES_SUCCESS",
  CATEGORY_ERROR = "CATEGORY_ERROR",
}

export interface PreSelectedCategoriesOptions {
  mainCategoryIds?: (number | string)[];
  subCategoryIds?: (number | string)[];
  categories?: "all";
}
