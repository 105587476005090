export interface INotification {
  id: number;
  title?: string;
  titleJP?: string;
  text: string;
  textJP: string;
  isRead: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
  sendTo?: string | null;
}

export interface FetchRecentNotificationsPayload {
  sendAllData: boolean;
}

export enum ActionTypes {
  SET_NOTIFICATIONS = "notification/SET_NOTIFICATIONS",
  SET_RECENT_NOTIFICATIONS = "notification/SET_RECENT_NOTIFICATIONS",
  NEW_NOTIFICATION = "notification/NEW_NOTIFICATION",
  READ_NOTIFICATIONS = "notification/READ_NOTIFICATIONS",
  SET_IS_SUBMITTING = "notification/SET_IS_SUBMITTING",
  DELETE_NOTIFICATION = "notification/DELETE_NOTIFICATION",
  SET_NOTIFICATION_DROPDOWN_VISIBILITY = "notification/SET_NOTIFICATION_DROPDOWN_VISIBILITY",
  SET_NOTIFICATIONS_POPUP_OPEN = "account/SET_NOTIFICATIONS_POPUP_OPEN",
  SET_NOTIFICATIONS_POPUP_CLOSE = "account/SET_NOTIFICATIONS_POPUP_CLOSE",
}
