import { FriendActionTypes } from "./actions";
import {
  FriendActions,
  AddFriendInfo,
  DeleteFriendInfo,
  GetFriendInfo,
  FriendUser,
} from "./types";

export const friendInitialState = {
  addFriendInfo: {} as AddFriendInfo,
  deleteFriendInfo: {} as DeleteFriendInfo,
  friendListLoading: false as boolean,
  friendList: [] as GetFriendInfo[],
  friendListCount: 0 as number,
  hasMoreFriends: false as boolean,
  friendUser: {} as FriendUser,
  errors: [] as any[],
};

export type FriendState = typeof friendInitialState;

export function friendReducer(
  state: FriendState = friendInitialState,
  action: FriendActionTypes
): FriendState {
  switch (action.type) {
    case FriendActions.ADD_FRIEND_REQUEST:
      return {
        ...state,
      };
    case FriendActions.ADD_FRIEND_SUCCESS:
      return {
        ...state,
        addFriendInfo: action.payload,
      };
    case FriendActions.DELETE_FRIEND_REQUEST:
      return {
        ...state,
      };
    case FriendActions.DELETE_FRIEND_SUCCESS:
      return {
        ...state,
        deleteFriendInfo: action.payload,
      };
    case FriendActions.GET_FRIEND_LIST_REQUEST:
      return {
        ...state,
        friendListLoading: true,
      };
    case FriendActions.GET_FRIEND_LIST_SUCCESS:
      const page = action.payload.page ? action.payload.page : 1;
      const friendListCount = action.payload.friendListCount;
      const friendList =
        page > 1
          ? [...state.friendList, ...action.payload.friendList]
          : action.payload.friendList;
      const hasMoreFriends =
        action.payload.friendListCount !== friendList.length &&
        action.payload.friendListCount > friendList.length;

      return {
        ...state,
        friendList,
        friendListCount,
        hasMoreFriends,
        friendListLoading: false,
      };
    case FriendActions.GET_FRIEND_USER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case FriendActions.GET_FRIEND_USER_DETAILS_SUCCESS:
      return {
        ...state,
        friendUser: action.payload,
      };
    case FriendActions.FRIEND_ERROR:
      return {
        ...state,
        errors: action.payload,
        friendListLoading: false,
      };
    default:
      return state;
  }
}
