import { SouvenirsActionTypes } from "./actions";
import { SouvenirActions, SouvenirInterface } from "./types";

export const souvenirInitialState = {
  souvenirs: [] as SouvenirInterface[],
  errors: [] as any[],
};

export type SouvenirState = typeof souvenirInitialState;

export function souvenirReducer(
  state: SouvenirState = souvenirInitialState,
  action: SouvenirsActionTypes
): SouvenirState {
  const { souvenirs } = state;
  let index = 0;

  switch (action.type) {
    case SouvenirActions.GET_SOUVENIRS:
      return {
        ...state,
        souvenirs: action.payload as SouvenirInterface[],
      };
    case SouvenirActions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload as any[],
      };
    case SouvenirActions.CREATE_SOUVENIR:
      return {
        ...state,
        souvenirs: [action.payload, ...state.souvenirs],
      };
    case SouvenirActions.UPDATE_SOUVENIR:
      index = souvenirs.findIndex((e) => e.id === action.payload.id);
      const souvenir = {
        ...action.payload,
        key: action.payload.id,
      };

      return {
        ...state,
        souvenirs: [
          ...souvenirs.slice(0, index),
          souvenir,
          ...souvenirs.slice(index + 1),
        ],
      };
    case SouvenirActions.DELETE_SOUVENIR:
      index = souvenirs.findIndex((e) => e.id === action.payload.id);

      return {
        ...state,
        souvenirs: [
          ...souvenirs.slice(0, index),
          ...souvenirs.slice(index + 1),
        ],
      };
    default:
      return state;
  }
}
