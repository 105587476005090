import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Loader } from "../components/Loader/Loader";
import { history } from "./history";
import * as routes from "./constants";
import useUserAuth from "../utils/useUserAuth";
import { getAuthToken } from "../utils/index";
import { LastLocationProvider } from "react-router-last-location";

import { fetchRecentNotifications } from "../store/notification/actions";
import { actionTypes as SocketActions } from "../middleware/types";
import {
  getIsNotificationDropdownVisible,
  notificationsPopupOpenSelector,
} from "../store/notification/selectors";

const PublicRoute = lazy(() => import("./PublicRoute"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const Home = lazy(() => import("../pages/Home"));
const Login = lazy(() => import("../pages/auth/Login"));
const ForgotPassword = lazy(() => import("../pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));
const Register = lazy(() => import("../pages/auth/Register"));
const LiveCommerce = lazy(() => import("../pages/LiveCommerce"));
const UserSetting = lazy(() => import("../pages/user/UserSetting"));
const MyPage = lazy(() => import("../pages/user/MyPage"));
const PayoutEarnings = lazy(
  () => import("../pages/user/MyPage/PayoutEarnings")
);
const NewCompleteBook = lazy(
  () => import("../pages/user/MyPage/NewCompleteBook")
);
const NewCompleteBookRanking = lazy(
  () => import("../pages/user/MyPage/NewCompleteBookRanking")
);
const MyFriends = lazy(() => import("../pages/user/MyPage/MyFriends"));
const MyFavourites = lazy(() => import("../pages/user/MyPage/MyFavourites"));
const MyBookingHistory = lazy(
  () => import("../pages/user/MyPage/MyBookingHistory")
);
const MyBookingHistoryActiveBookings = lazy(
  () => import("../pages/user/MyPage/MyBookingHistory/ActiveBookings")
);
const MyReviews = lazy(() => import("../pages/user/MyPage/MyReviews"));
const MyPaymentSubscriptionHistory = lazy(
  () => import("../pages/user/MyPage/MyPaymentSubscriptionHistory")
);
const UserProfile = lazy(() => import("../pages/Profile"));
const NewService = lazy(() => import("../pages/NewService"));
const UserAdmin = lazy(() => import("../pages/admin/UserAdmin"));
const ProAdminDashboard = lazy(
  () => import("../pages/ProAdmin/ProAdminDashboard")
);
const VrTherapyRooms = lazy(
  () => import("../pages/LanguageFree/components/VrTherapyRooms")
);
const VrPartyRooms = lazy(
  () => import("../pages/LanguageFree/components/VrPartyRooms")
);
const PaymentPlan = lazy(() => import("../pages/PaymentPlan"));
const PrivacyPolicyJP = lazy(
  () => import("../pages/Landing/PrivacyPolicy/PrivacyPolicyJP")
);
const PrivacyPolicyEN = lazy(
  () => import("../pages/Landing/PrivacyPolicy/PrivacyPolicyEN")
);
const TermsUseJP = lazy(() => import("../pages/Landing/TermsUse/TermUseJP"));
const TermsUseEN = lazy(() => import("../pages/Landing/TermsUse/TermUseEN"));
const TermsUseGuest = lazy(() => import("../pages/Landing/TermsUseGuest"));
const TermsUseGuide = lazy(() => import("../pages/Landing/TermsUseGuide"));
const SCTLawJP = lazy(() => import("../pages/Landing/SCTLaw/SCTLawJP"));
const SCTLawEN = lazy(() => import("../pages/Landing/SCTLaw/SCTLawEN"));
const EmailConfirm = lazy(() => import("../pages/EmailConfirm"));
const Dictionary = lazy(() => import("../pages/Dictionary"));
const ResetEmail = lazy(() => import("../pages/ResetEmail"));
const FAQ = lazy(() => import("../pages/Landing/FAQ"));
const TravelTour = lazy(() => import("../pages/Travel/Tour"));
const TravelGuide = lazy(() => import("../pages/Travel/Guide"));
const TravelSelfHostExplanation = lazy(
  () => import("../pages/Travel/SelfHostExplanation")
);
const GuideMeetUpExplanation = lazy(
  () => import("../pages/Travel/GuideMeetUp/GuideMeetUpExplanation")
);
const SelfhostTourDetail = lazy(
  () => import("../pages/Travel/SelfHost/components/TourDetail")
);
const SelfHostTourExplore = lazy(
  () => import("../pages/Travel/Tour/components/TourDetail")
);
const TherapyTourDetail = lazy(() => import("../pages/Therapy/TourDetail"));
const WorldDetail = lazy(() => import("../pages/World/TourDetail"));
const Notifications = lazy(() => import("../pages/Notifications"));
const ConcertDetail = lazy(() => import("../pages/ConcertDetail"));
const LpVr = lazy(() => import("../pages/Landing/Party/Lp1"));
const ProAdminPaymentHistory = lazy(
  () => import("../pages/ProAdmin/ProAdminPaymentHistory")
);
const ContinueWithGoogleCallback = lazy(
  () => import("../pages/auth/ContinueWithGoogleCallback")
);
const DiscontinueWithFacebookDataDeleted = lazy(
  () => import("../pages/auth/DiscontinueWithFacebookDataDeleted")
);
const ResetPasswordOtp = lazy(() => import("../pages/auth/ResetPasswordOtp"));
const ContinueWithTwitterCallback = lazy(
  () => import("../pages/auth/ContinueWithTwitterCallback")
);
const ContinueWithLineCallback = lazy(
  () => import("../pages/auth/ContinueWithLineCallback")
);
const WorldRamblerPrices = lazy(
  () => import("../pages/LanguageFree/components/VrTrip/WorldRamblerPrices")
);
const Community = lazy(() => import("../pages/Community"));
const MyPosts = lazy(() => import("../pages/Community/MyPosts"));
const PostDetails = lazy(() => import("../pages/PostDetails"));
const ForgotPasswordSuccess = lazy(
  () => import("../pages/auth/ForgotPasswordSuccess")
);
const InvitesByShortUrlId = lazy(() => import("../pages/Invites"));
const TermsSelfHostJP = lazy(
  () => import("../pages/Landing/TermsSelfHost/TermsSelfHostJP")
);
const TermsSelfHostEN = lazy(
  () => import("../pages/Landing/TermsSelfHost/TermsSelfHostEN")
);
const TermsParty = lazy(() => import("../pages/Landing/TermsParty"));
const TripRegister = lazy(() => import("../pages/Trip/TripRegister"));
const TripEdit = lazy(() => import("../pages/Trip/TripEdit"));
const WorldEdit = lazy(() => import("../pages/World/WorldEdit"));

const EventExplaination = lazy(() => import("../pages/EventExplaination"));

const SearchResults = lazy(() => import("../pages/SearchResults"));

const PasswordConfirm = lazy(() => import("../pages/Withdraw/PasswordConfirm"));
const WithdrawConfirmation = lazy(
  () => import("../pages/Withdraw/Confirmation")
);
const WithdrawComplete = lazy(() => import("../pages/Withdraw/Complete"));
const TestGaussianSplatting = lazy(
  () => import("../pages/TestGaussianSplatting")
);
const InfoUser = lazy(() => import("../pages/InfoUser"));
const InfoEnUser = lazy(() => import("../pages/InfoEnUser"));
const TravelAgency = lazy(() => import("../pages/TravelAgency"));
const EnTravelAgency = lazy(() => import("../pages/EnTravelAgency"));
const TimeCapsule = lazy(() => import("../pages/TimeCapsule"));
const EnTimeCapsule = lazy(() => import("../pages/EnTimeCapsule"));
const InfoMeet = lazy(() => import("../pages/InfoMeet"));
const InfoMeetEN = lazy(() => import("../pages/InfoMeetEN"));
// const EnglishGptTeacherV2 = lazy(
//   () => import("../pages/Gpt/EnglishGptTeacherV2")
// );
// const AiMama = lazy(() => import("../pages/Gpt/AiMama"));
// const AiHost = lazy(() => import("../pages/Gpt/AiHost"));
// const AiBuddha = lazy(() => import("../pages/Gpt/AiBuddha"));
const VrSicknessTransfer = lazy(() => import("../pages/VrSicknessTransfer"));
const MsAiTransfer = lazy(() => import("../pages/Transfer/MsAiTransfer"));
const AiTransfer = lazy(() => import("../pages/Transfer/AiTransfer"));
const ColumnBlog = lazy(() => import("../pages/Column"));
const ColumnGlacier = lazy(() => import("../pages/Column/Glacier"));
const ColumnFort = lazy(() => import("../pages/Column/Fort"));
const ColumnZoo = lazy(() => import("../pages/Column/Zoo"));
const ColumnCity = lazy(() => import("../pages/Column/City"));
const ColumnLake = lazy(() => import("../pages/Column/Lake"));
const CmsPage = lazy(() => import("../pages/CmsPage"));
const ColumnsBlog = lazy(() => import("../pages/Columns"));
const ColumnCastle = lazy(() => import("../pages/Column/Castle"));
const ColumnNationalPark = lazy(() => import("../pages/Column/NationalPark"));
const ColumnCathedral = lazy(() => import("../pages/Column/Cathedral"));
const ColumnWaterfall = lazy(() => import("../pages/Column/Waterfall"));
const ColumnFestival = lazy(() => import("../pages/Column/Festival"));
const ColumnSunset = lazy(() => import("../pages/Column/Sunset"));
const ColumnRuins = lazy(() => import("../pages/Column/Ruins"));
const ColumnNightview = lazy(() => import("../pages/Column/Nightview"));
const ColumnMountain = lazy(() => import("../pages/Column/Mountain"));
const ColumnSea = lazy(() => import("../pages/Column/Sea"));
const ColumnRock = lazy(() => import("../pages/Column/Rock"));
const ColumnLondon = lazy(() => import("../pages/Column/London"));
const ColumnGreece = lazy(() => import("../pages/Column/Greece"));
const ColumnPoland = lazy(() => import("../pages/Column/Poland"));
const ColumnTunisia = lazy(() => import("../pages/Column/Tunisia"));
const ColumnMexico = lazy(() => import("../pages/Column/Mexico"));
const ColumnSouthAmerica = lazy(() => import("../pages/Column/SouthAmerica"));
const ColumnIndonesia = lazy(() => import("../pages/Column/Indonesia"));
const ColumnWorldHeritageEurope = lazy(
  () => import("../pages/Column/WorldHeritage/Europe")
);
const ColumnWorldHeritageAsia = lazy(
  () => import("../pages/Column/WorldHeritage/Asia")
);
const TvMirror = lazy(() => import("../pages/Column/TvMirror"));
const OvercomeVrSickness = lazy(
  () => import("../pages/Column/OvercomeVrSickness")
);
const OvercomeVrSicknessEn = lazy(
  () => import("../pages/Column/OvercomeVrSicknessEn")
);
const LanguageLearning = lazy(() => import("../pages/Column/LanguageLearning"));
const IntroOnlineTour = lazy(() => import("../pages/Column/IntroOnlineTour"));
const BusinessLandingPage = lazy(() => import("../pages/Business/LandingPage"));
const MaintenanceCessation = lazy(
  () => import("../pages/Maintenance/Cessation")
);

export function AppRouter() {
  const dispatch = useDispatch();
  useUserAuth();
  const token = getAuthToken();
  const isNotificationDropdownVisible = useSelector(
    getIsNotificationDropdownVisible
  );
  const notificationsPopupOpen = useSelector(notificationsPopupOpenSelector);

  useEffect(() => {
    if (token) {
      dispatch({
        type: SocketActions.CONNECT_SOCKET,
      });
    } else {
      dispatch({
        type: SocketActions.DISCONNECT_SOCKET,
      });
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(
        fetchRecentNotifications({
          sendAllData: isNotificationDropdownVisible || notificationsPopupOpen,
        })
      );
    }
  }, [token, dispatch, isNotificationDropdownVisible, notificationsPopupOpen]);

  return (
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <LastLocationProvider>
          <Switch>
            <PrivateRoute
              exact
              path={routes.PAGE_GAUSSIAN_SPLATTING}
              component={TestGaussianSplatting}
            />
            <PublicRoute
              exact
              path={routes.HOME_ROUTE_PATH}
              component={Home}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.INFO_USER_PATH}
              component={InfoUser}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.TRAVEL_AGENCY_PATH}
              component={TravelAgency}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_TRAVEL_AGENCY_PATH}
              component={EnTravelAgency}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.TIME_CAPSULE_PATH}
              component={TimeCapsule}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_TIME_CAPSULE_PATH}
              component={EnTimeCapsule}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_INFO_USER_PATH}
              component={InfoEnUser}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.INFO_MEET_PATH}
              component={InfoMeet}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.INFO_MEET_PATH_EN}
              component={InfoMeetEN}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.ENGLISH_GPT_TEACHER_PATH}
              component={MaintenanceCessation}
            />
            <PublicRoute
              exact
              path={routes.AI_MAMA_PATH}
              component={MaintenanceCessation}
            />
            <PublicRoute
              exact
              path={routes.AI_HOST_PATH}
              component={MaintenanceCessation}
            />
            <PublicRoute
              exact
              path={routes.AI_BUDDHA_PATH}
              component={MaintenanceCessation}
            />
            <PublicRoute
              exact
              path={routes.COLUMNS_BLOG}
              component={ColumnsBlog}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMNS_BLOG_EN}
              component={ColumnsBlog}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_BLOG}
              component={ColumnBlog}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_GLACIER}
              component={ColumnGlacier}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_FORT}
              component={ColumnFort}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_ZOO}
              component={ColumnZoo}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_CITY}
              component={ColumnCity}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_LAKE}
              component={ColumnLake}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_CASTLE}
              component={ColumnCastle}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_NATIONALPARK}
              component={ColumnNationalPark}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_CATHEDRAL}
              component={ColumnCathedral}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_WATERFALL}
              component={ColumnWaterfall}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_FESTIVAL}
              component={ColumnFestival}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_SUNSET}
              component={ColumnSunset}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_RUINS}
              component={ColumnRuins}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_NIGHTVIEW}
              component={ColumnNightview}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_MOUNTIAN}
              component={ColumnMountain}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_SEA}
              component={ColumnSea}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_ROCK}
              component={ColumnRock}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_LONDON}
              component={ColumnLondon}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_GREECE}
              component={ColumnGreece}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_POLAND}
              component={ColumnPoland}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_TUNISIA}
              component={ColumnTunisia}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_MEXICO}
              component={ColumnMexico}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_SOUTH_AMERICA}
              component={ColumnSouthAmerica}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_INDONESIA}
              component={ColumnIndonesia}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_WORLDHERITAGE_EUROPE}
              component={ColumnWorldHeritageEurope}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_WORLDHERITAGE_ASIA}
              component={ColumnWorldHeritageAsia}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.TV_MIRROR}
              component={TvMirror}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_OVERCOME_VR_SICKNESS_PATH}
              component={OvercomeVrSickness}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_OVERCOME_VR_SICKNESS_EN_PATH}
              component={OvercomeVrSicknessEn}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.INTRO_ONLINE_TOUR}
              component={IntroOnlineTour}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.PROFILE_ROUTE_PATH}
              component={UserProfile}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.SEARCH_RESULTS_PAGE_ROUTE_PATH}
              component={SearchResults}
              isWithNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.COMMUNITY_ROUTE_PATH}
              component={Community}
            />
            <PrivateRoute
              exact
              path={routes.COMMUNITY_MY_POSTS_ROUTE_PATH}
              component={MyPosts}
            />
            <PrivateRoute
              exact
              path={routes.COMMUNITY_POST_DETAIL_ROUTE_PATH}
              component={PostDetails}
            />
            <PublicRoute
              exact
              path={routes.PROFILE_POST_DETAIL_ROUTE_PATH}
              component={PostDetails}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.LP_VR_PARTY_PATH}
              component={LpVr}
            />
            <Route
              exact
              path={routes.USER_CONFIRM_EMAIL}
              component={EmailConfirm}
            />
            <Route
              exact
              path={routes.USER_RESET_EMAIL_ROUTE_PATH}
              component={ResetEmail}
            />
            <PublicRoute
              exact
              path={routes.PRIVACY_POLICY_ROUTE_PATH}
              component={PrivacyPolicyJP}
            />
            <PublicRoute
              exact
              path={routes.PRIVACY_POLICY_ROUTE_PATH_EN}
              component={PrivacyPolicyEN}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_ROUTE_PATH}
              component={TermsUseJP}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_ROUTE_PATH_EN}
              component={TermsUseEN}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_GUEST_ROUTE_PATH}
              component={TermsUseGuest}
            />
            <PublicRoute
              exact
              path={routes.TERMS_USE_GUIDE_ROUTE_PATH}
              component={TermsUseGuide}
            />
            <PublicRoute
              exact
              path={routes.TERMS_SELFHOST_ROUTE_PATH}
              component={TermsSelfHostJP}
            />
            <PublicRoute
              exact
              path={routes.TERMS_SELFHOST_ROUTE_PATH_EN}
              component={TermsSelfHostEN}
            />
            <PublicRoute
              exact
              path={routes.TERMS_PARTY_ROUTE_PATH}
              component={TermsParty}
            />
            <PublicRoute
              exact
              path={routes.SCT_LAW_ROUTE_PATH}
              component={SCTLawJP}
            />
            <PublicRoute
              exact
              path={routes.SCT_LAW_ROUTE_PATH_EN}
              component={SCTLawEN}
            />
            <PublicRoute
              exact
              path={routes.EVENT_EXPLAINATION_ROUTE_PATH}
              component={EventExplaination}
            />
            <PublicRoute
              exact
              path={routes.USER_LOGIN_ROUTE_PATH}
              component={Login}
              hasReferer
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_REGISTER_ROUTE_PATH}
              component={Register}
              hasReferer
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_FORGOT_PASS_ROUTE_PATH}
              component={ForgotPassword}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_FORGOT_PASS_SUCCESS_ROUTE_PATH}
              component={ForgotPasswordSuccess}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_RESET_PASS_ROUTE_PATH}
              component={ResetPassword}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_RESET_PASS_CONFIRM_OTP_ROUTE_PATH}
              component={ResetPasswordOtp}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_CONTINUE_WITH_GOOGLE_CALLBACK_ROUTE_PATH}
              component={ContinueWithGoogleCallback}
              redirect
            />
            <PublicRoute
              exact
              path={
                routes.USER_DISCONTINUE_WITH_FACEBOOK_DATA_DELETED_ROUTE_PATH
              }
              component={DiscontinueWithFacebookDataDeleted}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_CONTINUE_WITH_TWITTER_CALLBACK_ROUTE_PATH}
              component={ContinueWithTwitterCallback}
              redirect
            />
            <PublicRoute
              exact
              path={routes.USER_CONTINUE_WITH_LINE_CALLBACK_ROUTE_PATH}
              component={ContinueWithLineCallback}
              redirect
            />
            <PublicRoute exact path={routes.FAQ_PATH} component={FAQ} />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_ROUTE_PATH}
              component={MyPage}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_PAYOUT_ROUTE_PATH}
              component={PayoutEarnings}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_COMPLETE_BOOK_ROUTE_PATH}
              component={NewCompleteBook}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_COMPLETE_BOOK_RANKING_ROUTE_PATH}
              component={NewCompleteBookRanking}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_FRIENDS_ROUTE_PATH}
              component={MyFriends}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_FAVOURITES_ROUTE_PATH}
              component={MyFavourites}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_BOOKING_HISTORY_ROUTE_PATH}
              component={MyBookingHistory}
            />

            <PrivateRoute
              exact
              path={
                routes.USER_MYPAGETRAVEL_BOOKING_HISTORY_ACTIVE_BOOKINGS_ROUTE_PATH
              }
              component={MyBookingHistoryActiveBookings}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_REVIEWS_ROUTE_PATH}
              component={MyReviews}
            />
            <PrivateRoute
              exact
              path={routes.USER_MYPAGETRAVEL_PAYMENT_HISTORY_ROUTE_PATH}
              component={MyPaymentSubscriptionHistory}
            />
            <PrivateRoute
              exact
              path={routes.NEW_WORLD_PATH}
              component={NewService}
            />
            <PublicRoute
              exact
              path={routes.VR_THERAPY_ROOMS_ROUTE_PATH}
              component={VrTherapyRooms}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.VR_PARTY_ROOMS_ROUTE_PATH}
              component={VrPartyRooms}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.SELFHOST_TOUR_EXPLORE_PATH}
              component={SelfHostTourExplore}
              isWithNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.LIVE_COMMERCE_ROUTE_PATH}
              component={LiveCommerce}
            />
            <PrivateRoute
              exact
              path={routes.USER_SETTING_ROUTE_PATH}
              component={UserSetting}
            />
            <PublicRoute
              path={[
                routes.PRICES_ROUTE_PATH,
                routes.PARTY_PAYMENT_ROUTE_PATH,
                routes.THERAPY_PAYMENT_PATH,
              ]}
              component={PaymentPlan}
              isWithNavbar={true}
            />
            <PublicRoute
              path={routes.TRAVEL_SELFHOST_PRICES_PATH}
              component={WorldRamblerPrices}
              isWithNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.ADMIN_ROUTE_PATH}
              component={UserAdmin}
            />
            <PrivateRoute
              exact
              path={routes.PRO_ADMIN_DASHBOARD_PATH}
              component={ProAdminDashboard}
            />
            <PrivateRoute
              exact
              path={routes.PRO_ADMIN_PAYMENT_HISTORY_PATH}
              component={ProAdminPaymentHistory}
            />
            <PrivateRoute
              exact
              path={routes.SELFHOST_TOUR_ROUTE_PATH}
              component={SelfhostTourDetail}
            />
            <PublicRoute
              exact
              path={routes.THERAPY_TOUR_ROUTE_PATH}
              component={TherapyTourDetail}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.WORLD_ROUTE_PATH}
              component={WorldDetail}
              isWithNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.DICTIONARY_ROUTE_PATH}
              component={Dictionary}
            />
            <PrivateRoute
              exact
              path={[
                routes.TRAVEL_REGISTRATION_ROUTE_PATH_WITHOUT_SLUG,
                routes.TRAVEL_REGISTRATION_ROUTE_PATH,
              ]}
              component={TripRegister}
            />
            <PublicRoute
              exact
              path={routes.TRAVEL_TOUR_ROUTE_PATH}
              component={TravelTour}
              isWithNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.TRAVEL_HOSTMANAGEMENT_ROUTE_PATH}
              component={() => <Redirect to={routes.PROFILE_ROUTE_PATH} />}
            />
            <PrivateRoute
              exact
              path={routes.TRAVEL_EDIT_ROUTE_PATH}
              component={TripEdit}
            />
            <PrivateRoute
              exact
              path={routes.WORLD_EDIT_ROUTE_PATH}
              component={WorldEdit}
            />
            <PrivateRoute
              exact
              isWithoutNavbar
              path={routes.TRAVEL_GUIDEDETAIL_PATH}
              component={TravelGuide}
            />
            <PrivateRoute
              exact
              path={routes.NOTIFICATION_ROUTE_PATH}
              component={Notifications}
            />
            <Route
              exact
              path={routes.TRAVEL_SELFHOST_INTRO_PATH}
              component={TravelSelfHostExplanation}
            />
            <Route
              exact
              path={routes.TRAVEL_GUIDEMEETUP_INTRO_PATH}
              component={GuideMeetUpExplanation}
            />
            <Route
              exact
              path={routes.INVITES_BY_SHORT_URL_ID_ROUTE_PATH}
              component={InvitesByShortUrlId}
            />
            <PublicRoute
              exact
              path={routes.CONCERT_DETAIL_ROUTE_PATH}
              component={ConcertDetail}
              isWithNavbar
            />
            <PrivateRoute
              exact
              path={routes.WITHDRAW_PASSWORD_CONFIRM}
              component={PasswordConfirm}
              isWithoutNavbar={true}
            />
            <PrivateRoute
              exact
              path={routes.WITHDRAW_CONFIRMATION}
              component={WithdrawConfirmation}
              isWithoutNavbar={true}
            />
            <Route
              exact
              path={routes.WITHDRAW_COMPLETE}
              component={WithdrawComplete}
            />
            {/**
             * English route path
             */}
            <PublicRoute
              exact
              path={routes.HOME_EN_ROUTE_PATH}
              component={Home}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_TRAVEL_TOUR_ROUTE_PATH}
              component={TravelTour}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_INFO_USER_PATH}
              component={InfoEnUser}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_SELFHOST_TOUR_EXPLORE_PATH}
              component={SelfHostTourExplore}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.EN_WORLD_ROUTE_PATH}
              component={WorldDetail}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.VR_SICKNESS_TEST_PATH}
              component={VrSicknessTransfer}
            />
            <PublicRoute
              exact
              path={routes.VR_SICKNESS_TEST_ENG_PATH}
              component={VrSicknessTransfer}
            />
            <PublicRoute
              exact
              path={routes.COLUMN_LANGUAGE_LEARNING_PATH}
              component={LanguageLearning}
              isWithNavbar={true}
            />
            <PublicRoute
              exact
              path={routes.TRANSITION_TO_MSAI}
              component={MsAiTransfer}
            />
            <PublicRoute
              exact
              path={routes.TRANSITION_TO_AI}
              component={AiTransfer}
            />
            <PublicRoute
              exact
              path={routes.BUSINESS_LANDING_PAGE}
              component={BusinessLandingPage}
            />
            <PublicRoute
              exact
              path=""
              component={CmsPage}
              isWithNavbar={true}
            />
            <Redirect from="*" to={routes.HOME_ROUTE_PATH} />
          </Switch>
        </LastLocationProvider>
      </Router>
    </Suspense>
  );
}
