export enum InviteActions {
  SEND_EMAIL_INVITES_TO_GUESTS_REQUEST = "SEND_EMAIL_INVITES_TO_GUESTS_REQUEST",
  SEND_EMAIL_INVITES_TO_GUESTS_SUCCESS = "SEND_EMAIL_INVITES_TO_GUESTS_SUCCESS",
  SEND_EMAIL_INVITES_TO_FRIENDS_REQUEST = "SEND_EMAIL_INVITES_TO_FRIENDS_REQUEST",
  SEND_EMAIL_INVITES_TO_FRIENDS_SUCCESS = "SEND_EMAIL_INVITES_TO_FRIENDS_SUCCESS",
  SEND_EMAIL_INVITES_ERROR = "SEND_EMAIL_INVITES_ERROR",
}

export interface InviteInfoEmails {
  emails: string[];
  emailsFriendList?: string[];
}

export interface InviteInfoEmailsForGifting {
  emailsForGifting: string[];
  emailsFriendListForGifting?: string[];
}

export interface InviteToGuestsInfoType {
  emails: string[];
  emailInviteLink: string;
  tourTitle: string;
  tourTitleJp?: string;
  hostId: number;
  hostName: string;
}

export interface InviteToFriendsInfoType {
  emails: string[];
  emailsFriendList?: string[];
  emailInviteLink: string;
  tourTitle: string;
  tourTitleJp?: string;
  tourItineraryTimeStart: string;
  senderFriendId: number;
  senderFriendName: string;
}
