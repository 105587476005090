import { SuggestionActionTypes } from "./action";
import { SuggestionActions } from "./types";

export const suggestionInitialState = {
  uniqueNamesLoading: true,
  uniqueNames: [] as string[],
  uniquePasswordsLoading: true,
  uniquePasswords: [] as string[],
  errors: [] as any[],
};

export type SuggestionState = typeof suggestionInitialState;

export function suggestionReducer(
  state: SuggestionState = suggestionInitialState,
  action: SuggestionActionTypes
): SuggestionState {
  switch (action.type) {
    case SuggestionActions.UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_REQUEST:
      return {
        ...state,
        uniqueNamesLoading: true,
      };
    case SuggestionActions.UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_SUCCESS:
      return {
        ...state,
        uniqueNamesLoading: false,
        uniqueNames: action.payload,
      };
    case SuggestionActions.UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_REQUEST:
      return {
        ...state,
        uniquePasswordsLoading: true,
      };
    case SuggestionActions.UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_SUCCESS:
      return {
        ...state,
        uniquePasswordsLoading: false,
        uniquePasswords: action.payload,
      };
    case SuggestionActions.UNIQUE_SUGGESTION_ERROR:
      return {
        ...state,
        uniqueNamesLoading: false,
        uniquePasswordsLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
