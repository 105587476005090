import { createSelector } from "reselect";
import { State } from "../state";
import { AppState } from "./reducer";

export function getApp(state: State) {
  return state.app;
}

export const getLanguage = createSelector(
  getApp,
  (app: AppState) => app.language
);

export const isLoading = createSelector(getApp, (app: AppState) => app.loading);

export const getRecentPosts = createSelector(
  getApp,
  (app: AppState) => app.recentPosts
);

export const getHasWebpSupport = createSelector(
  getApp,
  (app: AppState) => app.hasWebpSupport
);

export const getHasWebpSupportChecked = createSelector(
  getApp,
  (app: AppState) => app.hasWebpSupportChecked
);

export const getAllowLiveBroadcastTestModalVisibility = createSelector(
  getApp,
  (app: AppState) => app.allowLiveBroadcastTestModalVisibility
);
