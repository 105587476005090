import { RoomsActionTypes } from "./actions";
import {
  RoomActions,
  RoomInterface,
  RegisterTourVideo,
  VUMSVideo,
  SelfExploreVideo,
  PartyRoomInterface,
  TherapyRoomInterface,
  StreamingTestVideo,
  TrialVideo,
  IPartyLog,
  FavouriteVideo,
  Service,
  RoomIdVideo,
  LibraryVideo,
  WrBestSceneInterface,
  PurchaseHistoryItemByUser,
  HistoryDetail,
  BookingInterface,
  StatisticItemInterface,
  TripTutorialInterface,
} from "./types";

export const roomInitialState = {
  isSubmitting: false,
  rooms: [] as RoomInterface[],
  wrBestScene: null as WrBestSceneInterface | null,
  wrBestSceneIsFetched: false,
  wrBestSceneKids: null as WrBestSceneInterface | null,
  wrBestSceneKidsIsFetched: false,
  selectedVideo: null as FavouriteVideo | VUMSVideo | null,
  favouriteVideos: [] as FavouriteVideo[],
  countFavouriteVideos: 0,
  roomIdVideos: [] as RoomIdVideo[],
  countRoomIdVideos: 0,
  registerTourVideo: {} as RegisterTourVideo,
  errors: [] as any[],
  countVUMSVideos: 0,
  vumsVideos: [] as VUMSVideo[],
  selfExploreVideos: [] as SelfExploreVideo[],
  countSelfExploreVideos: 0,
  personalLibraryVideos: [] as LibraryVideo[],
  countPersonalLibraryVideos: 0,
  partyRooms: [] as PartyRoomInterface[],
  therapyRooms: [] as TherapyRoomInterface[],
  streamingTestVideos: [] as StreamingTestVideo[],
  streamingTestVideoPublic: [] as StreamingTestVideo[],
  trialvideo: { video: "" } as TrialVideo,
  countTrialVideos: 0,
  services: [] as Service[],
  eventNearDateServices: [] as Service[],
  popularWorldServices: [] as Service[],
  recommendWorldServices: [] as Service[],
  recentPostEventServices: [] as Service[],
  recentPostWorldServices: [] as Service[],
  isFetchingServices: false,
  hasMore: false,
  tripTutorial: null as TripTutorialInterface | null,
  partyLogs: {
    data: [],
    lastUpdated: null,
    total: 0,
  } as {
    data: IPartyLog[];
    lastUpdated: string | null;
    total: number;
  },
  isFetchingPurchaseHistory: false as boolean,
  purchaseHistory: [] as PurchaseHistoryItemByUser[],
  purchaseHistoryTotal: 0 as number,
  isFetchingHistories: false as boolean,
  histories: [] as HistoryDetail[],
  hasMoreHistories: false as boolean,
  isFetchingBookings: false as boolean,
  bookings: [] as BookingInterface[],
  isFetchingBookingsViewAll: false as boolean,
  bookingsViewAll: [] as BookingInterface[],
  hasMoreBookingsViewAll: false as boolean,
  isFetchingStatistics: false as boolean,
  statistics: [] as StatisticItemInterface[],
};

export type RoomState = typeof roomInitialState;

export function roomReducer(
  state: RoomState = roomInitialState,
  action: RoomsActionTypes
): RoomState {
  switch (action.type) {
    case RoomActions.GET_ROOMS:
      return {
        ...state,
        rooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.GET_WR_BEST_SCENE:
      return {
        ...state,
        wrBestScene: action.payload,
        wrBestSceneIsFetched: true,
      };
    case RoomActions.GET_WR_BEST_SCENE_KIDS:
      return {
        ...state,
        wrBestSceneKids: action.payload,
        wrBestSceneKidsIsFetched: true,
      };
    case RoomActions.GET_SELECTED_VIDEO:
      return {
        ...state,
        selectedVideo: action.payload,
      };
    case RoomActions.GET_FAVOURITE_VIDEOS:
      return {
        ...state,
        favouriteVideos: action.payload.videos,
        countFavouriteVideos: action.payload.total,
      };
    case RoomActions.GET_ROOM_ID_VIDEO:
      return {
        ...state,
        roomIdVideos: action.payload.videos,
        countRoomIdVideos: action.payload.total,
      };
    case RoomActions.GET_REGISTER_TOUR_VIDEO:
      return {
        ...state,
        registerTourVideo: action.payload.video,
      };
    case RoomActions.SET_ERRORS:
      return {
        ...state,
        errors: action.payload as any[],
      };
    case RoomActions.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case RoomActions.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case RoomActions.CREATE_ROOM:
      return {
        ...state,
        rooms: [
          ...state.rooms,
          {
            ...action.payload,
            key: action.payload.id,
          },
        ],
      };
    case RoomActions.UPDATE_ROOM:
      return {
        ...state,
        rooms: state.rooms.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...action.payload,
              key: action.payload.id,
            };
          }

          return el;
        }),
      };
    case RoomActions.DELETE_ROOM:
      return {
        ...state,
        rooms: state.rooms.filter((e) => e.id !== action.payload.id),
      };
    case RoomActions.GET_VUMS_VIDEOS:
      return {
        ...state,
        vumsVideos: action.payload.videos,
        countVUMSVideos: action.payload.total,
      };
    case RoomActions.GET_SELF_EXPLORE_VIDEOS:
      return {
        ...state,
        selfExploreVideos: action.payload.videos,
        countSelfExploreVideos: action.payload.total,
      };
    case RoomActions.GET_PERSONAL_LIBRARY_VIDEOS:
      return {
        ...state,
        personalLibraryVideos: action.payload.videos,
        countPersonalLibraryVideos: action.payload.total,
      };
    case RoomActions.GET_STREAMING_TEST_VIDEO_PUBLIC:
      return {
        ...state,
        streamingTestVideoPublic: action.payload.video,
      };
    case RoomActions.GET_STREAMING_TEST_VIDEO:
      return {
        ...state,
        streamingTestVideos: action.payload.videos,
      };
    case RoomActions.SET_STREAMING_TEST_VIDEO:
      return {
        ...state,
        streamingTestVideos: action.payload.videos,
      };
    case RoomActions.GET_PARTY_ROOMS:
      return {
        ...state,
        partyRooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.GET_THERAPY_ROOMS:
      return {
        ...state,
        therapyRooms: (action.payload || []).map((r) => ({
          ...r,
          key: r.id,
        })),
      };
    case RoomActions.SET_TRIAL_VIDEO:
      return { ...state, trialvideo: action.payload || {} };

    case RoomActions.SET_PARTY_LOG:
      return {
        ...state,
        partyLogs: action.payload,
      };

    case RoomActions.GET_SERVICES: {
      return { ...state, isFetchingServices: true };
    }

    case RoomActions.GET_SERVICES_FAIL: {
      return { ...state, isFetchingServices: false };
    }

    case RoomActions.GET_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const services =
        page > 1
          ? [...state.services, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== services.length &&
        action.payload.total > services.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        services,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.GET_EVENT_NEAR_DATE_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const eventNearDateServices =
        page > 1
          ? [...state.eventNearDateServices, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== eventNearDateServices.length &&
        action.payload.total > eventNearDateServices.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        eventNearDateServices,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.GET_POPULAR_WORLD_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const popularWorldServices =
        page > 1
          ? [...state.popularWorldServices, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== popularWorldServices.length &&
        action.payload.total > popularWorldServices.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        popularWorldServices,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.GET_RECOMMEND_WORLD_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const recommendWorldServices =
        page > 1
          ? [...state.recommendWorldServices, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== recommendWorldServices.length &&
        action.payload.total > recommendWorldServices.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        recommendWorldServices,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.GET_RECENT_POST_EVENT_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const recentPostEventServices =
        page > 1
          ? [...state.recentPostEventServices, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== recentPostEventServices.length &&
        action.payload.total > recentPostEventServices.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        recentPostEventServices,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.GET_RECENT_POST_WORLD_SERVICES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const recentPostWorldServices =
        page > 1
          ? [...state.recentPostWorldServices, ...action.payload.data]
          : action.payload.data;
      const hasMore =
        action.payload.total !== recentPostWorldServices.length &&
        action.payload.total > recentPostWorldServices.length;
      const tripTutorial = action.payload.tripTutorial;
      return {
        ...state,
        isFetchingServices: false,
        recentPostWorldServices,
        hasMore,
        tripTutorial,
      };
    }

    case RoomActions.DELETE_SERVICE:
      const { id, category } = action.payload;

      return {
        ...state,
        services: state.services.filter(
          (el) => el.id !== id || el.category !== category
        ),
      };

    case RoomActions.GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_REQUEST:
      return {
        ...state,
        isFetchingPurchaseHistory: true,
      };

    case RoomActions.GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetchingPurchaseHistory: false,
        purchaseHistory: action.payload.data,
        purchaseHistoryTotal: action.payload.total,
      };

    case RoomActions.GET_PURCHASE_HISTORY_BY_USER_AND_CATEGORY_FAILURE:
      return {
        ...state,
        isFetchingPurchaseHistory: false,
        errors: action.payload,
      };

    case RoomActions.GET_HISTORIES_REQUEST:
      return {
        ...state,
        isFetchingHistories: true,
      };

    case RoomActions.GET_HISTORIES_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const histories =
        page > 1
          ? [...state.histories, ...action.payload.data]
          : action.payload.data;
      const hasMoreHistories =
        action.payload.total !== histories.length &&
        action.payload.total > histories.length;

      return {
        ...state,
        isFetchingHistories: false,
        histories,
        hasMoreHistories,
      };
    }

    case RoomActions.GET_HISTORIES_FAILURE:
      return {
        ...state,
        isFetchingHistories: false,
        errors: action.payload,
      };

    case RoomActions.GET_BOOKINGS_REQUEST:
      return {
        ...state,
        isFetchingBookings: true,
      };

    case RoomActions.GET_BOOKINGS_SUCCESS:
      return {
        ...state,
        isFetchingBookings: false,
        bookings: action.payload.data,
      };

    case RoomActions.GET_BOOKINGS_FAILURE:
      return {
        ...state,
        isFetchingBookings: false,
        errors: action.payload,
      };

    case RoomActions.GET_BOOKINGS_VIEW_ALL_REQUEST:
      return {
        ...state,
        isFetchingBookingsViewAll: true,
      };

    case RoomActions.GET_BOOKINGS_VIEW_ALL_SUCCESS: {
      const page = action.payload.page ? action.payload.page : 1;
      const bookingsViewAll =
        page > 1
          ? [...state.bookingsViewAll, ...action.payload.data]
          : action.payload.data;
      const hasMoreBookingsViewAll =
        action.payload.total !== bookingsViewAll.length &&
        action.payload.total > bookingsViewAll.length;

      return {
        ...state,
        isFetchingBookingsViewAll: false,
        bookingsViewAll,
        hasMoreBookingsViewAll,
      };
    }

    case RoomActions.GET_BOOKINGS_VIEW_ALL_FAILURE:
      return {
        ...state,
        isFetchingBookingsViewAll: false,
        errors: action.payload,
      };

    case RoomActions.GET_STATISTICS_REQUEST:
      return {
        ...state,
        isFetchingStatistics: true,
      };

    case RoomActions.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingStatistics: false,
        statistics: action.payload.data,
      };

    case RoomActions.GET_STATISTICS_FAILURE:
      return {
        ...state,
        isFetchingStatistics: false,
        errors: action.payload,
      };

    default:
      return state;
  }
}
