import { SelfhostActionTypes } from "./actions";
import {
  ReviewItemInterface,
  SelfhostActions,
  SelfhostTourHistoryInterface,
  SelfhostTourInterface,
  SelfhostTourReviewInterface,
} from "./types";

export const selfhostInitialState = {
  isSubmitting: false,
  tours: [] as SelfhostTourInterface[],
  totalCount: 0,
  countries: [],
  allCount: 0,
  tourDetail: {} as SelfhostTourInterface,
  histories: [] as SelfhostTourHistoryInterface[],
  historiesTotal: 0,
  events: [] as SelfhostTourInterface[],
  reviews: [] as SelfhostTourReviewInterface[],
  isReviewsLoading: false as boolean,
  isFetchingEvents: false,
  isLoading: false,
  isHistoriesLoading: false,
};

export type SelfhostState = typeof selfhostInitialState;

export function selfhostReducer(
  state: SelfhostState = selfhostInitialState,
  action: SelfhostActionTypes
): SelfhostState {
  const { tours, reviews } = state;
  let index = -1;

  switch (action.type) {
    case SelfhostActions.CREATE_TOUR_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case SelfhostActions.CREATE_TOUR_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SelfhostActions.GET_TOUR_LIST_REQUEST:
      return {
        ...state,
        tours: [],
      };
    case SelfhostActions.GET_TOUR_LIST_SUCCESS:
      return {
        ...state,
        tours: action.payload,
      };
    case SelfhostActions.GET_TOUR_LIST_SEARCH_SUCCESS:
      return {
        ...state,
        tours: action.payload.tours,
        totalCount: action.payload.totalCount,
        countries: action.payload.countries,
        allCount: action.payload.allCount,
      };
    case SelfhostActions.UPDATE_SINGLE_TOUR_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case SelfhostActions.UPDATE_SINGLE_TOUR_SUCCESS:
      const updatedTours = tours.map((t) => {
        if (t.id === action.payload.id) {
          return {
            ...action.payload,
            RoomPrivacy: {
              ...t.RoomPrivacy,
              id: t.RoomPrivacy?.id ?? 0,
              permission: action.payload.public ? "public" : "private",
            },
          };
        }

        return t;
      });

      return {
        ...state,
        isSubmitting: false,
        tours: updatedTours,
      };
    case SelfhostActions.DELETE_SINGLE_TOUR_SUCCESS:
      return {
        ...state,
        tours: tours.filter((t) => t.id !== action.payload),
      };
    case SelfhostActions.GET_TOUR_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        tourDetail: {} as SelfhostTourInterface,
      };
    case SelfhostActions.GET_TOUR_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tourDetail: action.payload,
      };
    case SelfhostActions.UPDATE_TOUR_DETAIL_REQUEST:
      return {
        ...state,
        isSubmitting: true,
      };
    case SelfhostActions.UPDATE_TOUR_DETAIL_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
      };
    case SelfhostActions.GET_TOUR_HISTORIES_REQUEST:
      return {
        ...state,
        isHistoriesLoading: true,
      };
    case SelfhostActions.GET_TOUR_HISTORIES_SUCCESS:
      return {
        ...state,
        isHistoriesLoading: false,
        histories: action.payload.data,
        historiesTotal: action.payload.total,
      };
    case SelfhostActions.SEND_TOUR_REVIEW_REQUEST:
      return {
        ...state,
        isReviewsLoading: true,
      };
    case SelfhostActions.SEND_TOUR_REVIEW_SUCCESS:
      return {
        ...state,
        isReviewsLoading: false,
        reviews: reviews.map((el) => {
          if (action.payload && el.id === action.payload.id) {
            return {
              ...el,
              comment: action.payload.comment,
              evaluation: action.payload.evaluation,
              ReviewPhotos: action.payload.ReviewPhotos
                ? action.payload.ReviewPhotos
                : el.ReviewPhotos,
            };
          }
          return el;
        }),
      };
    case SelfhostActions.DELETE_SELFHOST_REVIEW_REQUEST:
      return {
        ...state,
        isReviewsLoading: true,
      };
    case SelfhostActions.DELETE_SELFHOST_REVIEW_SUCCESS:
      index = reviews.findIndex(
        (e: ReviewItemInterface) => e.id === action.payload
      );
      return {
        ...state,
        isReviewsLoading: false,
        reviews: [...reviews.slice(0, index), ...reviews.slice(index + 1)],
      };
    case SelfhostActions.DELETE_SELFHOST_REVIEW_ERROR:
      return {
        ...state,
        isReviewsLoading: false,
      };
    case SelfhostActions.UPDATE_TOUR_STATUS_REQUEST_SUCCESS: {
      const tourDetail = state.tourDetail;
      const { body } = action.payload;
      const { status } = body;

      if (status === "event") {
        tourDetail.isPrivate = false;
      }

      if (status === "world") {
        tourDetail.isPrivate = true;
      }

      return {
        ...state,
        tourDetail,
      };
    }
    case SelfhostActions.GET_WR_EVENTS_FOR_USER: {
      return {
        ...state,
        isFetchingEvents: true,
      };
    }
    case SelfhostActions.GET_WR_EVENTS_FOR_USER_FAIL: {
      return {
        ...state,
        isFetchingEvents: false,
      };
    }
    case SelfhostActions.GET_WR_EVENTS_FOR_USER_SUCCESS: {
      return {
        ...state,
        isFetchingEvents: false,
        events: action.payload.list,
      };
    }
    case SelfhostActions.GET_ROOMS_REVIEWS_FOR_USER_REQUEST: {
      return {
        ...state,
        isReviewsLoading: true,
      };
    }
    case SelfhostActions.GET_ROOMS_REVIEWS_FOR_USER_SUCCESS: {
      return {
        ...state,
        isReviewsLoading: false,
        reviews: action.payload,
      };
    }
    default:
      return state;
  }
}
