export enum LocationActions {
  GET_ALL_LOCATIONS_REQUEST = "GET_ALL_LOCATIONS_REQUEST",
  GET_ALL_LOCATIONS_SUCCESS = "GET_ALL_LOCATIONS_SUCCESS",
  GET_ALL_LOCATIONS_FAILURE = "GET_ALL_LOCATIONS_FAILURE",
  GET_PRE_SELECTED_LOCATIONS_REQUEST = "GET_PRE_SELECTED_LOCATIONS_REQUEST",
  GET_PRE_SELECTED_LOCATIONS_SUCCESS = "GET_PRE_SELECTED_LOCATIONS_SUCCESS",
  GET_PRE_SELECTED_LOCATIONS_FAILURE = "GET_PRE_SELECTED_LOCATIONS_FAILURE",
  LOCATION_ERROR = "LOCATION_ERROR",
}

export interface PreSelectedLocationsOptions {
  spotCodes?: (number | string)[];
  countryCodes?: (number | string)[];
  locations?: "all";
  type?: string;
}
