import { SideMenuInterface } from "../components/MainLayout/types";
import jwt_decode, { JwtPayload } from "jwt-decode";
import dayjs from "dayjs";
import {
  CategoryType,
  RoomCategoryType,
  WrBestSceneInterface,
} from "../store/room/types";
import { User } from "../store/account/types";
import { IBalance } from "../store/payment/types";
import { FRIENDLY_ROOM_CATEGORIES } from "./constant";

const TrippingLink = process.env.REACT_APP_TRIPPING_LINK;

export const redirectToRefer = (referer: string, token: string) => {
  let updated = referer.replace(new RegExp("[?&#]+$"), "");
  updated += (referer.split("?")[1] ? "&" : "?") + "token=" + token;
  window.location.href = updated;
};

export const getMenuPath = (menus: SideMenuInterface[], value: string) => {
  let paths: string[] = [];
  let found = false;

  const searchKeyFromMenu = (ms: SideMenuInterface[]) => {
    for (let i = 0; i < ms.length; i++) {
      const menu = ms[i];

      paths.push(menu.key);

      if (menu.key === value) {
        found = true;
        break;
      }

      if (menu.subMenus) {
        searchKeyFromMenu(menu.subMenus);

        if (found) {
          break;
        }
      }

      paths.pop();
    }
  };

  searchKeyFromMenu(menus);
  return paths;
};

export const convertS3toCloud = (url: string) => {
  const prodS3 = process.env.REACT_APP_STAGING_VIDEO_S3_LINK;
  const stagingS3 = process.env.REACT_APP_PROD_VIDEO_S3_LINK;
  const cloudDomain = process.env.REACT_APP_TRIPPING_VIDEO_CLOUD_LINK;

  if (!cloudDomain) return url;

  if (prodS3 && url.includes(prodS3)) {
    return url.replace(prodS3, cloudDomain);
  } else if (stagingS3 && url.includes(stagingS3)) {
    return url.replace(stagingS3, cloudDomain);
  }

  return url;
};

export const isObjectEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const isObjectOfArrayItemsEmpty = (obj: { [key: string]: any[] }) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value.length > 0) {
        return false;
      }
    }
  }
  return true;
};

export const compareObjectsOfArrayItemsLength = (
  obj1: { [key: string]: any[] },
  obj2: { [key: string]: any[] }
) => {
  for (const key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      const value = obj1[key];
      if (!obj2[key] || value.length !== obj2[key].length) {
        return false;
      }
    }
  }
  return true;
};

export const convertObjectToArrayOfObjects = (obj: {
  [key: string]: string;
}) => {
  return Object.entries(obj).map(([key, value]) => ({ key, value }));
};

export const attachQueryToLink = (
  link: string,
  queryKey: string,
  queryValue: string
) => {
  return link.includes("?")
    ? `${link}&${queryKey}=${queryValue}`
    : `${link}?${queryKey}=${queryValue}`;
};

export const queryParamToList = (queryParam: string) => {
  if (queryParam) {
    return queryParam.split(",");
  }
  return [];
};

export const isExpiredToken = (token: string) => {
  const decodedToken = jwt_decode<JwtPayload>(token);
  if (dayjs().unix() >= (decodedToken?.exp as JwtPayload)) return true;
  return false;
};

export const range = (count: number) =>
  Array.from({ length: count }, (v, i) => i + 1);

export const getAvatarString = (name: string) => {
  let names = name.split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const parseVideoId = (videoId: number | string) => {
  // parseInt(videoId.toString(), 10); will also work,
  // but used `split` to be more explicit
  return parseInt(videoId.toString().split("-")[0], 10);
};

export const replaceLineBreakWithHtmlLineBreak = (str: string) => {
  return str.replace(/\r\n/g, "<br />");
};

export const replaceLineBreakWithNoSpace = (str: string) => {
  return str.replace(/\r\n/g, "");
};

export const separateRoomByUser = (
  defaultRoomId: string,
  isAuth: boolean,
  userId?: number,
  customSeparator?: string
) => {
  let separator = "_"; // default separator
  if (customSeparator) {
    separator = customSeparator;
  }

  if (!isAuth) {
    const unAuthUserId = localStorage.getItem("mixpanel_distinct_id");
    return `${defaultRoomId}${separator}${unAuthUserId}`;
  }

  return `${defaultRoomId}${separator}${userId}`;
};

export const getTrippingLinkByBestScene = (
  bestScene: WrBestSceneInterface,
  type: string,
  isAuth: boolean,
  user: User
) => {
  const roomId = separateRoomByUser(
    bestScene.Video.defaultRoomId,
    isAuth,
    isAuth ? user.id : undefined
  );

  const trippingLink = `${TrippingLink}/tripping_merged.html?roomId=${roomId}&staticVideoLink=${convertS3toCloud(
    bestScene.Video.pcUrl
  )}&videoId=${bestScene.Video.id}&explorePlaceId=${
    bestScene.id
  }&type=${type}&metaverserId=${bestScene.author.id}&travelDxRoomId=${
    bestScene.id
  }&isHost=true${!isAuth ? "&notAuth=true" : ""}&sample=true&isFullSample=true`;

  return trippingLink;
};

export const getTutorialLinkByStaticVideoLink = (
  staticVideoLink: string,
  isAuth: boolean,
  user: User
) => {
  const roomId = separateRoomByUser(
    "trip-tutorial",
    isAuth,
    isAuth ? user.id : undefined,
    "-"
  );

  const trippingLink = `${TrippingLink}/tripping_merged.html?roomId=${roomId}&staticVideoLink=${staticVideoLink}&isTutorial=true${
    !isAuth ? "&notAuth=true" : ""
  }`;

  return trippingLink;
};

export const getContentByPrimaryLanguage = (
  primaryLanguage: string,
  content: string,
  contentJp?: string | null,
  contentZhcn?: string | null,
  contentZhtw?: string | null,
  contentVi?: string | null,
  contentHi?: string | null
) => {
  if (primaryLanguage === "ja") {
    return contentJp || content;
  } else if (primaryLanguage === "zh-CN") {
    return contentZhcn || content;
  } else if (primaryLanguage === "zh-TW") {
    return contentZhtw || content;
  } else if (primaryLanguage === "vi") {
    return contentVi || content;
  } else if (primaryLanguage === "hi") {
    return contentHi || content;
  }
  return content;
};

export const getCategoryTabTypeByCategory = (
  category: RoomCategoryType | CategoryType
) => {
  switch (category) {
    case FRIENDLY_ROOM_CATEGORIES.TOUR:
    case "tour":
      return "GM";

    case FRIENDLY_ROOM_CATEGORIES.EXPLORE_PLACE:
    case "explorePlace":
      return "WR";

    case FRIENDLY_ROOM_CATEGORIES.EXPLORE:
    case "explore":
      return "Explore";

    case FRIENDLY_ROOM_CATEGORIES.THERAPY:
    case "therapy":
      return "Therapy";

    case FRIENDLY_ROOM_CATEGORIES.KARAOKE:
    case "karaoke":
      return "Party";

    case FRIENDLY_ROOM_CATEGORIES.MUSIC:
    case "music":
      return "Music";

    case FRIENDLY_ROOM_CATEGORIES.LANGUAGE_LEARNING:
    case "languageLearning":
      return "LanguageLearning";

    case FRIENDLY_ROOM_CATEGORIES.NEWS:
    case "news":
      return "News";

    case FRIENDLY_ROOM_CATEGORIES.TIME_CAPSULE:
    case "timeCapsule":
      return "TimeCapsule";

    case FRIENDLY_ROOM_CATEGORIES.SEMINAR:
    case "seminar":
      return "Seminar";

    case FRIENDLY_ROOM_CATEGORIES.TALK:
    case "talk":
      return "Talk";

    case FRIENDLY_ROOM_CATEGORIES.LEARNING_EDUCATION:
    case "learningEducation":
      return "LearningEducation";

    case FRIENDLY_ROOM_CATEGORIES.ANIMALS_PET:
    case "animalsPet":
      return "AnimalsPet";

    case FRIENDLY_ROOM_CATEGORIES.FESTIVAL:
    case "festival":
      return "Festival";

    case FRIENDLY_ROOM_CATEGORIES.GOURMET:
    case "gourmet":
      return "Gourmet";
    case FRIENDLY_ROOM_CATEGORIES.SPORTS:
    case "sports":
      return "Sports";
    default:
      return "GM";
  }
};

export const getTotalBalanceByCurrency = (
  balance: IBalance[],
  currency: string
) => {
  const totalAmount =
    balance
      ?.map((item) => {
        if (currency === "jpy") {
          return item.amountCurrencyHint.jpy;
        }

        return parseFloat(item.amountCurrencyHint.usd);
      })
      .reduce((a, b) => a + b, 0) || 0;

  return currency === "jpy" ? totalAmount : totalAmount.toFixed(2);
};

export const balanceHasNonUsdCurrency = (balance: IBalance[]) => {
  return balance.some((item) => item.currency !== "usd");
};

export const getCategoryColorByCategory = (category: RoomCategoryType) => {
  switch (category) {
    case "tour":
      return "#f37021";
    case "explorePlace":
      return "#00a651";
    case "explore":
      return "#AB47BC";
    case "therapy":
      return "#E754FF";
    case "karaoke":
      return "#3FCFC7";
    case "music":
      return "#0070D2";
    case "languageLearning":
      return "#FFA726";
    case "lab":
      return "#7E57C2";
    case "news":
      return "#5C6BC0";
    case "timeCapsule":
      return "#42A5F5";
    case "seminar":
      return "#26A69A";
    case "talk":
      return "#43A047";
    case "learningEducation":
      return "#AFB42B";
    case "animalsPet":
      return "#795548";
    case "festival":
      return "#757575";
    case "gourmet":
      return "#78909C";
    case "sports":
      return "#ef5350";
    case "gaussianVr":
      return "#876D19";
    default:
      return "#f37021";
  }
};

export const convertHHMMtoDuration = (hhmm: string) => {
  const a = hhmm.split(":"); // split it at the colons
  const result = +a[0] * 60 * 60 + +a[1] * 60;
  return result;
};

export const getEventUrlByType = (type: string, streamKey: string) => {
  switch (type) {
    case "publisher": {
      return `${TrippingLink}/tripping_merged.html?roomId=${streamKey}&audio=true&protocol=hls&hlsType=3&playerType=jwplayer&targetBuffer=10&app=dd_live&openTokAudioOnly=false&isDirectLink=true&region=tokyo&syncSpeed=1000&cdn=cloudfront&vrmusic=true&publisher=true`;
    }

    case "vip": {
      return `${TrippingLink}/tripping_merged.html?roomId=${streamKey}&audio=true&protocol=hls&hlsType=3&playerType=jwplayer&targetBuffer=10&app=dd_live&openTokAudioOnly=false&isDirectLink=true&region=tokyo&syncSpeed=1000&cdn=cloudfront&vrmusic=true`;
    }

    case "general": {
      return `${TrippingLink}/tripping_merged.html?roomId=${streamKey}&audio=true&protocol=hls&hlsType=3&playerType=jwplayer&targetBuffer=10&app=dd_live&openTokAudioOnly=false&isDirectLink=true&region=tokyo&syncSpeed=1000&cdn=cloudfront&vrmusic=true&isWebinar=true`;
    }

    default:
      return "";
  }
};
