import { Action } from "redux";
import { notification } from "antd";
import { AppThunk } from "../types";
import { ColumnsDataType } from "./types";
import { setLoading } from "../app/actions";
import { createRequest } from "../../utils/network";
import { API_CMS_PAGE } from "../../routes/constants";
export enum PageActions {
  COLUMNS_IS_LOADING = "page/COLUMNS_IS_LOADING",
}

export interface GetPageAction extends Action {
  type: PageActions.COLUMNS_IS_LOADING;
  payload: ColumnsDataType;
}
export type ColumnsActionTypes = GetPageAction;
export function fetchPageListByLimit(
  pageTypeId: number,
  page: number,
  limit: number
): AppThunk {
  return async (dispatch: Function) => {
    dispatch(setLoading(true));
    const { response, error } = await createRequest(
      `${API_CMS_PAGE}/page-type-id/${pageTypeId}?limit=${limit}&page=${page}`,
      {
        method: "GET",
      }
    );
    if (error && typeof error === "string") {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
    } else {
      dispatch(dispatchPageData(response));
    }
    dispatch(setLoading(false));
  };
}

function dispatchPageData(response: ColumnsDataType) {
  return async (dispatch: Function) => {
    dispatch({
      type: PageActions.COLUMNS_IS_LOADING,
      payload: {
        data: response.data,
        success: response.success,
        errors: response.errors,
      },
    });
  };
}
