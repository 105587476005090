import { Action } from "redux";

import {
  API_USER_INFO,
  USER_LOGIN_ROUTE_PATH,
  API_USER_LOGIN,
  API_USER_FORGOT_PASSWORD,
  API_USER_RESET_PASSWORD,
  API_USER_REGISTER,
  API_USER_PERMISSION,
  API_USER_PRO_ADMIN_PERMISSION,
  API_USER_POINT,
  API_CARD,
  API_PLAN,
  API_EMAIL_CHANGE,
  API_CONFIRM_EMAIL_CHANGE,
  API_EMAIL_VERIFY,
  TRAVEL_HOSTMANAGEMENT_ROUTE_PATH,
  API_SUBSCRIBE_PLAN_WITH_FREE_POINT,
  API_USER_PAYMENT_METHOD,
  API_POINT_HISTORY,
  API_USER_CONTINUE_WITH_FACEBOOK,
  API_RESEND_OTP,
  API_CONFIRM_OTP,
  API_RESET_PASSWORD_OTP,
  API_RESET_PASSWORD_OTP_CONFIRMATION,
  API_USER_FORGOT_PASSWORD_BY_TOKEN,
  API_USER_CREATE_PRE_SIGNUP_PHONE_USER,
  API_USER_TUTORIAL_STATUS,
  API_USER_UPDATE_CAN_CHECK_PAYMENT_HISTORY,
  HOME_ROUTE_PATH,
  API_CANCEL_PLAN_WITH_FREE_POINT,
  WITHDRAW_COMPLETE,
  HOME_EN_ROUTE_PATH,
  API_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN,
  API_USER_INFO_BY_TWITTER_CALLBACK_TOKEN,
  API_USER_INFO_BY_LINE_CALLBACK_TOKEN,
  API_USER_DISABLE_MANAGEMENT_NOTIFICATIONS_POPUP,
  API_SEND_CONTACT,
} from "../../routes/constants";
import { history } from "../../routes/history";
import { createRequest } from "../../utils/network";
import { resetStore } from "../actions";
import { setLoading } from "../app/actions";
import { AppThunk } from "../types";
import {
  AccountTypes,
  CurrentAuthOption,
  IPaymentMethod,
  PointHistories,
  ResendOtpTypes,
  SendContactTemplate,
  SubscribePlanWithFreePoint,
  User,
  UserPoint,
} from "./types";
import { getStripeVerificationLink } from "../../api/payment";

import { getAuthToken } from "../../utils";
import { attachQueryToLink, redirectToRefer } from "../../utils/helper";
import { notification } from "antd";
import { API_USER_RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE } from "../../routes/constants";
import {
  API_USER_RESET_PHONE_SAVE_NEW_PHONE,
  API_USER_RESET_PHONE_VERIFY_CURRENT_PHONE,
} from "../../routes/constants";
import { getAppLanguageByBrowserLanguage, Language } from "../../i18n";
import { getLanguage } from "../app/selectors";
import {
  mixpanelCancelPlan,
  mixpanelLogin,
  mixpanelRegister,
  mixpanelSubscribePlan,
} from "../../utils/mixpanel";

interface SetUserAction extends Action {
  readonly type: AccountTypes.SET_USER;
  readonly payload: { user: User };
}

interface SetUserPermissionAction extends Action {
  readonly type: AccountTypes.SET_USER_PERMISSION;
  readonly payload: { isAdmin: boolean };
}

interface SetUserProAdminPermissionAction extends Action {
  readonly type: AccountTypes.SET_USER_PRO_ADMIN_PERMISSION;
  readonly payload: { user: User };
}

interface SetPlanAction extends Action {
  readonly type: AccountTypes.SET_PLAN;
  readonly payload: string;
}

interface DowngradePlanAction extends Action {
  readonly type: AccountTypes.DOWNGRADE_PLAN;
  readonly payload: {
    expireDate: string;
    service: string;
  };
}

interface CancelPlanWithFreePointACtion extends Action {
  readonly type: AccountTypes.CANCEL_PLAN_WITH_FREE_POINT;
  readonly payload: {
    service: string;
    isPointCancelled: boolean;
  };
}

interface SetSelfHostPlanAction extends Action {
  readonly type: AccountTypes.SET_USER_SELFHOST_PLAN;
  readonly payload: string;
}

interface SetIsSubmitting extends Action {
  readonly type: AccountTypes.SET_IS_SUBMITTING;
  readonly payload: boolean;
}

interface SetRedirectPathAction extends Action {
  readonly type: AccountTypes.SET_REDIRECT_PATH;
  readonly payload: { redirectPath: string | null };
}

interface SetAuthenticatedAction extends Action {
  readonly type: AccountTypes.SET_AUTHENTICATE;
  readonly payload: { authenticated: boolean };
}

interface LogoutAction extends Action {
  readonly type: AccountTypes.LOGOUT;
}

interface UpdateBusinessModeAction extends Action {
  readonly type: AccountTypes.UPDATE_BUSINESS_MODE;
  readonly payload: { isBusinessMode: boolean; businessModeName: string };
}

interface GetUserPointAction extends Action {
  readonly type: AccountTypes.GET_USERPOINT;
  readonly payload: { user: UserPoint };
}

interface GetUserPointHistoriesAction extends Action {
  readonly type: AccountTypes.GET_USERPOINT_HISTORY;
  readonly payload: { pointHistories: PointHistories };
}

interface SetPaymentMethod extends Action {
  readonly type: AccountTypes.SET_PAYMENT_METHOD;
  readonly payload: IPaymentMethod | null;
}

export interface SetAccountErrorsAction extends Action {
  type: AccountTypes.SET_ERRORS;
  payload: any[];
}
export interface ChangePointBySelfAction extends Action {
  type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT;
  payload: any[];
}

export interface ChangePointBySelfAction extends Action {
  type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT;
  payload: any[];
}

export interface UserAuthRequestAction extends Action {
  type: AccountTypes.USER_AUTH_REQUEST;
}

export interface UserAuthSuccessAction extends Action {
  type: AccountTypes.USER_AUTH_SUCCESS;
}

export interface ResendOtpRequestAction extends Action {
  type: AccountTypes.RESEND_OTP_REQUEST;
}

export interface ResendOtpSuccessAction extends Action {
  type: AccountTypes.RESEND_OTP_SUCCESS;
}

export interface ResendOtpFailureAction extends Action {
  type: AccountTypes.RESEND_OTP_FAILURE;
  payload: any[];
}

export interface ConfirmOtpRequestAction extends Action {
  type: AccountTypes.CONFIRM_OTP_REQUEST;
}

export interface ConfirmOtpSuccessAction extends Action {
  type: AccountTypes.CONFIRM_OTP_SUCCESS;
}

export interface ConfirmOtpFailureAction extends Action {
  type: AccountTypes.CONFIRM_OTP_FAILURE;
  payload: any[];
}

export interface ResetPasswordOtpConfirmationRequestAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_REQUEST;
}

export interface ResetPasswordOtpConfirmationSuccessAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_SUCCESS;
}

export interface ResetPasswordOtpConfirmationFailureAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_FAILURE;
  payload: any[];
}

export interface ResetPasswordOtpRequestAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_REQUEST;
}

export interface ResetPasswordOtpSuccessAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_SUCCESS;
}

export interface ResetPasswordOtpFailureAction extends Action {
  type: AccountTypes.RESET_PASSWORD_OTP_FAILURE;
  payload: any[];
}

export interface CreatePreSignupPhoneUserRequestAction extends Action {
  type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_REQUEST;
}

export interface CreatePreSignupPhoneUserSuccessAction extends Action {
  type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_SUCCESS;
}

export interface CreatePreSignupPhoneUserFailureAction extends Action {
  type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_FAILURE;
  payload: any[];
}

export interface ResetPhoneSaveNewPhoneRequestAction extends Action {
  type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_REQUEST;
}

export interface ResetPhoneSaveNewPhoneSuccessAction extends Action {
  type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_SUCCESS;
}

export interface ResetPhoneSaveNewPhoneFailureAction extends Action {
  type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_FAILURE;
  payload: any[];
}

export interface ResetPhoneVerifyCurrentPhoneRequestAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_REQUEST;
}

export interface ResetPhoneVerifyCurrentPhoneSuccessAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_SUCCESS;
}

export interface ResetPhoneVerifyCurrentPhoneFailureAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE;
  payload: any[];
}

export interface ResetPhoneVerifyAndResetNewPhoneRequestAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_REQUEST;
}

export interface ResetPhoneVerifyAndResetNewPhoneSuccessAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_SUCCESS;
}

export interface ResetPhoneVerifyAndResetNewPhoneFailureAction extends Action {
  type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE;
  payload: any[];
}

export interface UpdateCanCheckPaymentHistoryRequestAction extends Action {
  type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_REQUEST;
}

export interface UpdateCanCheckPaymentHistorySuccessAction extends Action {
  type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_SUCCESS;
}

export interface UpdateCanCheckPaymentHistoryFailureAction extends Action {
  type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE;
  payload: any[];
}

export interface GetUserInfoByGoogleCallbackTokenRequestAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_REQUEST;
}

export interface GetUserInfoByGoogleCallbackTokenSuccessAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_SUCCESS;
}

export interface GetUserInfoByGoogleCallbackTokenFailureAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE;
  payload: any[];
}

export interface GetUserInfoByTwitterCallbackTokenRequestAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_REQUEST;
}

export interface GetUserInfoByTwitterCallbackTokenSuccessAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_SUCCESS;
}

export interface GetUserInfoByTwitterCallbackTokenFailureAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE;
  payload: any[];
}

export interface GetUserInfoByLineCallbackTokenRequestAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_REQUEST;
}

export interface GetUserInfoByLineCallbackTokenSuccessAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_SUCCESS;
}

export interface GetUserInfoByLineCallbackTokenFailureAction extends Action {
  type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE;
  payload: any[];
}

export interface CloseManagementNotificationsPopupAction extends Action {
  type: AccountTypes.CLOSE_MANAGEMENT_NOTIFICATIONS_POPUP;
}

export interface EnableMayContainStaleSubscriptionDataAction extends Action {
  type: AccountTypes.ENABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA;
}

export interface DisableMayContainStaleSubscriptionDataAction extends Action {
  type: AccountTypes.DISABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA;
}

export function fetchUser(onSuccess?: () => any): AppThunk {
  return async (dispatch: Function) => {
    dispatch(setLoading(true));

    async function fetchUserWithAttempts() {
      const { response } = await createRequest(API_USER_INFO);

      if (response.success) {
        dispatch(setAuthenticated(true));
        dispatch(setUser(response.data.user));
        dispatch(fetchPaymentMethod());
        if (!response.data.user.language) {
          let language =
            localStorage.getItem("language") ||
            getAppLanguageByBrowserLanguage() ||
            "ja";

          dispatch(updateUser({ language }, () => {}));
        }

        onSuccess && onSuccess();
      } else if (response.errors) {
        if (response.errors[0]?.message === "jwt expired") {
          dispatch(logout());
          return;
        }
      }
    }

    await fetchUserWithAttempts();

    dispatch(setLoading(false));
  };
}

export function setUser(user: User): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER,
      payload: { user },
    });
  };
}

export function setUserPermission(isAdmin: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER_PERMISSION,
      payload: { isAdmin },
    });
  };
}

export function setUserProAdminPermission(user: User): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER_PRO_ADMIN_PERMISSION,
      payload: { user },
    });
  };
}

export function setUserSelfHostPlan(selfExplorePlan: boolean): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_USER_SELFHOST_PLAN,
      payload: { selfExplorePlan },
    });
  };
}

export function setRedirectPath(redirectPath: string | null) {
  return {
    type: AccountTypes.SET_REDIRECT_PATH,
    payload: { redirectPath },
  };
}

export function setIsSubmitting(isSubmitting: boolean) {
  return {
    type: AccountTypes.SET_IS_SUBMITTING,
    payload: { isSubmitting },
  };
}

export function setErrors(errors: string[]) {
  return {
    type: AccountTypes.SET_ERRORS,
    payload: errors,
  };
}

export function setAuthenticated(authenticated: boolean) {
  return {
    type: AccountTypes.SET_AUTHENTICATE,
    payload: { authenticated },
  };
}

export function setUserLogout(): AppThunk {
  return (dispatch) => {
    dispatch({
      type: AccountTypes.LOGOUT,
    });

    dispatch(resetStore());
  };
}

export function logout(refCode?: string): AppThunk {
  return async (dispatch) => {
    dispatch(setLoading(true));

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    dispatch(setUserLogout());
    history.push({
      pathname: USER_LOGIN_ROUTE_PATH,
      ...(refCode ? { search: `?ref=${refCode}` } : {}),
    });

    dispatch(setLoading(false));
  };
}

export function logoutWithdraw(): AppThunk {
  return async (dispatch) => {
    dispatch(setLoading(true));

    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    dispatch(setUserLogout());
    history.push({
      pathname: WITHDRAW_COMPLETE,
    });
    dispatch(setLoading(false));
  };
}

export function requestLogin(
  currentLoginOption: CurrentAuthOption,
  email: string,
  phone: string | undefined,
  password: string,
  refCode: string,
  a8Code: string,
  gaClientId: string,
  referer: string,
  routeReferer: string,
  prevLocation: string
): AppThunk {
  return async (dispatch: Function, getState) => {
    const language = getLanguage(getState());

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response } = await createRequest(API_USER_LOGIN, {
      method: "POST",
      body: JSON.stringify({
        currentLoginOption,
        email: currentLoginOption === "email" ? email : "",
        phone: currentLoginOption === "phone" && phone ? phone : "",
        password,
        refCode: refCode || null,
        a8Code: a8Code || null,
        gaClientId: gaClientId || null,
      }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));
      dispatch(fetchPaymentMethod());

      // mixpanel ログイン
      // console.log(response, "response of login");
      mixpanelLogin(response.data.user);

      // Check if user has a business account with business mode
      const isBusinessMode =
        localStorage.getItem(`business_mode_${response.data.user.id}`) ===
        "business";

      if (prevLocation) {
        history.push(prevLocation);
      } else if (referer) {
        redirectToRefer(referer, response.data.token);
      } else if (routeReferer) {
        history.push(
          refCode
            ? attachQueryToLink(routeReferer, "ref", refCode)
            : routeReferer
        );
      } else if (isBusinessMode) {
        history.push({
          pathname: TRAVEL_HOSTMANAGEMENT_ROUTE_PATH,
          ...(refCode ? { search: `?ref=${refCode}` } : {}),
        });
      } else {
        history.push({
          pathname: language === "en" ? HOME_EN_ROUTE_PATH : HOME_ROUTE_PATH,
          ...(refCode ? { search: `?ref=${refCode}` } : {}),
        });
      }

      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestRegister(
  currentRegisterOption: CurrentAuthOption,
  email: string,
  phone: string | undefined,
  name: string,
  password: string,
  refCode: string,
  a8Code: string,
  gaClientId: string,
  referer: string,
  routeReferer: string,
  prevLocation: string,
  nextLocation?: string,
  onBeforeNextLocationAction?: () => void
): AppThunk {
  return async (dispatch: Function, getState) => {
    const language = getLanguage(getState());
    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response } = await createRequest(API_USER_REGISTER, {
      method: "POST",
      body: JSON.stringify({
        currentRegisterOption,
        email: currentRegisterOption === "email" ? email : "",
        phone: currentRegisterOption === "phone" ? phone : "",
        name,
        password,
        refCode: refCode || null,
        a8Code: a8Code || null,
        gaClientId: gaClientId || null,
      }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));

      // mixpanel 新規会員登録
      mixpanelRegister(response.data.user);

      if (nextLocation) {
        onBeforeNextLocationAction && onBeforeNextLocationAction();
        history.push(nextLocation);
      } else if (prevLocation) {
        history.push(prevLocation);
      } else if (referer) {
        redirectToRefer(referer, response.data.token);
      } else if (routeReferer) {
        history.push(
          refCode
            ? attachQueryToLink(routeReferer, "ref", refCode)
            : routeReferer
        );
      } else {
        history.push({
          pathname: language === "en" ? HOME_EN_ROUTE_PATH : HOME_ROUTE_PATH,
          ...(refCode ? { search: `?ref=${refCode}` } : {}),
        });
      }

      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

function continueWithSuccessResponse(
  response: any,
  dispatch: Function,
  language: Language,
  refCode: string = "",
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string,
  nextLocation?: string
) {
  localStorage.setItem("token", response.data.token);
  localStorage.setItem("userId", response.data.user.id);
  dispatch(setUser(response.data.user));
  dispatch(setAuthenticated(true));
  dispatch(fetchPaymentMethod());

  // Check if user has a business account with business mode
  const isBusinessMode =
    localStorage.getItem(`business_mode_${response.data.user.id}`) ===
    "business";

  if (nextLocation) {
    history.push(nextLocation);
  } else if (prevLocation) {
    history.push(prevLocation);
  } else if (referer) {
    redirectToRefer(referer, response.data.token);
  } else if (routeReferer) {
    history.push(
      refCode ? attachQueryToLink(routeReferer, "ref", refCode) : routeReferer
    );
  } else if (isBusinessMode) {
    history.push({
      pathname: TRAVEL_HOSTMANAGEMENT_ROUTE_PATH,
      ...(refCode ? { search: `?ref=${refCode}` } : {}),
    });
  } else {
    history.push({
      pathname: language === "en" ? HOME_EN_ROUTE_PATH : HOME_ROUTE_PATH,
      ...(refCode ? { search: `?ref=${refCode}` } : {}),
    });
  }
}

export function continueWithGoogleIdentity(
  token: string,
  user: User,
  refCode: string = "",
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string,
  nextLocation?: string,
  cleanupSocialAuthLocalStorage?: () => any
): AppThunk {
  return (dispatch: Function, getState) => {
    // we are not calling API here, just managing state in respect to consistent code
    const language = getLanguage(getState());

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const response = {
      data: {
        token,
        user,
      },
    };

    continueWithSuccessResponse(
      response,
      dispatch,
      language,
      refCode,
      referer,
      routeReferer,
      prevLocation,
      nextLocation
    );

    cleanupSocialAuthLocalStorage && cleanupSocialAuthLocalStorage();

    return dispatch({
      type: AccountTypes.USER_AUTH_SUCCESS,
    });
  };
}

export function continueWithFacebook(
  payload: {
    userID: string;
    accessToken: string;
  } | null,
  errors: any[] = [],
  errorMessage: { message: string; description: string },
  refCode: string = "",
  a8Code: string = "",
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string,
  nextLocation?: string
): AppThunk {
  return async (dispatch: Function, getState) => {
    const language = getLanguage(getState());

    if (!payload) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: errors,
      });
    }

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const { response, error } = await createRequest(
      API_USER_CONTINUE_WITH_FACEBOOK,
      {
        method: "POST",
        body: JSON.stringify({
          refCode: refCode || null,
          a8Code: a8Code || null,
          ...payload,
        }),
      }
    );

    if (error) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      continueWithSuccessResponse(
        response,
        dispatch,
        language,
        refCode,
        referer,
        routeReferer,
        prevLocation,
        nextLocation
      );
      return dispatch({
        type: AccountTypes.USER_AUTH_SUCCESS,
      });
    } else {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function continueWithTwitter(
  token: string,
  user: User,
  refCode: string = "",
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string,
  nextLocation?: string,
  cleanupSocialAuthLocalStorage?: () => any
): AppThunk {
  return (dispatch: Function, getState) => {
    // we are not calling API here, just managing state in respect to consistent code
    const language = getLanguage(getState());

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const response = {
      data: {
        token,
        user,
      },
    };

    continueWithSuccessResponse(
      response,
      dispatch,
      language,
      refCode,
      referer,
      routeReferer,
      prevLocation,
      nextLocation
    );

    cleanupSocialAuthLocalStorage && cleanupSocialAuthLocalStorage();

    return dispatch({
      type: AccountTypes.USER_AUTH_SUCCESS,
    });
  };
}

export function continueWithLine(
  token: string,
  user: User,
  refCode: string = "",
  referer: string = "",
  routeReferer: string = "",
  prevLocation?: string,
  nextLocation?: string,
  cleanupSocialAuthLocalStorage?: () => any
): AppThunk {
  return (dispatch: Function, getState) => {
    // we are not calling API here, just managing state in respect to consistent code
    const language = getLanguage(getState());

    dispatch({
      type: AccountTypes.USER_AUTH_REQUEST,
    });

    const response = {
      data: {
        token,
        user,
      },
    };

    continueWithSuccessResponse(
      response,
      dispatch,
      language,
      refCode,
      referer,
      routeReferer,
      prevLocation,
      nextLocation
    );

    cleanupSocialAuthLocalStorage && cleanupSocialAuthLocalStorage();

    return dispatch({
      type: AccountTypes.USER_AUTH_SUCCESS,
    });
  };
}

export function confirmEmail(token: string, onSuccess?: () => any): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_EMAIL_VERIFY, {
      method: "POST",
      body: JSON.stringify({ token }),
    });

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestForgotPassword(
  currentForgotPasswordOption: CurrentAuthOption,
  email: string,
  phone: string | undefined,
  onSuccessEmail?: () => any,
  onSuccessPhone?: (token: any, maskedEmail: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_USER_FORGOT_PASSWORD, {
      method: "POST",
      body: JSON.stringify({
        currentForgotPasswordOption,
        email: currentForgotPasswordOption === "email" ? email : "",
        phone: currentForgotPasswordOption === "phone" && phone ? phone : "",
      }),
    });

    if (response.success) {
      if (currentForgotPasswordOption === "phone") {
        if (response.data.token) {
          onSuccessPhone &&
            onSuccessPhone(
              response.data.token,
              response.data.maskedEmail || null
            );
        } else {
          return dispatch({
            type: AccountTypes.SET_ERRORS,
            payload: [],
          });
        }
      } else {
        onSuccessEmail && onSuccessEmail();
      }
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestForgotPasswordByToken(
  token: string | null,
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    if (!token) {
      return;
    }

    const { response } = await createRequest(
      API_USER_FORGOT_PASSWORD_BY_TOKEN,
      {
        method: "POST",
        body: JSON.stringify({ token }),
      }
    );

    if (response.success) {
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestResetPassword(
  password: string,
  token: string,
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response } = await createRequest(API_USER_RESET_PASSWORD, {
      method: "PUT",
      body: JSON.stringify({ password, token }),
    });

    if (response.success) {
      onSuccess && onSuccess();
      return;
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestPlanUpdate(
  service: string,
  planCode: string,
  refCode?: string | null,
  trialCode?: string,
  cardInfo?: {
    cardNumber: string;
    expiry: string;
    cvc: string;
  },
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });
    const { response, error } = await createRequest(API_PLAN, {
      method: "POST",
      body: JSON.stringify({
        cardInfo,
        planCode,
        service,
        refCode: refCode || null,
        trialCode: trialCode || null,
      }),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (response.success) {
      if (response.data.expireDate) {
        // downgrade plane case
        // mixpanel プランを解約
        mixpanelCancelPlan(
          response.data.user,
          response.data.expireDate,
          service
        );
        dispatch(enableMayContainStaleSubscriptionData());
        onSuccess && onSuccess();
        return dispatch({
          type: AccountTypes.DOWNGRADE_PLAN,
          payload: {
            expireDate: response.data.expireDate,
            service,
          },
        });
      } else if (response.data?.user) {
        // upgrade plane case
        // mixpanel プランを契約
        mixpanelSubscribePlan(response.data.user, planCode, service);
        dispatch(setUser(response.data.user));
      }
      dispatch(enableMayContainStaleSubscriptionData());
      onSuccess && onSuccess();
    } else if (error || response.errors) {
      notification.error({
        message: "Error",
        description: response.errors[0].message || response.errors[0],
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    }
  };
}

function generateUserFormdata(data: {
  email?: string;
  name?: string;
  description?: string;
  language?: string;
  isBusiness?: boolean;
  avatar?: any;
  isFirst?: boolean;
  emailNotificationEnabled?: boolean;
  hostPhoto?: any;
  hostName?: string;
  hostStory?: string;
  hostStoryJp?: string;
  hostStoryZhcn?: string;
  hostStoryZhtw?: string;
}) {
  const formData = new FormData();

  data.name && formData.append("name", data.name);
  data.email && formData.append("email", data.email);
  (data.description || data.description === "") &&
    formData.append("description", data.description);
  data.language && formData.append("language", data.language);
  data.isBusiness &&
    formData.append("isBusiness", JSON.stringify(data.isBusiness));

  if (data.hasOwnProperty("emailNotificationEnabled")) {
    formData.append(
      "emailNotificationEnabled",
      JSON.stringify(data.emailNotificationEnabled)
    );
  }

  if (data.hasOwnProperty("isFirst")) {
    formData.append("isFirst", data.isFirst ? "true" : "false");
  }
  data.avatar && formData.append("avatar", data.avatar);
  data.hostPhoto && formData.append("hostPhoto", data.hostPhoto);
  data.hostName && formData.append("hostName", data.hostName);
  data.hostStory && formData.append("hostStory", data.hostStory);
  data.hostStoryJp && formData.append("hostStoryJp", data.hostStoryJp);
  data.hostStoryZhcn && formData.append("hostStoryZhcn", data.hostStoryZhcn);
  data.hostStoryZhtw && formData.append("hostStoryZhtw", data.hostStoryZhtw);

  return formData;
}

export function updateUser(
  data: {
    email?: string;
    name?: string;
    description?: string;
    language?: string;
    isBusiness?: boolean;
    avatar?: any;
    emailNotificationEnabled?: boolean;
  },
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const formData = generateUserFormdata(data);
    const { response, error } = await createRequest(API_USER_INFO, {
      method: "PUT",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAuthToken() || ""}`,
      },
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUser(response.data.user));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateTutorialStatus(data: {
  completedParty?: boolean;
  completedGuideMeetup?: boolean;
  completedSelfHost?: boolean;
}): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });

    const { response, error } = await createRequest(API_USER_TUTORIAL_STATUS, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUser(response.data.user));
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateUserPermission(
  data: { isAdmin: boolean },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_USER_PERMISSION, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUserPermission(data.isAdmin));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateUserProAdminPermission(
  data: { isProAdmin: boolean },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(
      API_USER_PRO_ADMIN_PERMISSION,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    );

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      dispatch(setUserProAdminPermission(response.data.user));
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateCardInfo(
  data: {
    cardNumber: string;
    expiry: string;
    cvc: string;
  },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_CARD, {
      method: "POST",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return dispatch({
        type: AccountTypes.SET_PAYMENT_METHOD,
        payload: response.data,
      });
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function requestEmailChange(onSuccess: () => any): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_EMAIL_CHANGE, {
      method: "PUT",
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function confirmEmailChange(
  data: {
    token: string;
    email: string;
  },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await createRequest(API_CONFIRM_EMAIL_CHANGE, {
      method: "PUT",
      body: JSON.stringify(data),
    });

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      onSuccess && onSuccess();
      return;
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function verifyStripeAccount(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: true,
    });

    const { response, error } = await getStripeVerificationLink();

    dispatch({
      type: AccountTypes.SET_IS_SUBMITTING,
      payload: false,
    });

    if (error) {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: [error],
      });
    } else if (response.success) {
      return (window.location.href = response.data.stripe_onbroading_url);
    } else {
      return dispatch({
        type: AccountTypes.SET_ERRORS,
        payload: response.errors,
      });
    }
  };
}

export function updateBusinessMode(
  isBusinessMode: boolean,
  businessModeName: string
): AppThunk {
  return (dispatch: Function) => {
    dispatch({
      type: AccountTypes.UPDATE_BUSINESS_MODE,
      payload: { isBusinessMode, businessModeName },
    });
  };
}

export function getUserPoint(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_USER_POINT, {
      method: "GET",
    });

    if (response.success) {
      return dispatch({
        type: AccountTypes.GET_USERPOINT,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function getUserPoinHistory(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_POINT_HISTORY, {
      method: "GET",
    });

    if (response.success) {
      return dispatch({
        type: AccountTypes.GET_USERPOINT_HISTORY,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function subscribePlanWithFreePoint(
  postData: SubscribePlanWithFreePoint
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({ type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT });
    const { response } = await createRequest(
      API_SUBSCRIBE_PLAN_WITH_FREE_POINT,
      {
        method: "POST",
        body: JSON.stringify(postData),
      }
    );
    if (response.success) {
      if (response.data?.user) {
        dispatch(setUser(response.data.user));
      }

      return dispatch({
        type: AccountTypes.SUBSCRIBE_PLAN_WITH_FREE_POINT,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
    });
  };
}

export function cancelPlanWithFreePoint(
  service: string,
  selfExplorePlan?: string,
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    const { response } = await createRequest(API_CANCEL_PLAN_WITH_FREE_POINT, {
      method: "POST",
      body: JSON.stringify({
        service,
        ...(selfExplorePlan ? { selfExplorePlan } : {}),
      }),
    });

    if (response.success) {
      onSuccess && onSuccess();
      return dispatch({
        type: AccountTypes.CANCEL_PLAN_WITH_FREE_POINT,
        payload: response.data,
      });
    }
    notification.error({
      message: "Error",
      description: response.errors[0].message || response.errors[0],
      duration: 15.0,
    });
    return dispatch({
      type: AccountTypes.SET_ERRORS,
    });
  };
}

export function fetchPaymentMethod(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
    const { response } = await createRequest(API_USER_PAYMENT_METHOD, {
      method: "GET",
    });

    if (response.success) {
      return dispatch({
        type: AccountTypes.SET_PAYMENT_METHOD,
        payload: response.data,
      });
    }
    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: response.errors,
    });
  };
}

export function requestResendOtp(
  type: ResendOtpTypes,
  token: string | null,
  successMessage: { message: string; description: string },
  errorMessage: { message: string; description: string }
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESEND_OTP_REQUEST,
    });

    const { response, error } = await createRequest(API_RESEND_OTP, {
      method: "POST",
      body: JSON.stringify({
        type,
        ...(token ? { token } : {}),
      }),
    });

    if (error) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.RESEND_OTP_FAILURE,
        payload: [error],
      });
    } else if (response.success) {
      notification.success({
        message: successMessage.message,
        description: successMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.RESEND_OTP_SUCCESS,
      });
    } else {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.RESEND_OTP_FAILURE,
        payload: response.errors,
      });
    }
  };
}

export function requestConfirmOtp(
  token: string,
  otpToken: string,
  successMessage: { message: string; description: string },
  errorMessage: { message: string; description: string },
  onSuccess: (phone: string, verifiedPhone: boolean) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.CONFIRM_OTP_REQUEST,
    });

    const { response, error } = await createRequest(API_CONFIRM_OTP, {
      method: "POST",
      body: JSON.stringify({ token, otpToken }),
    });

    if (error) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.CONFIRM_OTP_FAILURE,
        payload: [error],
      });
    } else if (response.success) {
      if (response.data.phone && response.data.verifiedPhone) {
        notification.success({
          message: successMessage.message,
          description: successMessage.description,
        });

        onSuccess &&
          onSuccess(response.data.phone, response.data.verifiedPhone);

        return dispatch({
          type: AccountTypes.CONFIRM_OTP_SUCCESS,
        });
      } else {
        notification.error({
          message: errorMessage.message,
          description: errorMessage.description,
          duration: 15.0,
        });
        return dispatch({
          type: AccountTypes.CONFIRM_OTP_FAILURE,
          payload: response.errors,
        });
      }
    } else {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });
      return dispatch({
        type: AccountTypes.CONFIRM_OTP_FAILURE,
        payload: response.errors,
      });
    }
  };
}

export function requestResetPasswordOtpConfirmation(
  token: string | null,
  oneTimePassword: string,
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_REQUEST,
    });

    if (!token) {
      return dispatch({
        type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_FAILURE,
        payload: [],
      });
    }

    const { response } = await createRequest(
      API_RESET_PASSWORD_OTP_CONFIRMATION,
      {
        method: "POST",
        body: JSON.stringify({
          token,
          oneTimePassword,
        }),
      }
    );

    if (response.success) {
      if (response.data.isValid) {
        onSuccess && onSuccess();
      } else {
        return dispatch({
          type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_FAILURE,
          payload: [],
        });
      }
      return dispatch({
        type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_FAILURE,
      payload: response.errors,
    });
  };
}

export function requestResetPasswordOtp(
  token: string | null,
  password: string,
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESET_PASSWORD_OTP_REQUEST,
    });

    if (!token) {
      return dispatch({
        type: AccountTypes.RESET_PASSWORD_OTP_FAILURE,
        payload: [],
      });
    }

    const { response } = await createRequest(API_RESET_PASSWORD_OTP, {
      method: "POST",
      body: JSON.stringify({
        token,
        password,
      }),
    });

    if (response.success) {
      onSuccess && onSuccess();
      return dispatch({
        type: AccountTypes.RESET_PASSWORD_OTP_SUCCESS,
      });
    }

    return dispatch({
      type: AccountTypes.RESET_PASSWORD_OTP_FAILURE,
      payload: response.errors,
    });
  };
}

export function requestCreatePreSignupPhoneUser(
  currentRegisterOption: CurrentAuthOption,
  phone: string | undefined,
  refCode: string,
  a8Code: string,
  onSuccess: (token: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_REQUEST,
    });

    const { response } = await createRequest(
      API_USER_CREATE_PRE_SIGNUP_PHONE_USER,
      {
        method: "POST",
        body: JSON.stringify({
          currentRegisterOption,
          phone,
          refCode: refCode || null,
          a8Code: a8Code || null,
        }),
      }
    );

    if (response.success) {
      onSuccess && onSuccess(response.data.token || "");

      return dispatch({
        type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_SUCCESS,
      });
    } else if (response.errors) {
      notification.error({
        message: "Error",
        description: response.errors[0].message || response.errors[0],
        duration: 15.0,
      });

      return dispatch({
        type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_FAILURE,
      payload: response.errors,
    });
  };
}

export function requestSaveNewPhone(
  newPhone: string,
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_REQUEST,
    });

    const { response } = await createRequest(
      API_USER_RESET_PHONE_SAVE_NEW_PHONE,
      {
        method: "PUT",
        body: JSON.stringify({ newPhone }),
      }
    );

    if (response.success) {
      onSuccess && onSuccess();

      return dispatch({
        type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_SUCCESS,
      });
    } else if (response.errors) {
      notification.error({
        message: "Error",
        description: response.errors[0].message || response.errors[0],
        duration: 15.0,
      });

      return dispatch({
        type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_FAILURE,
      payload: response.errors,
    });
  };
}

export function requestVerifyCurrentPhone(
  oneTimePasswordCurrentPhone: string,
  errorMessage: { message: string; description: string },
  onSuccess: (nextPhone: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_REQUEST,
    });

    const { response } = await createRequest(
      API_USER_RESET_PHONE_VERIFY_CURRENT_PHONE,
      {
        method: "POST",
        body: JSON.stringify({ oneTimePasswordCurrentPhone }),
      }
    );

    if (response.success) {
      if (response.data.nextPhone) {
        onSuccess && onSuccess(response.data.nextPhone);

        return dispatch({
          type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_SUCCESS,
        });
      } else {
        notification.error({
          message: errorMessage.message,
          description: errorMessage.description,
          duration: 15.0,
        });

        return dispatch({
          type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE,
          payload: response.errors,
        });
      }
    } else if (response.errors) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });

      return dispatch({
        type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE,
      payload: response.errors,
    });
  };
}

export function requestVerifyAndResetNewPhone(
  oneTimePasswordNewPhone: string,
  errorMessage: { message: string; description: string },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_REQUEST,
    });

    const { response } = await createRequest(
      API_USER_RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE,
      {
        method: "PUT",
        body: JSON.stringify({ oneTimePasswordNewPhone }),
      }
    );

    if (response.success) {
      localStorage.setItem("token", response.data.token);
      dispatch(setUser(response.data.user));
      dispatch(setAuthenticated(true));

      onSuccess && onSuccess();

      return dispatch({
        type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_SUCCESS,
      });
    } else if (response.errors) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });

      return dispatch({
        type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE,
      payload: response.errors,
    });
  };
}

export function updateCanCheckPaymentHistory(
  password: string,
  errorMessage: { message: string; description: string },
  onSuccess: () => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_REQUEST,
    });

    const { response } = await createRequest(
      API_USER_UPDATE_CAN_CHECK_PAYMENT_HISTORY,
      {
        method: "PUT",
        body: JSON.stringify({ password }),
      }
    );

    if (response.success) {
      onSuccess && onSuccess();

      return dispatch({
        type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_SUCCESS,
      });
    } else if (response.errors) {
      notification.error({
        message: errorMessage.message,
        description: errorMessage.description,
        duration: 15.0,
      });

      return dispatch({
        type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE,
      payload: response.errors,
    });
  };
}

export function fetchUserInfoByGoogleCallbackToken(
  token: string | null,
  onSuccess?: (user: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_REQUEST,
      payload: [],
    });

    if (!token) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE,
        payload: [],
      });
    }

    const { response } = await createRequest(
      `${API_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN}?token=${token}`,
      {
        method: "GET",
      }
    );

    if (response.success) {
      onSuccess && onSuccess(response.data.user);

      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_SUCCESS,
      });
    } else if (response.errors) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE,
      payload: response.errors,
    });
  };
}

export function fetchUserInfoByTwitterCallbackToken(
  token: string | null,
  onSuccess?: (user: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_REQUEST,
      payload: [],
    });

    if (!token) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE,
        payload: [],
      });
    }

    const { response } = await createRequest(
      `${API_USER_INFO_BY_TWITTER_CALLBACK_TOKEN}?token=${token}`,
      {
        method: "GET",
      }
    );

    if (response.success) {
      onSuccess && onSuccess(response.data.user);

      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_SUCCESS,
      });
    } else if (response.errors) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE,
      payload: response.errors,
    });
  };
}

export function fetchUserInfoByLineCallbackToken(
  token: string | null,
  onSuccess?: (user: any) => any
): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_REQUEST,
      payload: [],
    });

    if (!token) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE,
        payload: [],
      });
    }

    const { response } = await createRequest(
      `${API_USER_INFO_BY_LINE_CALLBACK_TOKEN}?token=${token}`,
      {
        method: "GET",
      }
    );

    if (response.success) {
      onSuccess && onSuccess(response.data.user);

      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_SUCCESS,
      });
    } else if (response.errors) {
      return dispatch({
        type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE,
        payload: response.errors,
      });
    }

    return dispatch({
      type: AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE,
      payload: response.errors,
    });
  };
}

export function closeManagementNotificationsPopup(
  onSuccess?: () => any
): AppThunk {
  return async (dispatch: Function) => {
    const { response } = await createRequest(
      API_USER_DISABLE_MANAGEMENT_NOTIFICATIONS_POPUP,
      {
        method: "POST",
      }
    );

    if (response.success) {
      if (response.data?.user) {
        dispatch(setUser(response.data.user));
        dispatch({
          type: AccountTypes.CLOSE_MANAGEMENT_NOTIFICATIONS_POPUP,
        });
        onSuccess && onSuccess();
        return;
      } else {
        return dispatch({
          type: AccountTypes.SET_ERRORS,
          payload: response.errors,
        });
      }
    }

    return dispatch({
      type: AccountTypes.SET_ERRORS,
      payload: [],
    });
  };
}

export function enableMayContainStaleSubscriptionData(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.ENABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA,
    });
  };
}

export function disableMayContainStaleSubscriptionData(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AccountTypes.DISABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA,
    });
  };
}

export async function sendContactRequest(
  data: {
    company: string;
    name: string;
    email: string;
    phone: string;
    inquiry: string;
  },
  template: SendContactTemplate
) {
  const { response } = await createRequest(API_SEND_CONTACT, {
    method: "POST",
    body: JSON.stringify({
      ...data,
      sendTo: "dokodemodoors",
      template: template.toString(),
    }),
  });

  if (!response.success) {
    notification.error({
      message: response.errors[0].message,
      duration: 15.0,
    });
    return false;
  }

  return true;
}

export type AccountActions =
  | SetUserAction
  | SetUserPermissionAction
  | SetUserProAdminPermissionAction
  | SetPlanAction
  | DowngradePlanAction
  | CancelPlanWithFreePointACtion
  | SetSelfHostPlanAction
  | SetIsSubmitting
  | SetRedirectPathAction
  | SetAuthenticatedAction
  | LogoutAction
  | SetAccountErrorsAction
  | UpdateBusinessModeAction
  | GetUserPointAction
  | GetUserPointHistoriesAction
  | ChangePointBySelfAction
  | SetPaymentMethod
  | UserAuthRequestAction
  | UserAuthSuccessAction
  | ResendOtpRequestAction
  | ResendOtpSuccessAction
  | ResendOtpFailureAction
  | ConfirmOtpRequestAction
  | ConfirmOtpSuccessAction
  | ConfirmOtpFailureAction
  | ResetPasswordOtpRequestAction
  | ResetPasswordOtpSuccessAction
  | ResetPasswordOtpConfirmationFailureAction
  | ResetPasswordOtpConfirmationRequestAction
  | ResetPasswordOtpConfirmationSuccessAction
  | ResetPasswordOtpFailureAction
  | CreatePreSignupPhoneUserRequestAction
  | CreatePreSignupPhoneUserSuccessAction
  | CreatePreSignupPhoneUserFailureAction
  | ResetPhoneSaveNewPhoneRequestAction
  | ResetPhoneSaveNewPhoneSuccessAction
  | ResetPhoneSaveNewPhoneFailureAction
  | ResetPhoneVerifyCurrentPhoneRequestAction
  | ResetPhoneVerifyCurrentPhoneSuccessAction
  | ResetPhoneVerifyCurrentPhoneFailureAction
  | ResetPhoneVerifyAndResetNewPhoneRequestAction
  | ResetPhoneVerifyAndResetNewPhoneSuccessAction
  | ResetPhoneVerifyAndResetNewPhoneFailureAction
  | UpdateCanCheckPaymentHistoryRequestAction
  | UpdateCanCheckPaymentHistorySuccessAction
  | UpdateCanCheckPaymentHistoryFailureAction
  | GetUserInfoByGoogleCallbackTokenRequestAction
  | GetUserInfoByGoogleCallbackTokenSuccessAction
  | GetUserInfoByGoogleCallbackTokenFailureAction
  | GetUserInfoByTwitterCallbackTokenRequestAction
  | GetUserInfoByTwitterCallbackTokenSuccessAction
  | GetUserInfoByTwitterCallbackTokenFailureAction
  | GetUserInfoByLineCallbackTokenRequestAction
  | GetUserInfoByLineCallbackTokenSuccessAction
  | GetUserInfoByLineCallbackTokenFailureAction
  | CloseManagementNotificationsPopupAction
  | EnableMayContainStaleSubscriptionDataAction
  | DisableMayContainStaleSubscriptionDataAction;
