import { notification } from "antd";
import { Action } from "redux";

import { AppActions, IPost } from "./types";
import { AppThunk } from "../types";
import { updateUser } from "../account/actions";
import { Language } from "../../i18n";
import { createRequest } from "../../utils/network";
import { API_SITE_INFO } from "../../routes/constants";
import supportsWebP from "supports-webp";

export interface SetLoadingAction extends Action {
  type: AppActions.SET_LOADING;
  payload: boolean;
}

export interface SetLanguageAction extends Action {
  type: AppActions.SET_LANGUAGE;
  payload: Language;
}

export interface SetLanguageAction extends Action {
  type: AppActions.SET_LANGUAGE;
  payload: Language;
}

export interface SetSiteInfo extends Action {
  type: AppActions.SET_SITE_INFO;
  payload: {
    newPosts: IPost;
  };
}

export interface SetHasWebpSupportRequest extends Action {
  type: AppActions.SET_HAS_WEBP_SUPPORT_REQUEST;
}

export interface SetHasWebpSupportSuccess extends Action {
  type: AppActions.SET_HAS_WEBP_SUPPORT_SUCCESS;
  payload: boolean;
}

export interface SetHasWebpSupportFailure extends Action {
  type: AppActions.SET_HAS_WEBP_SUPPORT_FAILURE;
}

export interface SetEnableLiveBroadcastTestModalVisibility extends Action {
  type: AppActions.SET_ENABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY;
}

export interface SetDisableLiveBroadcastTestModalVisibility extends Action {
  type: AppActions.SET_DISABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY;
}

export type AppActionTypes =
  | SetLoadingAction
  | SetLanguageAction
  | SetSiteInfo
  | SetHasWebpSupportRequest
  | SetHasWebpSupportSuccess
  | SetHasWebpSupportFailure
  | SetEnableLiveBroadcastTestModalVisibility
  | SetDisableLiveBroadcastTestModalVisibility;

export function setLoading(payload: boolean): SetLoadingAction {
  return { type: AppActions.SET_LOADING, payload };
}

export function setLanguage(payload: Language, notAuth = false): AppThunk {
  return async (dispatch: Function) => {
    if (!notAuth) {
      await dispatch(updateUser({ language: payload }, () => {}));
    }
    dispatch({
      type: AppActions.SET_LANGUAGE,
      payload: payload,
    });
  };
}

export function fetchSiteInfo(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AppActions.SET_LOADING,
      payload: true,
    });

    const { response, error } = await createRequest(API_SITE_INFO, {
      method: "GET",
    });

    if (error) {
      notification.error({
        message: error as string,
        duration: 15.0,
      });
      return false;
    } else if (response.success) {
      dispatch({
        type: AppActions.SET_SITE_INFO,
        payload: response.data,
      });
      return true;
    } else {
      response.errors.forEach((err: any) =>
        notification.error({
          message: err.message,
          duration: 15.0,
        })
      );
      return false;
    }
  };
}

export function setHasWebpSupport(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AppActions.SET_HAS_WEBP_SUPPORT_REQUEST,
    });

    try {
      let hasWebpSupport;
      if (await supportsWebP) {
        hasWebpSupport = true;
      } else {
        hasWebpSupport = false;
      }

      dispatch({
        type: AppActions.SET_HAS_WEBP_SUPPORT_SUCCESS,
        payload: hasWebpSupport,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: AppActions.SET_HAS_WEBP_SUPPORT_FAILURE,
      });
    }
  };
}

export function setEnableLiveBroadcastTestModalVisibility(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AppActions.SET_ENABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY,
    });
  };
}

export function setDisableLiveBroadcastTestModalVisibility(): AppThunk {
  return async (dispatch: Function) => {
    dispatch({
      type: AppActions.SET_DISABLE_LIVE_BROADCAST_TEST_MODAL_VISIBILITY,
    });
  };
}
