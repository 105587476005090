import { CategoryType, PaymentType, ServiceType } from "../room/types";

export enum ReviewActions {
  GET_REVIEW_DETAILS_REQUEST = "review/GET_REVIEW_DETAILS_REQUEST",
  GET_REVIEW_DETAILS_SUCCESS = "review/GET_REVIEW_DETAILS_SUCCESS",
  LIKE_REVIEW_PHOTO = "LIKE_REVIEW_PHOTO",
  DISLIKE_REVIEW_PHOTO = "DISLIKE_REVIEW_PHOTO",
  REVIEW_ERROR = "review/REVIEW_ERROR",
  LIKE_REVIEW = "LIKE_REVIEW",
  DISLIKE_REVIEW = "DISLIKE_REVIEW",
  GET_REVIEWS_REQUEST = "room/GET_REVIEWS_REQUEST",
  GET_REVIEWS_SUCCESS = "room/GET_REVIEWS_SUCCESS",
  GET_REVIEWS_FAILURE = "room/GET_REVIEWS_FAILURE",
}

export interface ReviewDetails {
  averagedEvaluations: string | null;
  averagedEvaluationsWithoutQuestionnaire: string | null;
  reviewsCount: number;
  Reviews: ReviewItem[];
  reviewRatings: ReviewRating;
}

export interface ReviewRating {
  5: number;
  4: number;
  3: number;
  2: number;
  1: number;
}

export interface ReviewItemRoom {
  id: number;
  title: string;
  titleJp: string | null;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  category: CategoryType;
  type: ServiceType;
  paymentType: PaymentType;
  author?: {
    id: number;
    hostName: string;
  };
  isFree?: boolean;
  price?: string;
}

export interface ReviewItem {
  id: number;
  type: string;
  review?: string; // Legacy
  comment: string; // New
  commentJp: string | null;
  commentZhcn: string | null;
  commentZhtw: string | null;
  commentVi: string | null;
  commentHi: string | null;
  evaluation: number;
  userId?: number;
  roomId?: number;
  createdAt: string | null;
  updatedAt: string;
  ReviewPhotos?: ReviewPhotoInterface[];
  ReviewLikes?: ReviewLike[];
  User?: {
    id: number;
    name: string;
    email: string;
    avatarUrl: string;
    deletedAt?: string | null;
  };
  Room?: ReviewItemRoom;
}

export interface ReviewLike {
  id: number;
  userId: number;
}

export interface ReviewPhotoInterface {
  id: number;
  userId: number;
  bookingId: number | null;
  explorePlaceHistoryId: number | null;
  photo: string;
  ReviewPhotosLike: ReviewPhotoLikeInterface[];
}

export interface ReviewPhotoLikeInterface {
  liked: boolean;
}

export interface ReviewsActionInterface {
  type?: string | null;
  category?: string | null;
  roomType?: string | null;
  page?: number | null;
}
