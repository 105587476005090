import { AccountActions } from "./actions";
import {
  AccountTypes,
  User,
  PlanTypes,
  IPaymentMethod,
  SelfExplorePlanTypes,
} from "./types";
import { setSentryTag } from "../../utils/sentry";

export type AccountState = {
  user: User;
  loaded: boolean;
  isSubmitting: boolean;
  authenticated: boolean;
  redirectPath: string | null;
  errors: any[];
  isBusinessMode: boolean;
  isBusinessUpdated: boolean;
  paymentMethod: IPaymentMethod | null;
  userAuthLoading: boolean;
  resendOtpLoading: boolean;
  confirmOtpLoading: boolean;
  resetPasswordOtpConfirmationLoading: boolean;
  resetPasswordOtpLoading: boolean;
  createPreSignupPhoneUserLoading: boolean;
  resetPhoneSaveNewPhoneLoading: boolean;
  resetPhoneVerifyCurrentPhoneLoading: boolean;
  resetPhoneVerifyAndResetNewPhoneLoading: boolean;
  updateCanCheckPaymentHistoryLoading: boolean;
  userInfoByGoogleCallbackTokenLoading: boolean;
  userInfoByTwitterCallbackTokenLoading: boolean;
  userInfoByLineCallbackTokenLoading: boolean;
  mayContainStaleSubscriptionData: boolean;
};

export const accountInitialState: AccountState = {
  user: {
    id: 0,
    name: "",
    email: "",
    phone: "",
    description: "",
    avatar: "",
    point: "",
    latestPointsExpireDate: "",
    plan: PlanTypes.free,
    selfExplorePlan: {
      hasPlan: false,
      plan: SelfExplorePlanTypes.free,
      expireDate: "",
      isPointPay: false,
      isPointCancelled: false,
    },
    isAdmin: false,
    isProAdmin: false,
    emailNotificationEnabled: true,
    isBusiness: false,
    stripeAccountId: null,
    stripeVerifyStatus: null,
    partyPlan: {
      hasPlan: false,
      expireDate: "",
    },
    therapyPlan: {
      hasPlan: false,
      expireDate: "",
    },
    pointHistories: null,
    purchasedConcerts: [],
    isFirst: false,
    newToParty: false,
    newToMeetup: false,
    newToSelfHost: false,
    canCheckPaymentHistory: false,
    asAffiliatedUser: {
      isWrSubscribed: false,
      newToWrSubscription: false,
    },
    hostName: "",
    hostPhoto: "",
    hostStory: "",
    hostStoryJp: "",
    hostStoryZhcn: "",
    hostStoryZhtw: "",
    managementNotificationsPopupEnabled: true,
    isJoinByPassword: false,
    enableLabß: false,
    submittedLabß: false,
  },
  loaded: false,
  isSubmitting: false,
  authenticated: false,
  redirectPath: null,
  errors: [] as any[],
  isBusinessMode: false,
  isBusinessUpdated: false,
  paymentMethod: null,
  userAuthLoading: false,
  resendOtpLoading: false,
  confirmOtpLoading: false,
  resetPasswordOtpConfirmationLoading: false,
  resetPasswordOtpLoading: false,
  createPreSignupPhoneUserLoading: false,
  resetPhoneSaveNewPhoneLoading: false,
  resetPhoneVerifyCurrentPhoneLoading: false,
  resetPhoneVerifyAndResetNewPhoneLoading: false,
  updateCanCheckPaymentHistoryLoading: false,
  userInfoByGoogleCallbackTokenLoading: false,
  userInfoByTwitterCallbackTokenLoading: false,
  userInfoByLineCallbackTokenLoading: false,
  mayContainStaleSubscriptionData: false,
};

export function accountReducer(
  state: AccountState = accountInitialState,
  action: AccountActions
): AccountState {
  switch (action.type) {
    case AccountTypes.SET_USER:
      const user: User = {
        ...state.user,
        ...action.payload.user,
      };
      setSentryTag(user);
      return {
        ...state,
        user,
        loaded: true,
      };
    case AccountTypes.SET_USER_PERMISSION:
      return {
        ...state,
        user: {
          ...state.user,
          isAdmin: action.payload.isAdmin,
        },
        loaded: true,
      };
    case AccountTypes.SET_USER_PRO_ADMIN_PERMISSION:
      const newUser: User = {
        ...state.user,
        ...action.payload.user,
      };

      return {
        ...state,
        user: newUser,
        loaded: true,
      };
    case AccountTypes.SET_PLAN:
      return {
        ...state,
        user: {
          ...state.user,
          plan: action.payload as any,
        },
      };
    case AccountTypes.DOWNGRADE_PLAN:
      switch (action.payload.service) {
        case "SelfExplore":
          return {
            ...state,
            user: {
              ...state.user,
              selfExplorePlan: {
                ...state.user.selfExplorePlan,
                hasPlan: state.user.selfExplorePlan.hasPlan,
                expireDate: action.payload.expireDate,
              },
            },
          };
        case "Party":
          return {
            ...state,
            user: {
              ...state.user,
              partyPlan: {
                hasPlan: state.user.partyPlan.hasPlan,
                expireDate: action.payload.expireDate,
              },
            },
          };
        case "Therapy":
          return {
            ...state,
            user: {
              ...state.user,
              therapyPlan: {
                hasPlan: state.user.therapyPlan.hasPlan,
                expireDate: action.payload.expireDate,
              },
            },
          };

        default:
          return {
            ...state,
          };
      }
    case AccountTypes.CANCEL_PLAN_WITH_FREE_POINT:
      return {
        ...state,
        user: {
          ...state.user,
          selfExplorePlan: {
            ...state.user.selfExplorePlan,
            isPointCancelled: action.payload.isPointCancelled,
          },
        },
      };
    case AccountTypes.SET_USER_SELFHOST_PLAN:
      return {
        ...state,
        user: {
          ...state.user,
          selfExplorePlan: action.payload as any,
        },
      };
    case AccountTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case AccountTypes.SET_REDIRECT_PATH:
      return { ...state, redirectPath: action.payload.redirectPath };
    case AccountTypes.SET_AUTHENTICATE:
      return { ...state, authenticated: action.payload.authenticated };
    case AccountTypes.LOGOUT:
      return {
        ...accountInitialState,
      };
    case AccountTypes.SET_ERRORS:
      return {
        ...state,
        errors: action.payload as any[],
        userAuthLoading: false,
      };
    case AccountTypes.UPDATE_BUSINESS_MODE:
      localStorage.setItem(
        action.payload.businessModeName,
        !!action.payload.isBusinessMode ? "business" : "normal"
      );

      return {
        ...state,
        isBusinessMode: action.payload.isBusinessMode,
        isBusinessUpdated: true,
      };
    case AccountTypes.GET_USERPOINT:
      return {
        ...state,
        user: {
          ...state.user,
          point: action.payload.user.remainingPoint,
          latestPointsExpireDate: action.payload.user.latestPointsExpireDate,
        },
      };
    case AccountTypes.GET_USERPOINT_HISTORY:
      return {
        ...state,
        user: {
          ...state.user,
          pointHistories: action.payload?.pointHistories,
        },
      };
    case AccountTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case AccountTypes.USER_AUTH_REQUEST:
      return {
        ...state,
        userAuthLoading: true,
      };
    case AccountTypes.USER_AUTH_SUCCESS:
      return {
        ...state,
        userAuthLoading: false,
      };
    case AccountTypes.RESEND_OTP_REQUEST:
      return {
        ...state,
        resendOtpLoading: true,
      };
    case AccountTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        resendOtpLoading: false,
      };
    case AccountTypes.RESEND_OTP_FAILURE:
      return {
        ...state,
        resendOtpLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.CONFIRM_OTP_REQUEST:
      return {
        ...state,
        confirmOtpLoading: true,
      };
    case AccountTypes.CONFIRM_OTP_SUCCESS:
      return {
        ...state,
        confirmOtpLoading: false,
      };
    case AccountTypes.CONFIRM_OTP_FAILURE:
      return {
        ...state,
        confirmOtpLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_REQUEST:
      return {
        ...state,
        resetPasswordOtpConfirmationLoading: true,
      };
    case AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_SUCCESS:
      return {
        ...state,
        resetPasswordOtpConfirmationLoading: false,
      };
    case AccountTypes.RESET_PASSWORD_OTP_CONFIRMATION_FAILURE:
      return {
        ...state,
        resetPasswordOtpConfirmationLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.RESET_PASSWORD_OTP_REQUEST:
      return {
        ...state,
        resetPasswordOtpLoading: true,
      };
    case AccountTypes.RESET_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        resetPasswordOtpLoading: false,
      };
    case AccountTypes.RESET_PASSWORD_OTP_FAILURE:
      return {
        ...state,
        resetPasswordOtpLoading: false,
        errors: action.payload as any[],
      };

    case AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_REQUEST:
      return {
        ...state,
        createPreSignupPhoneUserLoading: true,
      };
    case AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_SUCCESS:
      return {
        ...state,
        createPreSignupPhoneUserLoading: false,
      };
    case AccountTypes.CREATE_PRE_SIGNUP_PHONE_USER_FAILURE:
      return {
        ...state,
        createPreSignupPhoneUserLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_REQUEST:
      return {
        ...state,
        resetPhoneSaveNewPhoneLoading: true,
      };
    case AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_SUCCESS:
      return {
        ...state,
        resetPhoneSaveNewPhoneLoading: false,
      };
    case AccountTypes.RESET_PHONE_SAVE_NEW_PHONE_FAILURE:
      return {
        ...state,
        resetPhoneSaveNewPhoneLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_REQUEST:
      return {
        ...state,
        resetPhoneVerifyCurrentPhoneLoading: true,
      };
    case AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_SUCCESS:
      return {
        ...state,
        resetPhoneVerifyCurrentPhoneLoading: false,
      };
    case AccountTypes.RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE:
      return {
        ...state,
        resetPhoneVerifyCurrentPhoneLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_REQUEST:
      return {
        ...state,
        resetPhoneVerifyAndResetNewPhoneLoading: true,
      };
    case AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_SUCCESS:
      return {
        ...state,
        resetPhoneVerifyAndResetNewPhoneLoading: false,
      };
    case AccountTypes.RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE:
      return {
        ...state,
        resetPhoneVerifyAndResetNewPhoneLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_REQUEST:
      return {
        ...state,
        updateCanCheckPaymentHistoryLoading: true,
      };
    case AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        updateCanCheckPaymentHistoryLoading: false,
      };
    case AccountTypes.UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE:
      return {
        ...state,
        updateCanCheckPaymentHistoryLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_REQUEST:
      return {
        ...state,
        userInfoByGoogleCallbackTokenLoading: true,
      };
    case AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_SUCCESS:
      return {
        ...state,
        userInfoByGoogleCallbackTokenLoading: false,
      };
    case AccountTypes.GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE:
      return {
        ...state,
        userInfoByGoogleCallbackTokenLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_REQUEST:
      return {
        ...state,
        userInfoByTwitterCallbackTokenLoading: true,
      };
    case AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_SUCCESS:
      return {
        ...state,
        userInfoByTwitterCallbackTokenLoading: false,
      };
    case AccountTypes.GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE:
      return {
        ...state,
        userInfoByTwitterCallbackTokenLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_REQUEST:
      return {
        ...state,
        userInfoByLineCallbackTokenLoading: true,
      };
    case AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_SUCCESS:
      return {
        ...state,
        userInfoByLineCallbackTokenLoading: false,
      };
    case AccountTypes.GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE:
      return {
        ...state,
        userInfoByLineCallbackTokenLoading: false,
        errors: action.payload as any[],
      };
    case AccountTypes.CLOSE_MANAGEMENT_NOTIFICATIONS_POPUP:
      return {
        ...state,
        user: {
          ...state.user,
          managementNotificationsPopupEnabled: false,
        },
      };
    case AccountTypes.ENABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA:
      return {
        ...state,
        mayContainStaleSubscriptionData: true,
      };
    case AccountTypes.DISABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA:
      return {
        ...state,
        mayContainStaleSubscriptionData: false,
      };
    default:
      return state;
  }
}
