import { countriesGeoInfo } from "../../utils/countryGeoInfo";
import type { Dayjs } from "dayjs";
import { RoomCategoryType } from "../room/types";

export enum TourActions {
  SET_LOADING = "tour/SET_LOADING",
  SET_SINGLE_TOUR = "tour/SET_SINGLE_TOUR",
  SET_TOUR_MANAGEMENT_INDEX = "tour/SET_TOUR_MANAGEMENT_INDEX",
  GET_RECTOURS_REQUEST = "tour/GET_RECTOURS_REQUEST",
  GET_RECTOURS_SUCCESS = "tour/GET_RECTOURS_SUCCESS",
  GET_RECTOURS_ERROR = "tour/GET_RECTOURS_ERROR",
  GET_CREATETOURS_REQUEST = "tour/GET_CREATETOURS_REQUEST",
  GET_CREATETOURS_SUCCESS = "tour/GET_CREATETOURS_SUCCESS",
  GET_CREATETOURS_ERROR = "tour/GET_CREATETOURS_ERROR",
  CREATE_TOUR_REQUEST = "tour/CREATE_TOUR_REQUEST",
  CREATE_TOUR_SUCCESS = "tour/CREATE_TOUR_SUCCESS",
  UPDATE_TOUR_REQUEST = "tour/UPDATE_TOUR_REQUEST",
  UPDATE_TOUR_SUCCESS = "tour/UPDATE_TOUR_SUCCESS",
  UPDATE_TOUR_ERROR = "tour/UPDATE_TOUR_ERROR",
  DELETE_TOUR_REQUEST = "tour/DELETE_TOUR_REQUEST",
  DELETE_TOUR_SUCCESS = "tour/DELETE_TOUR_SUCCESS",
  DELETE_TOUR_ERROR = "tour/DELETE_TOUR_ERROR",
  GET_RECTOURDETAILS_REQUEST = "tour/GET_RECTOURDETAILS_REQUEST",
  GET_RECTOURDETAILS_REQUEST_ITINERARIES_ONLY = "tour/GET_RECTOURDETAILS_REQUEST_ITINERARIES_ONLY",
  GET_RECTOURDETAILS_SUCCESS = "tour/GET_RECTOURDETAILS_SUCCESS",
  GET_RECTOURDETAILS_ERROR = "tour/GET_RECTOURDETAILS_ERROR",
  CREATE_RESERVATION_REQUEST = "tour/CREATE_RESERVATION_REQUEST",
  CREATE_RESERVATION_SUCCESS = "tour/CREATE_RESERVATION_SUCCESS",
  CREATE_RESERVATION_ERROR = "tour/CREATE_RESERVATION_ERROR",
  GET_RESERVATION_REQUEST = "tour/GET_RESERVATION_REQUEST",
  GET_RESERVATION_SUCCESS = "tour/GET_RESERVATION_SUCCESS",
  GET_RESERVATION_ERROR = "tour/GET_RESERVATION_ERROR",
  DELETE_RESERVATION_REQUEST = "tour/DELETE_RESERVATION_REQUEST",
  DELETE_RESERVATION_SUCCESS = "tour/DELETE_RESERVATION_SUCCESS",
  DELETE_RESERVATION_ERROR = "tour/DELETE_RESERVATION_ERROR",
  GET_SEARCHTOURS_REQUEST = "tour/GET_SEARCHTOURS_REQUEST",
  GET_SEARCHTOURS_SUCCESS = "tour/GET_SEARCHTOURS_SUCCESS",
  GET_SEARCHTOURS_ERROR = "tour/GET_SEARCHTOURS_ERROR",
  GET_SPOT_LIST_REQUEST = "tour/GET_SPOT_LIST_REQUEST",
  GET_SPOT_LIST_SUCCESS = "tour/GET_SPOT_LIST_SUCCESS",
  SET_SPOT_LIST_FAILURE = "tour/SET_SPOT_LIST_FAILURE",
  SET_COUNTRIES_REQUEST = "tour/SET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS = "tour/GET_COUNTRIES_SUCCESS",
  SET_COUNTRIES_FAILURE = "tour/SET_COUNTRIES_FAILURE",
  GET_TOURINRESERVATION_REQUEST = "tour/GET_TOURINRESERVATION_REQUEST",
  GET_TOURINRESERVATION_SUCCESS = "tour/GET_TOURINRESERVATION_SUCCESS",
  GET_TOURINRESERVATION_FAILURE = "tour/GET_TOURINRESERVATION_FAILURE",
  SET_HISTORYTOUR = "tour/SET_HISTORYTOUR",
  GET_HISTORYTOUR_REQUEST = "tour/GET_HISTORYTOUR_REQUEST",
  GET_HISTORYTOUR_SUCCESS = "tour/GET_HISTORYTOUR_SUCCESS",
  GET_HISTORYTOUR_FAILURE = "tour/GET_HISTORYTOUR_FAILURE",
  GET_BOOKING_FOR_REPAY_REQUEST = "tour/GET_BOOKING_FOR_REPAY_REQUEST",
  GET_BOOKING_FOR_REPAY_ERROR = "tour/GET_BOOKING_FOR_REPAY_ERROR",
  GET_TOURREVIEWS_REQUEST = "tour/GET_TOURREVIEWS_REQUEST",
  GET_TOURREVIEWS_SUCCESS = "tour/GET_TOURREVIEWS_SUCCESS",
  SEND_TOURREVIEW_REQUEST = "tour/SEND_TOURREVIEW_REQUEST",
  SEND_TOURREVIEW_SUCCESS = "tour/SEND_TOURREVIEW_SUCCESS",
  POST_TOUR_REVIEW_LIKE = "tour/POST_TOUR_REVIEW_LIKE",
  POST_REVIEW_PHOTO = "POST_REVIEW_PHOTO",
  POST_REVIEW_PHOTO_LIKE = "POST_REVIEW_PHOTO_LIKE",
  SET_TOUR_ERROR = "tour/SET_TOUR_ERROR",
  GET_TEMPORARY_TOUR = "tour/GET_TEMPORARY_TOUR",
  GET_TEMPORARY_SUCCESS = "tour/GET_TEMPORARY_SUCCESS",
  GET_TEMPORARY_ERROR = "tour/GET_TEMPORARY_ERROR",
  GET_TOUR_CATEGORIES = "tour/GET_TOUR_CATEGORIES",
  GET_TOUR_CATEGORIES_SUCCESS = "tour/GET_TOUR_CATEGORIES_SUCCESS",
  VALIDATE_ADD_SCHEDULE_REQUEST = "tour/VALIDATE_ADD_SCHEDULE_REQUEST",
  VALIDATE_ADD_SCHEDULE_SUCCESS = "tour/VALIDATE_ADD_SCHEDULE_SUCCESS",
  VALIDATE_ADD_SCHEDULE_FAILURE = "tour/VALIDATE_ADD_SCHEDULE_FAILURE",
  RESET_VALIDATE_ADD_SCHEDULE_FLAG = "tour/RESET_VALIDATE_ADD_SCHEDULE_FLAG",
  GET_POINT_EMAIL = "tour/GET_POINT_EMAIL",
  GET_POINT_EMAIL_SUCCESS = "tour/GET_POINT_EMAIL_SUCCESS",
  GET_POINT_EMAIL_ERROR = "tour/GET_POINT_EMAIL_FAILURE",
  POST_POINT_CHANGE_PROADMIN = "POST_POINT_CHANGE_PROADMIN",
  POST_POINT_CHANGE_PROADMIN_SUCCESS = "POST_POINT_CHANGE_PROADMIN_SUCCESS",
  POST_POINT_CHANGE_PROADMIN_ERROR = "POST_POINT_CHANGE_PROADMIN_ERROR",
  UPDATE_TOUR_CHOUSEISAN_LINK_REQUEST = "UPDATE_TOUR_CHOUSEISAN_LINK_REQUEST",
  UPDATE_TOUR_CHOUSEISAN_LINK_SUCCESS = "UPDATE_TOUR_CHOUSEISAN_LINK_SUCCESS",
  UPDATE_TOUR_CHOUSEISAN_LINK_ERROR = "UPDATE_TOUR_CHOUSEISAN_LINK_ERROR",
  SUGGEST_TOUR_PREFERRED_SCHEDULE_REQUEST = "SUGGEST_TOUR_PREFERRED_SCHEDULE_REQUEST",
  SUGGEST_TOUR_PREFERRED_SCHEDULE_SUCCESS = "SUGGEST_TOUR_PREFERRED_SCHEDULE_SUCCESS",
  SUGGEST_TOUR_PREFERRED_SCHEDULE_ERROR = "SUGGEST_TOUR_PREFERRED_SCHEDULE_ERROR",
  CANCEL_BOOKING = "tour/CANCEL_BOOKING",
  CANCEL_PRESENT_BOOKING = "CANCEL_PRESENT_BOOKING",
  GET_ROOMS_REVIEWS_FOR_USER_REQUEST = "GET_ROOMS_REVIEWS_FOR_USER_REQUEST",
  GET_ROOMS_REVIEWS_FOR_USER_SUCCESS = "GET_ROOMS_REVIEWS_FOR_USER_SUCCESS",
  GET_ROOMS_REVIEWS_FOR_USER_FAILURE = "GET_ROOMS_REVIEWS_FOR_USER_FAILURE",
  MUTATE_ROOMS_REVIEW_FOR_USER_REQUEST = "MUTATE_ROOMS_REVIEW_FOR_USER_REQUEST",
  MUTATE_ROOMS_REVIEW_FOR_USER_SUCCESS = "MUTATE_ROOMS_REVIEW_FOR_USER_SUCCESS",
  MUTATE_ROOMS_REVIEW_FOR_USER_FAILURE = "MUTATE_ROOMS_REVIEW_FOR_USER_FAILURE",
}

export interface RecToursInterface {
  tourId: number;
  location: string;
  language?: string; //TODO
  title: string;
  titleJp?: string;
  titleZhcn?: string | null;
  titleZhtw?: string | null;
  titleVi?: string | null;
  titleHi?: string | null;
  photos: string[];
  payment?: string;
  paymentUSD?: number;
  time?: string;
  videoId?: number;
  countryInfo?: TravelCountryInterface;
  countryId?: string;
  Categories: TourCategory[];
  Video?: {
    difficulty: string | null;
    id: number;
  };
  isFree: boolean;
}

export interface CreateTourInterface {
  tourId: number;
  videoId: string;
  location: string;
  language: string[]; // Because there are multiple
  title: string;
  titleJp: string;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  photos: string[];
  payment: number;
  paymentUSD: number;
  time: number;
  averagedEvaluations: string | null;
  chouseisanLink: string | null;
  chouseisanLinkVisibility: boolean;
  Video?: {
    difficulty: string | null;
    id: number;
  };
}

export type Permission = "public" | "allFriends" | "limitedFriends" | "onlyMe";
export type Accommodation =
  | "detachedHouse"
  | "condominium"
  | "apartment"
  | "others";
export interface ITourDetail {
  id: number;
  videoId: string;
  location: string;
  language: string[];
  otherLanguages: string[];
  title: string;
  titleJp: string;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  description: string;
  descriptionJp?: string;
  descriptionZhcn?: string;
  descriptionZhtw?: string;
  descriptionVi?: string;
  descriptionHi?: string;
  photos: string[];
  payment: number;
  paymentUSD: number;
  time: number;
  averagedEvaluations: string | null;
  chouseisanLink: string | null;
  chouseisanLinkVisibility: boolean;
  Video?: {
    name: string;
    size: number;
    difficulty: string | null;
    id: number;
    SubCategories: {
      id: number;
      name: string;
      nameJp: string;
      nameZhcn: string;
      nameZhtw: string;
      nameVi: string;
      nameHi: string;
      hasFreeDescription: string;
    }[];
    VideoCategoryFreeDescriptions: {
      subCategoryId: number;
      freeDescription: string;
    }[];
    categoryTags: {
      id: string;
      name: string;
      nameJp: string;
      nameZhcn: string;
      nameZhtw: string;
      nameVi: string;
      nameHi: string;
    }[];
    is2d: boolean;
  };
  Itineraries: {
    id: number;
    timeStart: string;
    timeEnd: string;
    minimum: string;
    maximum: string;
  }[];
  primaryLanguage: "en" | "ja" | "zh-CN" | "zh-TW" | "vi" | "hi";
  note: string;
  isFree: boolean;
  RoomPrivacy: {
    id: number;
    friendIds: string[];
    permission: Permission;
  };
  RoomPrices: {
    id: number;
    minimum: number;
    maximum: number;
    amount: string;
    currency: string;
    name: string;
  }[];
  isLiveStream: boolean;
}

export interface ChouseisanLinkInfo {
  chouseisanLink: string | null;
  chouseisanLinkVisibility: boolean;
}

export interface TourDeleteNotification {
  success: string;
  errorBookings: string;
}

export interface SearchTourBookingInterface {
  guestId: number;
  evaluation: number;
  review: string;
  liked: boolean;
  likeCount: number;
  // bookingId for GuideMeetup, explorePlaceHistoryId for SelfHost to use API_REVIEW_LIKE
  bookingId: number;
  explorePlaceHistoryId: number;
  updatedAt: string;
  reviewedAt: string | null;
  User: {
    id: number;
    name: string;
    email: string;
    avatarUrl: string;
    favorites?: any[];
  };
  PresentHistory?: {
    receiverId: number;
    receiverEmail: string;
  } | null;
  type?: "vip" | "normal" | null;
}

export interface SearchTourInterface {
  Room: {
    id: number;
    language: string;
    location: string;
    payment: number;
    paymentUSD: number;
    photos: string[];
    title: string;
    titleJp: string;
    titleZhcn: string | null;
    titleZhtw: string | null;
    titleVi: string | null;
    titleHi: string | null;
    guideId: number;
    Video: {
      difficulty: string | null;
      id: number;
    };
    isFree: boolean;
    averagedEvaluations: string | null;
    reviewsCount: number;
    isLiveStream: boolean;
  };
  Bookings: SearchTourBookingInterface[];
  ItineraryPrices: RecTourDetailItineraryAvailableInterface[];
  available: number;
  itineraryId: number;
  reserved: number;
  minimum: number;
  maximum: number;
  timeStart: Dayjs;
  timeEnd: Dayjs;
  status: string;
  reservationGuests: number[];
  giftedReservationGuests: GiftedReservationGuests[];
}

export interface SearchTourCountInterface {
  countryId: keyof typeof countriesGeoInfo;
  tourCount: number;
}

export interface TourDetailPriceInterface {
  name: string;
  paymentUSD: number;
  minimum: number;
  maximum: number;
}

export interface RefRoomInterface {
  id: number;
  title: string;
  titleJp: string | null;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  description: string;
  descriptionJp: string;
  descriptionZhcn: string | null;
  descriptionZhtw: string | null;
  descriptionVi: string | null;
  descriptionHi: string | null;
  type: string;
}

export interface RecTourDetailsInterface {
  id: number;
  guideId: number;
  guideEmail: string;
  isDeleted: boolean;
  category: RoomCategoryType;
  type: string;
  country: string;
  location: string;
  language: string | string[];
  otherLanguages: string[];
  hostName: string;
  hostPhoto: string;
  hostStory: string;
  minimum: number;
  maximum: number;
  groupParticipation: boolean;
  groupPayment: number;
  title: string;
  titleJp: string;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  description: string;
  descriptionJp: string;
  descriptionZhcn: string | null;
  descriptionZhtw: string | null;
  descriptionVi: string | null;
  descriptionHi: string | null;
  photos: string[];
  payment: number;
  videoId?: number;
  labUrl?: string;
  note: string;
  time: number;
  RoomPrices: TourDetailPriceInterface[];
  /* Categories: TourCategory[]; */
  Video?: {
    defaultRoomId: string;
    pcUrl: string;
    s3Url: string;
    size: number;
    streamUrl: string;
    difficulty: string | null;
    MainCategories: TourCategory[];
    SubCategories: TourCategory[];
    VideoCategoryFreeDescriptions?: {
      subCategoryId: number;
      freeDescription: string;
    }[];
    categoryTags?: {
      id: string;
      name: string;
      nameJp: string;
      nameZhcn: string;
      nameZhtw: string;
      nameVi: string;
      nameHi: string;
    }[];
  };
  Itineraries: RecTourDetailsReservationInterface[];
  ItinerariesTotal: number;
  RefRoom: null | RefRoomInterface;
  isCompleted: boolean;
  deletedAt: string;
  averagedEvaluations: string | null;
  isFree: boolean;
  chouseisanLink: string | null;
  chouseisanLinkVisibility: boolean;
  isFavoriteGuide: boolean;
  earliestFutureItineraryTimeStart: string | null;
  earliestFutureItineraryTimeEnd: string | null;
  isLiveStream: boolean;
  streamKey: string | null;
  streamServer: string | null;
}

export interface TourCategory {
  TourCategory: {
    CategoryId: number;
    TourId: number;
    createdAt: string;
    updatedAt: string;
  };
  id: number;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
  hasFreeDescription?: boolean;
}

export interface GiftedReservationGuests {
  id: number;
  sender: {
    id: number;
    name: string;
    email: string;
  };
}

export interface RecTourDetailItineraryAvailableInterface {
  amount: string;
  currency: string;
  name: string;
  available: number;
  maximum: number;
  minimum: number;
}

export interface RecTourDetailsReservationInterface {
  id: number; // equal to reservationId
  timeStart: string;
  timeEnd: string;
  status: string;
  computedStatus: string;
  requestAnswered: boolean;
  reserved: number;
  maximum: number;
  reservationGuests: number[];
  giftedReservationGuests: GiftedReservationGuests[];
  Bookings: SearchTourBookingInterface[];
  ItineraryPrices: RecTourDetailItineraryAvailableInterface[];
}

export interface TourReservationInterface {
  timeStart: string;
  timeEnd: string;
  available: number;
  itineraryId: number;
}

export interface ReservationRegisterInterface {
  timeStart: string;
  timeEnd: string;
  available?: number;
  requestAnswered: boolean;
}

export interface ReservationRegisterResInterface {
  itineraryId: number;
  tourId: string;
  isDeleted: boolean;
  timeStart: string;
  timeEnd: string;
  available: number;
  requestAnswered: boolean;
  updatedAt: string;
  createdAt: string;
}

export interface SpotListInterface {
  spot_code: string;
  spot_name: string;
  count: number;
}

export interface TravelCountryInterface {
  code: keyof typeof countriesGeoInfo;
  lat: number;
  lng: number;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
  spot_code: string;
  spot_name: string;
  count: number;
  thumbnail?: string;
}

export interface PresentBookingInterface {
  id: number;
  receiverId: number;
  receiverName: string;
  receiverEmail: string;
  status: string;
}

export interface BookingInterface {
  id: number;
  guestId: number;
  number: number;
  timeStart: string;
  timeEnd: string;
  evaluation: number;
  review: string;
  ReviewPhotos?: ReviewPhotoInterface[];
  status: string;
  hasPresent: boolean;
  isPresent?: boolean;
  senderName?: string;
  presentBookings?: PresentBookingInterface[];
  Itinerary: {
    id: number;
    status: string;
    available: number;
    Tour: {
      id: number;
      hostName: string;
      hostPhoto: string;
      title: string;
      titleJp: string;
      titleZhcn: string | null;
      titleZhtw: string | null;
      titleVi: string | null;
      titleHi: string | null;
      guideId: number;
      isFree: boolean;
      payment: number;
      paymentUSD: number;
      Video: {
        id: string;
        defaultRoomId: string;
        pcUrl: string;
        s3Url: string;
        size: number;
        streamUrl: string;
      };
      Guide?: {
        hostName: string;
        hostStory: string;
        hostStoryJp?: string;
        hostStoryZhcn?: string | null;
        hostStoryZhtw?: string | null;
        hostStoryVi?: string | null;
        hostStoryHi?: string | null;
        id: number;
        language: string;
        location: string;
        otherLanguages: string[];
        photo: string;
        userId: number;
      };
    };
  };
}

export interface GmBookingInterface {
  id: number;
  guestId: number;
  number: number;
  timeStart: Date;
  timeEnd: Date;
  status: string;
  isPresent?: boolean;
  hasPresent: boolean;
  senderName?: string;
  senderId?: number;
  senderEmail?: string;
  Itinerary: {
    id: number;
    status: string;
    Room: {
      id: number;
      hostName: string;
      hostPhoto: string;
      title: string;
      titleJp: string | null;
      titleZhcn: string | null;
      titleZhtw: string | null;
      titleVi: string | null;
      titleHi: string | null;
      guideId: number;
      isFree: boolean;
      RoomPrice: {
        id: number;
        amount: string;
        currency: string;
      };
      Guide: {
        otherLanguages: string[];
        userId: number;
        location: string;
        language: string;
        hostName: string;
        photo: string;
        hostStory: string;
        hostStoryJp: string | null;
        hostStoryZhcn: string | null;
        hostStoryZhtw: string | null;
        hostStoryVi: string | null;
        hostStoryHi: string | null;
      };
      Video: {
        id: number;
        defaultRoomId: string;
        s3Url: string;
        streamUrl: null;
        pcUrl: string;
        size: number;
      };
    };
    ItineraryPrices: ItineraryPricesInterface[];
  };
  presentBookings?: PresentBookingInterface[];
}

export interface ItineraryPricesInterface {
  available: number;
}

export interface SendReviewResInterface {
  id: number;
  guestId: number;
  tourId: number;
  itineraryId: number;
  size: number;
  timeStart: string;
  timeEnd: string;
  groupParticipation: boolean;
  evaluation: number;
  review: string;
  ReviewPhotos: ReviewPhotoInterface[];
  createdAt: string;
  updatedAt: string;
  deletedAt: boolean;
}

export interface TemporarySaveTourInterface {
  location: string;
  country: string;
  language: string;
  otherLanguages: string[] | [];
  hostName: string;
  hostPhoto: string;
  hostStory: string;
  minimum: number;
  maximum: number;
  groupParticipation: boolean;
  groupPayment: number;
  title: string;
  titleJp: string;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
  photos: [];
  payment: number;
  paymentUSD: number;
  videoId: number;
  note: string;
  time: number;
  reservations: Schedule[];
  guideId: number;
  /**
   *temporary.isCompleted === true, user has registered a tour.
   *temporary.isCompleted === false, user saved and exit in a tour registration.
   *temporary.isCompleted === undefined, user hasn't registered a tour yet.
   **/
  isCompleted: boolean;
  id: number;
}

interface Schedule {
  index: number;
  timeStart: Dayjs;
  timeEnd: Dayjs;
}

export interface CategoryInterface {
  createdAt: string;
  id: number;
  name: string;
  nameJp: string;
  nameZhcn: string;
  nameZhtw: string;
  nameVi: string;
  nameHi: string;
}

export interface PointEmail {
  userId: number;
  name: string;
  email: string;
  remainingPoint: number;
}

export interface ChangePointAdminPro {
  userId: number;
  isFree: boolean;
  eventCode: string;
  amountChange: number;
  memo: string;
  expirationDate: string;
}

export interface BuyItineraryWithFreePoint {
  itineraryId: number;
  description?: string;
  presentEmails?: string[];
  refCode?: string | null;
}

export interface BuyFreeItinerary {
  itineraryId: number;
  isWorld?: boolean;
  refCode?: string | null;
  type?: "vip" | "normal";
}

export interface BuyFreeItineraryWithNotAuth extends BuyFreeItinerary {
  name: string;
  email: string;
  distinctId: string;
}

export interface NoShowInfo {
  noShow?: boolean;
}

export interface ReviewPhotoInterface {
  id: number;
  userId: number;
  bookingId: number;
  photo: string;
}

export interface TourReviewFormdataInterface {
  evaluation: number;
  review: string;
  photos?: any[];
}

export interface RoomReviewFormdataInterface {
  roomId: number;
  evaluation: number;
  comment: string;
  type: string;
  photos?: any[];
}

export interface ReviewItemInterface {
  id: number;
  userId: number;
  roomId: number;
  evaluation: number;
  comment: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  ReviewPhotos?: ReviewPhotoInterface[];
  Room: {
    id: number;
    category: string;
    title: string;
    titleJp: string | null;
    titleZhcn: string | null;
    titleZhtw: string | null;
    titleVi: string | null;
    titleHi: string | null;
  };
}

export interface TourReviewRoomInterface {
  id: number;
  category: string;
  title: string;
  titleJp: string | null;
  titleZhcn: string | null;
  titleZhtw: string | null;
  titleVi: string | null;
  titleHi: string | null;
}

export interface TourReviewInterface {
  id: number;
  userId: number;
  roomId: number;
  evaluation: number;
  comment: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: null;
  ReviewPhotos: ReviewPhotoInterface[];
  Room: TourReviewRoomInterface;
}
