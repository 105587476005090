import { SearchActionTypes } from "./actions";
import { SearchActions, SearchedUser } from "./types";

export const searchInitialState = {
  searchedUsers: {
    // set to null by default to avoid flash of unstyled content
    hasFoundUsers: null as boolean | null,
    users: [] as SearchedUser[],
  },
  errors: [] as any[],
};

export type SearchState = typeof searchInitialState;

export function searchReducer(
  state: SearchState = searchInitialState,
  action: SearchActionTypes
): SearchState {
  switch (action.type) {
    case SearchActions.SEARCH_USERS_REQUEST:
      return {
        ...state,
      };
    case SearchActions.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchedUsers: action.payload,
      };
    case SearchActions.SEARCH_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
