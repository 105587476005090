import { EventActionTypes } from "./actions";
import { EventActions, IMyUploads } from "./types";

export const eventInitialState = {
  isSubmitting: false,
  isLoading: false,
  myUploads: {
    inProgressVideos: [],
    pastVideos: {
      items: [],
      total: 0,
    },
  } as IMyUploads,
};

export type EventState = typeof eventInitialState;

export function eventReducer(
  state: EventState = eventInitialState,
  action: EventActionTypes
): EventState {
  switch (action.type) {
    case EventActions.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case EventActions.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case EventActions.SET_MY_UPLOADS:
      return {
        ...state,
        isLoading: false,
        myUploads: action.payload,
      };
    default:
      return state;
  }
}
