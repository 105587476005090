import { GuideActionTypes } from "./actions";
import {
  GuideActions,
  HostGuideInfoType,
  ApiError,
  IGuide,
  IFavoriteGuide,
} from "./types";

export const guideInitialState = {
  isSubmitting: false,
  loading: false,
  hostGuide: {} as HostGuideInfoType,
  guides: [] as IGuide[],
  favoriteGuides: [] as IFavoriteGuide[],
  favouriteGuidesCount: 0 as number,
  hasMoreFavoriteGuides: false as boolean,
  errors: [] as ApiError[],
};

export type GuideState = typeof guideInitialState;

export function guideReducer(
  state: GuideState = guideInitialState,
  action: GuideActionTypes
): GuideState {
  switch (action.type) {
    case GuideActions.SET_GUIDE_ERROR:
      return {
        ...state,
        errors: action.payload as ApiError[],
      };
    case GuideActions.SET_GUIDE_FAVORITE_STATUS:
      return {
        ...state,
        hostGuide: {
          ...state.hostGuide,
          isFavorited: action.payload,
        },
      };
    case GuideActions.GET_HOSTGUIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GuideActions.GET_HOSTGUIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        hostGuide: action.payload,
      };
    case GuideActions.GET_HOSTGUIDE_ERROR:
      return {
        ...state,
        loading: false,
        hostGuide: {} as HostGuideInfoType,
        errors: action.payload as ApiError[],
      };
    case GuideActions.UPDATE_HOSTGUIDE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GuideActions.UPDATE_HOSTGUIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        hostGuide: {
          ...state.hostGuide,
          ...action.payload,
        },
      };
    case GuideActions.UPDATE_HOSTGUIDE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload as ApiError[],
      };
    case GuideActions.SET_GUIDES:
      return {
        ...state,
        guides: action.payload,
      };
    case GuideActions.SET_FAVORITE_GUIDES:
      const page = action.payload.page ? action.payload.page : 1;
      const favouriteGuidesCount = action.payload.favouriteGuidesCount;
      const favoriteGuides =
        page > 1
          ? [...state.favoriteGuides, ...action.payload.favoriteGuides]
          : action.payload.favoriteGuides;
      const hasMoreFavoriteGuides =
        action.payload.favouriteGuidesCount !== favoriteGuides.length &&
        action.payload.favouriteGuidesCount > favoriteGuides.length;

      return {
        ...state,
        favoriteGuides,
        favouriteGuidesCount,
        hasMoreFavoriteGuides,
      };
    case GuideActions.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GuideActions.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case GuideActions.REMOVE_FAVORITE_GUIDE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
