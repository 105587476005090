import { ReviewActionTypes } from "./action";

import { ReviewActions, ReviewDetails, ReviewItem } from "./types";

export const reviewInitialState = {
  loading: true as boolean,
  reviewDetails: {} as ReviewDetails,
  errors: [] as any[],
  isFetchingReviews: false as boolean,
  reviews: [] as ReviewItem[],
  hasMoreReviews: false as boolean,
};

export type reviewState = typeof reviewInitialState;

export const reviewReducer = (
  state: reviewState = reviewInitialState,
  action: ReviewActionTypes
): reviewState => {
  switch (action.type) {
    case ReviewActions.GET_REVIEW_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ReviewActions.GET_REVIEW_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviewDetails: action.payload,
      };
    case ReviewActions.LIKE_REVIEW:
      return {
        ...state,
        reviewDetails: {
          ...state.reviewDetails,
          Reviews: state.reviewDetails.Reviews.map((el) => {
            if (el.id === action.payload.reviewId && el.ReviewLikes) {
              if (
                el.ReviewLikes.some(
                  (el) =>
                    el.id === action.payload.reviewId &&
                    el.userId === action.payload.userId
                )
              ) {
                return el;
              } else {
                return {
                  ...el,
                  ReviewLikes: [
                    ...el.ReviewLikes,
                    {
                      id: action.payload.reviewId,
                      userId: action.payload.userId,
                    },
                  ],
                };
              }
            }
            return el;
          }),
        },
      };
    case ReviewActions.DISLIKE_REVIEW:
      return {
        ...state,
        reviewDetails: {
          ...state.reviewDetails,
          Reviews: state.reviewDetails.Reviews.map((el) => {
            if (el.id === action.payload.reviewId && el.ReviewLikes) {
              return {
                ...el,
                ReviewLikes: el.ReviewLikes
                  ? el.ReviewLikes.filter(
                      (el) =>
                        el.id !== action.payload.reviewId &&
                        el.userId !== action.payload.userId
                    )
                  : undefined,
              };
            }

            return el;
          }),
        },
      };
    case ReviewActions.LIKE_REVIEW_PHOTO:
      return {
        ...state,
        reviewDetails: {
          ...state.reviewDetails,
          Reviews: state.reviewDetails.Reviews.map((el) => {
            if (el.ReviewPhotos) {
              el.ReviewPhotos.map((photo) => {
                if (photo.id === action.payload) {
                  if (photo.ReviewPhotosLike.length === 0) {
                    photo.ReviewPhotosLike.push({ liked: true });
                  } else {
                    photo.ReviewPhotosLike[0].liked = true;
                  }
                  return photo;
                }

                return photo;
              });
            }
            return el;
          }),
        },
      };
    case ReviewActions.DISLIKE_REVIEW_PHOTO:
      return {
        ...state,
        reviewDetails: {
          ...state.reviewDetails,
          Reviews: state.reviewDetails.Reviews.map((el) => {
            if (el.ReviewPhotos) {
              el.ReviewPhotos.map((photo) => {
                if (photo.id === action.payload) {
                  if (photo.ReviewPhotosLike.length === 0) {
                    photo.ReviewPhotosLike.push({ liked: false });
                  } else {
                    photo.ReviewPhotosLike[0].liked = false;
                  }
                  return photo;
                }

                return photo;
              });
            }
            return el;
          }),
        },
      };
    case ReviewActions.REVIEW_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case ReviewActions.GET_REVIEWS_REQUEST:
      return {
        ...state,
        isFetchingReviews: true,
      };
    case ReviewActions.GET_REVIEWS_SUCCESS:
      const page = action.payload.page ? action.payload.page : 1;
      const reviews =
        page > 1
          ? [...state.reviews, ...action.payload.data]
          : action.payload.data;
      const hasMoreReviews =
        action.payload.total !== reviews.length &&
        action.payload.total > reviews.length;

      return {
        ...state,
        isFetchingReviews: false,
        reviews,
        hasMoreReviews,
      };
    case ReviewActions.GET_REVIEWS_FAILURE:
      return {
        ...state,
        isFetchingReviews: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
