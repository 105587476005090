import {
  createStore,
  applyMiddleware,
  compose,
  CombinedState,
  AnyAction,
} from "redux";
import thunk from "redux-thunk";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
  withReduxStateSync,
} from "redux-state-sync";

import { StoreActionTypes } from "./actions";
import { rootReducers } from "./reducers";
import { initialState, State } from "./state";
import { AccountTypes } from "./account/types";
import { socketMiddleware } from "../middleware/socket";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers =
  window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;

const stateSync = createStateSyncMiddleware({
  // Any whitelist, blacklist, etc configuration we might have
  whitelist: [AccountTypes.LOGOUT],
});

const enhancedRootReducers = (
  state: CombinedState<State> | undefined,
  action: AnyAction
) => {
  if (action.type === StoreActionTypes.RESET_STORE) {
    return rootReducers(undefined, action);
  }

  return rootReducers(state, action);
};

const middlewares = [thunk, socketMiddleware, stateSync];

export const store = createStore(
  withReduxStateSync(enhancedRootReducers),
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);
initStateWithPrevTab(store);
