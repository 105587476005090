import { StripeVerifyStatus } from "../../api/payment";

export enum PlanTypes {
  free = "free",
  pro = "pro",
  security = "security",
}

export enum SelfExplorePlanTypes {
  free = "free",
  selfExploreMobile = "selfExploreMobile",
  selfExplore = "selfExplore",
}

export interface User {
  id: number;
  language?: string;
  name: string;
  email: string;
  phone: string;
  description: string;
  point: string;
  latestPointsExpireDate: string;
  plan: PlanTypes;
  selfExplorePlan: {
    hasPlan: boolean | null;
    plan: SelfExplorePlanTypes;
    prevPlan?: SelfExplorePlanTypes;
    expireDate: string;
    isPointPay: boolean;
    isPointCancelled: boolean;
    planPrice?: number;
    planCurrency?: string;
  };
  partyPlan: {
    hasPlan: boolean | null;
    expireDate: string;
    planPrice?: number;
    planCurrency?: string;
  };
  therapyPlan: {
    hasPlan: boolean | null;
    expireDate: string;
    planPrice?: number;
    planCurrency?: string;
  };
  isAdmin: boolean;
  isProAdmin: boolean;
  emailNotificationEnabled: boolean;
  avatar?: string;
  isBusiness: boolean;
  stripeAccountId: string | null;
  stripeVerifyStatus: StripeVerifyStatus | null;
  pointHistories: PointHistories | null;
  purchasedConcerts: number[];
  isFirst: boolean;
  newToParty: boolean;
  newToMeetup: boolean;
  newToSelfHost: boolean;
  canCheckPaymentHistory: boolean;
  asAffiliatedUser: {
    isWrSubscribed: boolean;
    newToWrSubscription: boolean;
  };
  hostPhoto?: string;
  hostName: string;
  hostStory: string;
  hostStoryJp?: string;
  hostStoryZhcn?: string | null;
  hostStoryZhtw?: string | null;
  managementNotificationsPopupEnabled: boolean;
  isJoinByPassword: boolean;
  enableLabß: boolean;
  submittedLabß: boolean;
}

export interface PointHistories {
  total: number;
  pointHistories: Array<PointHistory>;
}

export interface PointHistory {
  id: number;
  isFree: boolean;
  expirationDate?: string;
  amount: number;
  createdAt: string;
}

export interface UserPoint {
  email: string;
  name: string;
  remainingPoint: string;
  latestPointsExpireDate: string;
  userId: string;
}

export interface SubscribePlanWithFreePoint {
  isFree: boolean;
  eventCode: string;
  amountChange: number;
  planCode: string;
  refCode?: string | null;
  cardInfo?: {
    cardNumber: string;
    expiry: string;
    cvc: string;
  };
}

export interface IPaymentMethod {
  id: string;
  last4: string;
  exp_year: number;
  exp_month: number;
  country: string;
}

export type CurrentAuthOption = "email" | "phone";

export type PartyOrTherapy = "Party" | "Therapy";

export type SendContactTemplate = "travelAgency" | "timeCapsule";

export enum AccountTypes {
  SET_USER = "account/SET_USER",
  SET_USER_PERMISSION = "account/SET_USER_PERMISSION",
  SET_USER_PRO_ADMIN_PERMISSION = "account/SET_USER_PRO_ADMIN_PERMISSION",
  SET_PLAN = "account/SET_PLAN",
  DOWNGRADE_PLAN = "account/DOWNGRADE_PLAN",
  SET_USER_SELFHOST_PLAN = "account/SET_USER_SELFHOST_PLAN",
  SET_IS_SUBMITTING = "account/SET_IS_SUBMITTING",
  SET_AUTHENTICATE = "account/SET_AUTHENTICATE",
  SET_REDIRECT_PATH = "account/SET_REDIRECT_PATH",
  LOGOUT = "account/LOGOUT",
  REQUEST_LOGIN = "account/REQUEST_LOGIN",
  REQUEST_REGISTER = "account/REQUEST_REGISTER",
  SET_ERRORS = "account/SET_ERRORS",
  UPDATE_BUSINESS_MODE = "account/UPDATE_BUSINESS_MODE",
  UPDATE_USER_BUSINESS = "account/UPDATE_USER_BUSINESS",
  GET_USERPOINT = "account/GET_USERPOINT",
  GET_USERPOINT_HISTORY = "account/GET_USERPOINT_HISTORY",
  SUBSCRIBE_PLAN_WITH_FREE_POINT = "account/SUBSCRIBE_PLAN_WITH_FREE_POINT",
  CANCEL_PLAN_WITH_FREE_POINT = "account/CANCEL_PLAN_WITH_FREE_POINT",
  SET_PAYMENT_METHOD = "account/SET_PAYMENT_METHOD",
  USER_AUTH_REQUEST = "account/USER_AUTH_REQUEST",
  USER_AUTH_SUCCESS = "account/USER_AUTH_SUCCESS",
  RESEND_OTP_REQUEST = "account/RESEND_OTP_REQUEST",
  RESEND_OTP_SUCCESS = "account/RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE = "account/RESEND_OTP_FAILURE",
  CONFIRM_OTP_REQUEST = "account/CONFIRM_OTP_REQUEST",
  CONFIRM_OTP_SUCCESS = "account/CONFIRM_OTP_SUCCESS",
  CONFIRM_OTP_FAILURE = "account/CONFIRM_OTP_FAILURE",
  RESET_PASSWORD_OTP_CONFIRMATION_REQUEST = "account/RESET_PASSWORD_OTP_CONFIRMATION_REQUEST",
  RESET_PASSWORD_OTP_CONFIRMATION_SUCCESS = "account/RESET_PASSWORD_OTP_CONFIRMATION_SUCCESS",
  RESET_PASSWORD_OTP_CONFIRMATION_FAILURE = "account/RESET_PASSWORD_OTP_CONFIRMATION_FAILURE",
  RESET_PASSWORD_OTP_REQUEST = "account/RESET_PASSWORD_OTP_REQUEST",
  RESET_PASSWORD_OTP_SUCCESS = "account/RESET_PASSWORD_OTP_SUCCESS",
  RESET_PASSWORD_OTP_FAILURE = "account/RESET_PASSWORD_OTP_FAILURE",
  CREATE_PRE_SIGNUP_PHONE_USER_REQUEST = "account/CREATE_PRE_SIGNUP_PHONE_USER_REQUEST",
  CREATE_PRE_SIGNUP_PHONE_USER_SUCCESS = "account/CREATE_PRE_SIGNUP_PHONE_USER_SUCCESS",
  CREATE_PRE_SIGNUP_PHONE_USER_FAILURE = "account/CREATE_PRE_SIGNUP_PHONE_USER_FAILURE",
  RESET_PHONE_SAVE_NEW_PHONE_REQUEST = "account/RESET_PHONE_SAVE_NEW_PHONE_REQUEST",
  RESET_PHONE_SAVE_NEW_PHONE_SUCCESS = "account/RESET_PHONE_SAVE_NEW_PHONE_SUCCESS",
  RESET_PHONE_SAVE_NEW_PHONE_FAILURE = "account/RESET_PHONE_SAVE_NEW_PHONE_FAILURE",
  RESET_PHONE_VERIFY_CURRENT_PHONE_REQUEST = "account/RESET_PHONE_VERIFY_CURRENT_PHONE_REQUEST",
  RESET_PHONE_VERIFY_CURRENT_PHONE_SUCCESS = "account/RESET_PHONE_VERIFY_CURRENT_PHONE_SUCCESS",
  RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE = "account/RESET_PHONE_VERIFY_CURRENT_PHONE_FAILURE",
  RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_REQUEST = "account/RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_REQUEST",
  RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_SUCCESS = "account/RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_SUCCESS",
  RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE = "account/RESET_PHONE_VERIFY_AND_RESET_NEW_PHONE_FAILURE",
  UPDATE_CAN_CHECK_PAYMENT_HISTORY_REQUEST = "account/UPDATE_CAN_CHECK_PAYMENT_HISTORY_REQUEST",
  UPDATE_CAN_CHECK_PAYMENT_HISTORY_SUCCESS = "account/UPDATE_CAN_CHECK_PAYMENT_HISTORY_SUCCESS",
  UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE = "account/UPDATE_CAN_CHECK_PAYMENT_HISTORY_FAILURE",
  GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_REQUEST = "account/GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_REQUEST",
  GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_SUCCESS = "account/GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_SUCCESS",
  GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE = "account/GET_USER_INFO_BY_GOOGLE_CALLBACK_TOKEN_FAILURE",
  GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_REQUEST = "account/GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_REQUEST",
  GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_SUCCESS = "account/GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_SUCCESS",
  GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE = "account/GET_USER_INFO_BY_TWITTER_CALLBACK_TOKEN_FAILURE",
  GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_REQUEST = "account/GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_REQUEST",
  GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_SUCCESS = "account/GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_SUCCESS",
  GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE = "account/GET_USER_INFO_BY_LINE_CALLBACK_TOKEN_FAILURE",
  CLOSE_MANAGEMENT_NOTIFICATIONS_POPUP = "account/CLOSE_MANAGEMENT_NOTIFICATIONS_POPUP",
  ENABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA = "account/ENABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA",
  DISABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA = "account/DISABLE_MAY_CONTAIN_STALE_SUBSCRIPTION_DATA",
}

export type ResendOtpTypes =
  | "signup"
  | "resetPhoneCurrentPhone"
  | "resetPhoneNewPhone"
  | "forgotPassword";
