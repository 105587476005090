import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  INITIAL_SPOT_COUNTRY_COUNT_OPTIONS,
  INITIAL_SPOT_COUNTRY_OPTIONS,
} from "../../utils/constant";
import { MainListWithSubListType } from "../../utils/type";
import { LocationActionTypes } from "./actions";
import { LocationActions } from "./types";

export const locationInitialState = {
  allLocationsLoading: false,
  allLocationsHasFailedToLoad: false,
  allLocations: [] as MainListWithSubListType[],
  totalSubLocations: 0 as number,
  totalSubLocationsByMainLocation: {} as {
    [key: string]: number;
  },
  preSelectedLocationsLoading: false as boolean,
  preSelectedCountriesBySpots: {
    ...INITIAL_SPOT_COUNTRY_OPTIONS,
  } as {
    [key: string]: CheckboxValueType[];
  },
  preSelectedCountriesCountBySpot: {
    ...INITIAL_SPOT_COUNTRY_COUNT_OPTIONS,
  } as {
    [key: string]: number;
  },
  errors: [] as any[],
};

export type LocationState = typeof locationInitialState;

export function locationReducer(
  state: LocationState = locationInitialState,
  action: LocationActionTypes
): LocationState {
  switch (action.type) {
    case LocationActions.GET_ALL_LOCATIONS_REQUEST:
      return {
        ...state,
        allLocationsLoading: true,
        allLocationsHasFailedToLoad: false,
      };
    case LocationActions.GET_ALL_LOCATIONS_SUCCESS:
      return {
        ...state,
        allLocationsLoading: false,
        allLocationsHasFailedToLoad: false,
        allLocations: action.payload.allData,
        totalSubLocations: action.payload.total,
        totalSubLocationsByMainLocation: action.payload.totalByMain,
      };
    case LocationActions.GET_ALL_LOCATIONS_FAILURE:
      return {
        ...state,
        allLocationsLoading: false,
        allLocationsHasFailedToLoad: true,
        errors: action.payload,
      };
    case LocationActions.GET_PRE_SELECTED_LOCATIONS_REQUEST:
      return {
        ...state,
        preSelectedLocationsLoading: true,
      };
    case LocationActions.GET_PRE_SELECTED_LOCATIONS_SUCCESS:
      return {
        ...state,
        preSelectedCountriesBySpots: action.payload.preSelectedCountriesBySpots,
        preSelectedCountriesCountBySpot:
          action.payload.preSelectedCountriesCountBySpot,
        preSelectedLocationsLoading: false,
      };
    case LocationActions.GET_PRE_SELECTED_LOCATIONS_FAILURE:
      return {
        ...state,
        errors: action.payload,
        preSelectedLocationsLoading: false,
      };
    case LocationActions.LOCATION_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
