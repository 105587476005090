import { BookActions } from "./actions";
import { Grade, BookTypes, IContinent, ICountry, IRankUnit } from "./types";

export type BookState = {
  grade: Grade;
  worldwide: {
    progress: {
      total: number;
      completed: number;
    };
    continents: IContinent[];
    countries: ICountry[];
  };
  variety: {
    progress: {
      total: number;
      completed: number;
    };
  };
  ranking: {
    monthly: IRankUnit[];
    all: IRankUnit[];
  };
  isLoading: boolean;
};

export const bookInitialState: BookState = {
  grade: "new",
  worldwide: {
    progress: {
      total: 0,
      completed: 0,
    },
    continents: [],
    countries: [],
  },
  variety: {
    progress: {
      total: 0,
      completed: 0,
    },
  },
  ranking: {
    monthly: [],
    all: [],
  },
  isLoading: false,
};

export function bookReducer(
  state: BookState = bookInitialState,
  action: BookActions
): BookState {
  switch (action.type) {
    case BookTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case BookTypes.SET_TOTAL_PROGRESS:
      return {
        ...state,
        grade: action.payload.grade,
        worldwide: {
          ...state.worldwide,
          progress: action.payload.progress.worldwide,
        },
        variety: {
          ...state.variety,
          progress: action.payload.progress.variety,
        },
        isLoading: false,
      };
    case BookTypes.SET_CONTINENTS:
      return {
        ...state,
        worldwide: {
          ...state.worldwide,
          continents: action.payload,
        },
        isLoading: false,
      };
    case BookTypes.SET_COUNTRIES:
      return {
        ...state,
        worldwide: {
          ...state.worldwide,
          countries: action.payload,
        },
        isLoading: false,
      };
    case BookTypes.SET_RANKING_ALL:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          all: action.payload.ranking,
        },
        isLoading: false,
      };
    case BookTypes.SET_RANKING_MONTHLY:
      return {
        ...state,
        ranking: {
          ...state.ranking,
          monthly: action.payload.ranking,
        },
        isLoading: false,
      };
    default:
      return state;
  }
}
