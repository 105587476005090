export enum SuggestionActions {
  UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_REQUEST = "UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_REQUEST",
  UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_SUCCESS = "UNIQUE_SUGGESTION_GET_UNIQUE_NAMES_SUCCESS",
  UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_REQUEST = "UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_REQUEST",
  UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_SUCCESS = "UNIQUE_SUGGESTION_GET_UNIQUE_PASSWORDS_SUCCESS",
  UNIQUE_SUGGESTION_ERROR = "UNIQUE_SUGGESTION_ERROR",
}

export interface GetUniqueNamesOptionsInterface {
  name: string;
  limit?: number;
  length?: 2 | 3;
  number?: number;
}

export interface GetUniquePasswordsOptionsInterface {
  limit?: number;
  length?: number;
}
