export interface IConcert {
  id: number;
  type: string;
  timeStart: string;
  timeEnd?: string | null;
  Room: {
    id: number;
    type: string;
    title: string;
    photos: string[];
    guideId: number;
    Video?: {
      id: number;
      s3Url: string;
      pcUrl: string;
      defaultRoomId: string;
    };
  };
}

export interface IConcertDetail {
  id: number;
  title: string;
  guideId: number;
  photos: string[];
  description: string;
  caution: string;
  message: string;
  type: string;
  paymentType: string;
  category: string;
  createdAt: string;
  Itineraries: {
    id: number;
    timeStart: string;
    timeEnd: string | null;
    purchaseStartTime: string | null;
    purchaseEndTime: string | null;
    ItineraryPrices: {
      id: number;
      name: string;
      currency: string;
      amount: string;
      minimum: number;
      maximum: number;
      available: number;
    }[];
  }[];
}

export enum ActionTypes {
  SET_CONCERTS = "music/SET_CONCERTS",
  SET_CONCERT = "music/SET_CONCERT",
  SET_IS_LOADING = "music/SET_IS_LOADING",
}
