import { combineReducers } from "redux";
import { State } from "./state";
import { accountReducer } from "./account/reducer";
import { appReducer } from "./app/reducer";
import { souvenirReducer } from "./souvenir/reducer";
import { tourReducers } from "./tour/reducer";
import { paymentReducer } from "./payment/reducer";
import { roomReducer } from "./room/reducer";
import { guideReducer } from "./guide/reducer";
import { dictionaryReducer } from "./dictionary/reducer";
import { selfhostReducer } from "./selfhost/reducer";
import { therapyReducer } from "./therapy/reducer";
import { inviteReducer } from "./invite/reducer";
import { friendReducer } from "./friend/reducer";
import { notificationReducer } from "./notification/reducer";
import { searchReducer } from "./search/reducer";
import { countryReducer } from "./country/reducer";
import { spotReducer } from "./spot/reducer";
import { suggestionReducer } from "./suggestion/reducer";
import { musicReducer } from "./music/reducer";
import { reviewReducer } from "./review/reducer";
import { categoryReducer } from "./category/reducer";
import { locationReducer } from "./location/reducer";
import { affiliateReducer } from "./affiliate/reducer";
import { bookReducer } from "./book/reducer";
import { csvReducer } from "./csv/reducer";
import { tripReducer } from "./trip/reducer";
import { eventReducer } from "./events/reducer";
import { postsReducer } from "./posts/reducer";
import { historyReducer } from "./history/reducer";
import { labReducer } from "./lab/reducer";
import { pageReducer } from "./pages/reducer";
import { columnReducer } from "./columns/reducer";

export const rootReducers = combineReducers<State>({
  account: accountReducer,
  app: appReducer,
  souvenir: souvenirReducer,
  tour: tourReducers,
  payment: paymentReducer,
  room: roomReducer,
  dictionary: dictionaryReducer,
  guide: guideReducer,
  selfhost: selfhostReducer,
  therapy: therapyReducer,
  invite: inviteReducer,
  friend: friendReducer,
  notification: notificationReducer,
  search: searchReducer,
  country: countryReducer,
  spot: spotReducer,
  suggestion: suggestionReducer,
  music: musicReducer,
  review: reviewReducer,
  category: categoryReducer,
  location: locationReducer,
  affiliate: affiliateReducer,
  book: bookReducer,
  csv: csvReducer,
  trip: tripReducer,
  event: eventReducer,
  posts: postsReducer,
  history: historyReducer,
  lab: labReducer,
  pages: pageReducer,
  columns: columnReducer,
});
