import { PageActions, ColumnsActionTypes } from "./actions";
import { ColumnsDataType } from "./types";

export const columnInitialState: ColumnsDataType = {
  data: {
    page: -1,
    limit: -1,
    totalPages: -1,
    totalItems: -1,
    data: [],
  },
  success: false,
  errors: {},
};
export function columnReducer(
  state: ColumnsDataType = columnInitialState,
  action: ColumnsActionTypes
): ColumnsDataType {
  switch (action.type) {
    case PageActions.COLUMNS_IS_LOADING:
      return {
        ...state,
        data: action.payload.data,
        success: action.payload.success,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
}
