import { TripActionTypes } from "./action";
import {
  TourItineraryDeleteResponseData,
  TourMutationResponseData,
  TripActions,
  TripSelectedVideo,
} from "./types";

export const tripInitialState = {
  tripSelectedVideo: null as TripSelectedVideo | null,
  tripSelectedVideoLoading: false as boolean,
  tripTourStep2Detail: {} as any, // TODO: Update Type
  tripTourStep2DetailLoading: false as boolean,
  tripTourStep2Data: {} as TourMutationResponseData,
  tripTourStep2DataLoading: false as boolean,
  tripTourStep3Detail: {} as any, // TODO: Update Type
  tripTourStep3DetailLoading: false as boolean,
  tripTourStep3Data: {} as TourMutationResponseData,
  tripTourStep3DataLoading: false as boolean,
  tripTourStep4Detail: {} as any, // TODO: Update Type
  tripTourStep4DetailLoading: false as boolean,
  tripTourStep4Data: {} as TourMutationResponseData,
  tripTourStep4DataLoading: false as boolean,
  tripTourStep5Detail: {} as any, // TODO: Update Type
  tripTourStep5DetailLoading: false as boolean,
  tripTourStep5Data: {} as TourMutationResponseData,
  tripTourStep5DataLoading: false as boolean,
  tripTourStep6Detail: {} as any, // TODO: Update Type
  tripTourStep6DetailLoading: false as boolean,
  tripTourStep6Data: {} as TourMutationResponseData,
  tripTourStep6DataLoading: false as boolean,
  tripTourDeletionData: {} as TourItineraryDeleteResponseData,
  tripTourDeletionDataLoading: false as boolean,
  tripSubmitted: false,
  errors: [] as any[],
};

export type tripState = typeof tripInitialState;

export const tripReducer = (
  state: tripState = tripInitialState,
  action: TripActionTypes
): tripState => {
  switch (action.type) {
    case TripActions.GET_TRIP_SELECTED_VIDEO_REQUEST:
      return {
        ...state,
        tripSelectedVideoLoading: true,
      };
    case TripActions.GET_TRIP_SELECTED_VIDEO_SUCCESS:
      return {
        ...state,
        tripSelectedVideoLoading: false,
        tripSelectedVideo: action.payload,
      };
    case TripActions.GET_TRIP_SELECTED_VIDEO_FAILURE:
      return {
        ...state,
        tripSelectedVideo: null,
        tripSelectedVideoLoading: false,
        errors: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_2_DETAIL_REQUEST:
      return {
        ...state,
        tripSubmitted: false,
        tripTourStep2DetailLoading: true,
      };
    case TripActions.GET_TRIP_TOUR_STEP_2_DETAIL_SUCCESS:
      return {
        ...state,
        tripTourStep2DetailLoading: false,
        tripTourStep2Detail: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_2_DETAIL_FAILURE:
      return {
        ...state,
        tripTourStep2DetailLoading: false,
        errors: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_2_REQUEST:
      return {
        ...state,
        tripTourStep2DataLoading: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_2_SUCCESS:
      return {
        ...state,
        tripTourStep2DataLoading: false,
        tripTourStep2Data: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_2_FAILURE:
      return {
        ...state,
        tripTourStep2DataLoading: false,
        errors: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_3_DETAIL_REQUEST:
      return {
        ...state,
        tripTourStep3DetailLoading: true,
      };
    case TripActions.GET_TRIP_TOUR_STEP_3_DETAIL_SUCCESS:
      return {
        ...state,
        tripTourStep3DetailLoading: false,
        tripTourStep3Detail: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_3_DETAIL_FAILURE:
      return {
        ...state,
        tripTourStep3DetailLoading: false,
        errors: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_3_REQUEST:
      return {
        ...state,
        tripTourStep3DataLoading: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_3_SUCCESS:
      return {
        ...state,
        tripTourStep3DataLoading: false,
        tripTourStep3Data: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_3_FAILURE:
      return {
        ...state,
        tripTourStep3DataLoading: false,
        errors: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_4_DETAIL_REQUEST:
      return {
        ...state,
        tripTourStep4DetailLoading: true,
      };
    case TripActions.GET_TRIP_TOUR_STEP_4_DETAIL_SUCCESS:
      return {
        ...state,
        tripTourStep4DetailLoading: false,
        tripTourStep4Detail: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_4_DETAIL_FAILURE:
      return {
        ...state,
        tripTourStep4DetailLoading: false,
        errors: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_4_REQUEST:
      return {
        ...state,
        tripTourStep4DataLoading: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_4_SUCCESS:
      return {
        ...state,
        tripTourStep4DataLoading: false,
        tripTourStep4Data: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_4_FAILURE:
      return {
        ...state,
        tripTourStep4DataLoading: false,
        errors: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_5_DETAIL_REQUEST:
      return {
        ...state,
        tripSubmitted: false,
        tripTourStep5DetailLoading: true,
      };
    case TripActions.GET_TRIP_TOUR_STEP_5_DETAIL_SUCCESS:
      return {
        ...state,
        tripTourStep5DetailLoading: false,
        tripTourStep5Detail: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_5_DETAIL_FAILURE:
      return {
        ...state,
        tripTourStep5DetailLoading: false,
        errors: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_5_REQUEST:
      return {
        ...state,
        tripTourStep5DataLoading: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_5_SUCCESS:
      return {
        ...state,
        tripTourStep5DataLoading: false,
        tripTourStep5Data: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_5_FAILURE:
      return {
        ...state,
        tripTourStep5DataLoading: false,
        errors: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_6_DETAIL_REQUEST:
      return {
        ...state,
        tripTourStep6DetailLoading: true,
      };
    case TripActions.GET_TRIP_TOUR_STEP_6_DETAIL_SUCCESS:
      return {
        ...state,
        tripTourStep6DetailLoading: false,
        tripTourStep6Detail: action.payload,
      };
    case TripActions.GET_TRIP_TOUR_STEP_6_DETAIL_FAILURE:
      return {
        ...state,
        tripTourStep6DetailLoading: false,
        errors: action.payload,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_6_REQUEST:
      return {
        ...state,
        tripSubmitted: false,
        tripTourStep6DataLoading: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_6_SUCCESS:
      return {
        ...state,
        tripTourStep6DataLoading: false,
        tripTourStep6Data: action.payload,
        tripSubmitted: true,
      };
    case TripActions.MUTATE_TRIP_TOUR_STEP_6_FAILURE:
      return {
        ...state,
        tripTourStep6DataLoading: false,
        errors: action.payload,
      };
    case TripActions.DELETE_TRIP_TOUR_ITINERARY_REQUEST:
      return {
        ...state,
        tripTourDeletionDataLoading: true,
      };
    case TripActions.DELETE_TRIP_TOUR_ITINERARY_SUCCESS:
      return {
        ...state,
        tripTourDeletionDataLoading: false,
        tripTourDeletionData: action.payload,
      };
    case TripActions.DELETE_TRIP_TOUR_ITINERARY_FAILURE:
      return {
        ...state,
        tripTourDeletionDataLoading: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};
