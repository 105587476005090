import { PaymentActions } from "./actions";
import {
  PaymentActionTypes,
  IPaymentHistory,
  IBankAccount,
  IBalance,
  IPoint,
} from "./types";

export type PaymentState = {
  isSubmitting: boolean;
  itineraryId: number | undefined;
  paymentIntent:
    | {
        status: string;
        clientSecret: string;
      }
    | undefined;
  notAuthData:
    | {
        preSignupUserId?: number;
        postSignupUserId?: number;
      }
    | undefined;
  fetchedBalance: boolean;
  availableBalance: IBalance[];
  pendingBalance: IBalance[];
  pointBalance: IPoint[];
  histories: {
    data: IPaymentHistory[];
    total: number;
  };
  bankAccounts: {
    data: IBankAccount[];
    has_more: boolean;
  };
  usersPaymentHistories: any; // TODO: Define type
};

export const paymentInitialState: PaymentState = {
  isSubmitting: false,
  itineraryId: undefined,
  paymentIntent: undefined,
  notAuthData: undefined,
  fetchedBalance: false,
  availableBalance: [],
  pendingBalance: [],
  pointBalance: [],
  histories: {
    data: [],
    total: 0,
  },
  bankAccounts: {
    data: [],
    has_more: false,
  },
  usersPaymentHistories: {},
};

export function paymentReducer(
  state: PaymentState = paymentInitialState,
  action: PaymentActions
): PaymentState {
  switch (action.type) {
    case PaymentActionTypes.SET_ITINERARY:
      return {
        ...state,
        itineraryId: action.payload.itineraryId,
      };
    case PaymentActionTypes.SET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload,
      };
    case PaymentActionTypes.CLEAR_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: undefined,
      };
    case PaymentActionTypes.SET_HISTORY:
      const { availableBalance, pendingBalance, paymentHistories } =
        action.payload;

      return {
        ...state,
        availableBalance,
        pendingBalance,
        histories: {
          data: paymentHistories.items,
          total: paymentHistories.totalItems,
        },
        fetchedBalance: true,
      };
    case PaymentActionTypes.SET_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: action.payload,
      };
    case PaymentActionTypes.ADD_BANK_ACCOUNT:
      return {
        ...state,
        bankAccounts: {
          data: [...state.bankAccounts.data, ...action.payload.data],
          has_more: action.payload.has_more,
        },
      };
    case PaymentActionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.payload,
      };
    case PaymentActionTypes.SET_POINT_BALANCE:
      return {
        ...state,
        pointBalance: action.payload,
      };
    case PaymentActionTypes.SET_NOT_AUTH_DATA:
      return {
        ...state,
        notAuthData: action.payload,
      };
    case PaymentActionTypes.SET_USERS_PAYMENT_HISTORIES:
      return {
        ...state,
        usersPaymentHistories: action.payload,
      };
    default:
      return state;
  }
}
